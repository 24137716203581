import React from "react";
import PropTypes from 'prop-types'
import DesktopContainer from "./DesktopContainer";
const AppContainer = ({children}) => {
    return (
        <>
            <DesktopContainer>{children}</DesktopContainer>
        </>
    )
}
AppContainer.propTypes = {
    children: PropTypes.node,
}
export default AppContainer
