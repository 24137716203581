import React, { Component } from 'react';
import SavedListTop from './SavedListTop';
import SavedListCard from './SavedListCard';
import _ from "lodash";
import {sessionService} from "redux-react-session";
import {trackPromise} from "react-promise-tracker";
import rpa from "../../apis/rpa";
import {toast, ToastContainer} from "react-toastify";
import AppSpinner from "../Projects/AppSpinner";
import ShareLink from "../Projects/shareLink";
import DeleteModalSaveList from "../Projects/SaveListModule/DeleteModalSaveList";
import ReactPaginate from "react-paginate";
import SearchParams from "../CustomHooks/SearchParams";
import UsersBehalfList from "./UsersBehalfList";
import {userFullName} from "../Actions/ListActions"

export default (class SavedListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveLists: [],
            listUsers: [],
            currentList: {},
            pageCount: null,
            currentPage: 1,
            listName: '',
            creatorId: null,
            isOpen: false,
            load: undefined,
            deleteListId: null,
            userDetails: null,
            response: undefined,
            err: undefined,
            isEdit: false,
            openShare: false,
            onUserBehalf: false,
            listId: null,
            listToken: '',
            editListName: '',
            deleteListName: '',
        }
    }
    componentDidMount() {
        let listCurrentPage = SearchParams.getValue('listCurrentPage')
        listCurrentPage = listCurrentPage > 0 ? listCurrentPage : this.state.currentPage
        this.setState({currentPage: listCurrentPage}, ()=> this.setUserDetails())
    }
    setUserDetails = () => {
        if(_.isEmpty(this.userDetails)){
            sessionService.loadSession().then(response => {
                this.setState({userDetails: response}, () => this.getSaveLists())
            }).catch(err => {
            })
        }
    }
    UpdateSaveListUsers = (updatedList) => {
        this.setState(prevState =>{
            return{
                ...prevState,
                saveLists : prevState.saveLists.map(list => {
                    if (list.id === updatedList.id){
                        list.users = updatedList.users
                    }
                    return list
                })
            }
        })
    }
    UpdateCurrentListOnProjectDelete = (currentList) => {
        let saveLists = Object.assign([], this.state.saveLists);
        saveLists = saveLists.map(list => {
            if (list.id === currentList.id){
                list.projects = currentList.projects
                list.users = currentList.users
            }
            return list
        })
        this.setState({saveLists: saveLists, currentList: currentList, load: true})
    }
    getSaveLists = () => {
        trackPromise(rpa.get(`users/${this.state.userDetails.sub}/save_lists?page=${this.state.currentPage}`, {
            params: {
            }
        })
            .then(res => {
                let saveLists = _.orderBy(res.data.save_lists, ['name'], ['asc']);
                if (!_.isEmpty(saveLists)){
                    this.setState({saveLists: saveLists, currentList: saveLists[0], load: true, currentPage: res.data.page, pageCount: res.data.page_count})
                }
                else {
                    this.setState({saveLists: [], currentList: {}, load: true})
                }
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'save-project-side');
    }
    onchangeListName = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    onDeleteHide = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    addSaveLists = () => {
        trackPromise(rpa.post(`users/${this.state.userDetails.sub}/save_lists/create_and_get_lists`, {
            save_list: {
                name: this.state.listName
            },
            page: this.state.currentPage
        }).then(response => {
            if(response.data.success){
                let saveLists = response.data.save_lists
                // saveLists.push({id: response.data.save_list.id, name: response.data.save_list.name})
                saveLists = _.orderBy(saveLists, ['name'], ['asc']);
                toast.success('List Created Successfully')
                this.setState({saveLists: saveLists, load: true, listName: '', currentPage: response.data.page, pageCount: response.data.page_count })
            }
            else{
                toast.error(response.data.errors)
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Save List Error', err)
        }), 'save-list-side');
    }
    onClickDelete = (e) => {
        this.setState({isOpen: !this.state.isOpen, deleteListId: e.currentTarget.id, deleteListName: e.currentTarget.name})
    }
    ShowBehalfList = (e) => {
        let list = this.filterListUsers(e.currentTarget.id)
        try {
            let users = list.users?.map(user => {return {label: userFullName(user), value: userFullName(user), id: user.id, uid: user.uid, checked: true}})
            this.setState({onUserBehalf: !this.state.onUserBehalf, listUsers: users, listId: list.id, listToken: list.token, listName: list.name, creatorId: list.creator_id})
        } catch (e) {
            toast.error(e.message)
        }
    }
    updateSelectedList = (obj) => {
        let saveLists = Object.assign([], this.state.saveLists);
        saveLists = saveLists.filter(list => list.id !== obj.id)
        saveLists = _.orderBy(saveLists, ['name'], ['asc']);
        if (!_.isEmpty(saveLists)){
            this.setState({saveLists: saveLists, currentList: saveLists[0]})
        }
        else {
            this.setState({saveLists: [], currentList: {}})
        }
    }
    onEditClick = (e) => {
        let id = e.currentTarget.id
        let listText = document.querySelector(`.list-${id}`);
        let listField = document.querySelector(`#list-field-${id}`);
        this.setState({editListName: e.currentTarget.name, isEdit: true})
        listText.classList.add("d-none");
        listField.classList.remove("d-none");
    }
    onListNameUpdate = (e) => {
        let listId = e.currentTarget.id;
        this.updateListName(listId)
    }
    updateListName = (listId) => {
        trackPromise(rpa.patch(`users/${this.state.userDetails.sub}/save_lists/${listId}/update_list`, {
            name: this.state.editListName
        }).then(response => {
            if(response.data.success){
                let listText = document.querySelector(`.list-${listId}`);
                let listField = document.querySelector(`#list-field-${listId}`);
                listText.classList.remove("d-none");
                listField.classList.add("d-none");
                this.updateListsAfterNameUpdate(response.data.save_list)
                toast.success('Updated Successfully')
                this.setState({load: true, isEdit: false})
            }
            else{
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Update List Name Error', err)
        }), 'save-list-side');
    }
    updateListsAfterNameUpdate = (updatedList) => {
        var lists = []
        this.state.saveLists.map((list) => {
            if(list.id == updatedList.id){
                lists.push({id: list.id, name: updatedList.name, projects: list.projects, projects_count: list.projects_count})
            }else{
                lists.push(list)
            }
        })
        lists = _.orderBy(lists, ['name'], ['asc']);
        this.setState({saveLists: lists})
        this.setState({currentList: lists[0]})
    }
    handleShareLink = (e) => {
        this.setState({openShare: !this.state.openShare, listId: e.currentTarget.id, listToken: e.currentTarget.ariaLabel, listName: e.currentTarget.name})
    }
    handleShareClose = () => {
        this.setState({openShare: !this.state.openShare})
    }
    filterListUsers = (id) => {
        return this.state.saveLists.filter(lst => lst.id === parseInt(id))[0]
    }
    handleBehalfListClose = () => {
        this.setState({onUserBehalf: !this.state.onUserBehalf})
    }
    handlePageClick = (event) => {
        let currentPage = event.selected + 1
        this.setState({currentPage: currentPage}, () => this.getSaveLists())
        SearchParams.setValue('listCurrentPage', currentPage)
    }
    render() {
        return(
            <div className="saved-list-page">
                <AppSpinner customClass="delete-loader" area="save-project-side"/>
                <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <SavedListTop listPage={true} listName={this.state.listName} onchangeListName={this.onchangeListName} addSaveLists={this.addSaveLists}/>
                        </div>
                    </div>
                    {this.state.openShare &&
                    <ShareLink show={this.state.openShare} handleShareClose={() => this.handleShareClose()}
                               userDetails={this.state.userDetails} listId={this.state.listId} listToken={this.state.listToken} listName={this.state.listName}/>
                    }
                    {this.state.onUserBehalf &&
                        <UsersBehalfList show={this.state.onUserBehalf} handleBehalfListClose={() => this.handleBehalfListClose()}
                                         UpdateSaveListUsers = {this.UpdateSaveListUsers}
                                         userDetails={this.state.userDetails} listId={this.state.listId}
                                         listToken={this.state.listToken} listName={this.state.listName} listUsers={this.state.listUsers}
                                         creatorId={this.state.creatorId} />
                    }
                    <div className="row projects-pane">
                        {!_.isEmpty(this.state.saveLists) ? this.state.saveLists.map(list => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4" key={list.id}>
                                    <SavedListCard list={list} projects={list.projects} count={list.projects_count} handleShareLink={this.handleShareLink} onClickDelete={this.onClickDelete} onEditClick={this.onEditClick} editListName={this.state.editListName} onchangeListName={this.onchangeListName} onListNameUpdate={this.onListNameUpdate} isEdit={this.state.isEdit} ShowBehalfList={this.ShowBehalfList}/>
                                </div>
                            )
                        } ) : <div className="col-12 empty-msg"><p>No SaveList Found !</p></div>}
                    </div>
                    <div className="pagination-box">
                        <ReactPaginate className="pagination" id="react-paginate"
                            // labels
                                       previousLabel={''}
                                       nextLabel={''}
                                       breakLabel={'…'}
                            // classNames
                                       containerClassName={'react-paginate'}
                                       previousClassName={'previous'}
                                       breakClassName={'break-me'}
                                       nextClassName={'next'}
                                       pageClassName={'page'}
                                       activeClassName={'active'}
                                       pageLinkClassName={'page-link'}
                                       disabledClassName={'disabled'}
                            // functionality
                                       pageCount={this.state.pageCount}
                                       marginPagesDisplayed={2}
                                       pageRangeDisplayed={5}
                                       onPageChange={this.handlePageClick}
                                       disableInitialCallback={true}
                                       forcePage={this.state.currentPage - 1}
                        />
                        <span className="current-page">
                                    Page {this.state.currentPage} of {this.state.pageCount? this.state.pageCount : this.state.currentPage }
                                </span>
                    </div>
                    <DeleteModalSaveList isOpen={this.state.isOpen} onDeleteHide={this.onDeleteHide} onClickDelete={this.onClickDelete} id={this.state.deleteListId} userDetails={this.state.userDetails} updateSelectedList={this.updateSelectedList} listName={this.state.deleteListName} getSaveLists={this.getSaveLists}/>
                </div>
            </div>
        );
    }
});
