import _ from "lodash";

export const onFilterStateChange = (data, e) => {
    let filters =  Object.assign({}, data.filters);
    let localTotalStates =  Object.assign([], data.localTotalStates);
    localTotalStates = localTotalStates.map(localState => {
        if (e.currentTarget.name === localState.label){
            localState.checked = e.currentTarget.checked
        }
        return localState
    })
    let selectedStates = data.localTotalStates.filter(state => state.checked)
    let selectedStatesAbr = selectedStates.map(stateAbr => {return stateAbr.value})
    localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
    selectedStates = _.orderBy(selectedStates, ['label'], ['asc']);
    // filters["selectedStates"] = selectedStates
    // filters["states"] = selectedStatesAbr
    return {showMultiSelectedStates: selectedStates, multiSelectedStates: selectedStates, localAbrStates: selectedStatesAbr, localTotalStates: localTotalStates}
}
export const verifyLocalSelectedStates = (localTotalStates) => {
    return !_.isEmpty(localTotalStates.filter(curState => curState.checked))
}
export const onFilterSectorChange = (data, e) => {
    let localTotalSectors =  Object.assign([], data.localTotalSectors);
    let selectedSectors = data.showMultiSelectedSectors.filter(sector => {
        if (e.target.name !== sector.label){
            return sector
        }
        else {
            sector.checked = false
            localTotalSectors.push(sector)
        }
    })
    let selectedSectorsAbr = data.localAbrSectors.filter(sectorAbr => {
        if (e.target.id !== sectorAbr){
            return sectorAbr
        }
    })
    localTotalSectors = _.orderBy(localTotalSectors, ['value'], ['asc']);
    selectedSectors = _.orderBy(selectedSectors, ['label'], ['asc']);
    return {showMultiSelectedSectors: selectedSectors, multiSelectedSectors: selectedSectors, localAbrSectors: selectedSectorsAbr, localTotalSectors: localTotalSectors}
}
export const onFilterChangeUpdateSelectedSectors = (data, e) => {
    e = e.map(sector => {sector.checked = true; return sector})
    let multiSelectedSectors = e
    let showMultiSelectedSectors = Object.assign([], data.showMultiSelectedSectors);
    let localTotalSectors = Object.assign([], data.localTotalSectors);
    multiSelectedSectors.map(sector => {
        if (!data.showMultiSelectedSectors.some(o => o.label === sector.label)){
            showMultiSelectedSectors.push(sector)
        }
    })
    localTotalSectors = localTotalSectors.filter(sector => {
        if (!multiSelectedSectors.some(o => o.label === sector.label)){
            sector.checked = false
            return sector
        }
    })
    localTotalSectors = _.orderBy(localTotalSectors, ['label'], ['asc']);
    showMultiSelectedSectors = _.orderBy(showMultiSelectedSectors, ['label'], ['asc']);
    let localAbrSectors = showMultiSelectedSectors.map(sector => { return sector.value });
    multiSelectedSectors = []
    return {multiSelectedSectors: multiSelectedSectors, showMultiSelectedSectors: showMultiSelectedSectors, localTotalSectors: localTotalSectors, localAbrSectors: localAbrSectors}
}
export const onFilterInvolvementChange = (data, e) => {
    let localTotalInvolvements =  Object.assign([], data.localTotalInvolvements);
    let multiSelectedInvolvements = data.showMultiSelectedInvolvements.filter(inv => {
        if (e.target.name !== inv.label){
            return inv
        }
        else {
            inv.checked = false
            localTotalInvolvements.push(inv)
        }
    })
    let localAbrInvolvements = data.localAbrInvolvements.filter(invAbr => {
        if (e.target.id !== invAbr){
            return invAbr
        }
    })
    localTotalInvolvements = _.orderBy(localTotalInvolvements, ['value'], ['asc']);
    multiSelectedInvolvements = _.orderBy(multiSelectedInvolvements, ['label'], ['asc']);
    return {showMultiSelectedInvolvements: multiSelectedInvolvements, multiSelectedInvolvements: multiSelectedInvolvements, localAbrSectors: localAbrInvolvements, localTotalInvolvements: localTotalInvolvements}
}
export const onFilterChangeUpdateSelectedInvolvement = (data, e) => {
    e = e.map(inv => {inv.checked = true; return inv})
    let multiSelectedInvolvements = e
    let showMultiSelectedInvolvements = Object.assign([], data.showMultiSelectedInvolvements);
    let localTotalInvolvements = Object.assign([], data.localTotalInvolvements);
    multiSelectedInvolvements.map(inv => {
        if (!data.showMultiSelectedInvolvements.some(o => o.label === inv.label)){
            showMultiSelectedInvolvements.push(inv)
        }
    })
    localTotalInvolvements = localTotalInvolvements.filter(inv => {
        if (!multiSelectedInvolvements.some(o => o.label === inv.label)){
            inv.checked = false
            return inv
        }
    })
    localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);
    showMultiSelectedInvolvements = _.orderBy(showMultiSelectedInvolvements, ['label'], ['asc']);
    let localAbrInvolvements = showMultiSelectedInvolvements.map(inv => { return inv.value });
    multiSelectedInvolvements = []
    return {multiSelectedInvolvements: multiSelectedInvolvements, showMultiSelectedInvolvements: showMultiSelectedInvolvements, localTotalInvolvements: localTotalInvolvements, localAbrInvolvements: localAbrInvolvements}
}
export const onFilterClearAll = (data) => {
    let localTotalStates =  Object.assign([], data.localTotalStates);
    localTotalStates = localTotalStates.map(localState => {localState.checked = false; return localState})
    localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
    let localAbrStates = localTotalStates.filter(localState => localState.checked)

    let completionYear = Object.assign([], data.completionYear);
    let rangCompletionYear = Object.assign([], data.rangCompletionYear);
    rangCompletionYear = rangCompletionYear.map(obj => {
        obj.checked = false
        return obj;
    })
    completionYear = ''

    let localTotalSectors =  Object.assign([], data.localTotalSectors);
    data.showMultiSelectedSectors.forEach(selectedSector => localTotalSectors.push(selectedSector))
    localTotalSectors = _.orderBy(localTotalSectors, ['label'], ['asc']);

    let localTotalInvolvements =  Object.assign([], data.localTotalInvolvements);
    data.showMultiSelectedInvolvements.forEach(selectedInv => localTotalInvolvements.push(selectedInv))
    localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);


    return {showMultiSelectedStates: [], localAbrStates: localAbrStates, localTotalStates: localTotalStates,
        completionYear: completionYear, rangCompletionYear: rangCompletionYear,
        showMultiSelectedSectors: [], localAbrSectors: [], localTotalSectors: localTotalSectors,
        showMultiSelectedInvolvements: [], localAbrInvolvements: [], localTotalInvolvements: localTotalInvolvements,
        maxSquare: '', minSquare: '', keywords: ''}
}
export const onFilterClearAllSelectedSectors = (data) => {
    let localTotalSectors =  Object.assign([], data.localTotalSectors);
    data.showMultiSelectedSectors.forEach(selectedSector => localTotalSectors.push(selectedSector))
    localTotalSectors = _.orderBy(localTotalSectors, ['label'], ['asc']);
    return {showMultiSelectedSectors: [], localAbrSectors: [], localTotalSectors: localTotalSectors}
}
export const onFilterClearAllSelectedInvolvements = (data) => {
    let localTotalInvolvements =  Object.assign([], data.localTotalInvolvements);
    data.showMultiSelectedInvolvements.forEach(selectedInv => localTotalInvolvements.push(selectedInv))
    localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);
    return {showMultiSelectedInvolvements: [], localAbrInvolvements: [], localTotalInvolvements: localTotalInvolvements}
}
export const onFilterClearSquareFeet = () => {
    return {maxSquare: '', minSquare: ''}
}
export const onFilterClearKeywords = () => {
    return {keywords: ''}
}
export const clearFilters = (filters) => {
    filters.involvement = []
    filters.keywords = ''
    filters.maxSquare = ''
    filters.minSquare =  ''
    filters.sectors =  []
    filters.states =  []
    filters.year = ''
    return filters
}