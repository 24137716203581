import {withOktaAuth} from "@okta/okta-react";
import React, {Component} from "react";
import _ from "lodash";
import {verifyLocalSelectedStates} from "../../Actions/FilterActions";
import {Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default withOktaAuth(class SelectedParameters extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Form className="form-general side-filter-form mb-0">
                    <Card className="filter-card">
                        <div className="states-data states-data-inputs pb-0">
                            <div className="data-pane state-params">
                                <Card.Header className="">
                                    <Button className="btn btn-link p-0 clear-all-btn" variant=""
                                            onClick={this.props.onClearAll} type="button">CLEAR ALL</Button>
                                </Card.Header>
                            </div>
                        </div>
                    </Card>
                </Form>
                {verifyLocalSelectedStates(this.props.localTotalStates) &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onClearAllSelectedStates} type="button">States
                                            x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                {this.props.localTotalStates.map((selected, index) => {
                                                    if (selected.checked) {
                                                        return (
                                                            <li className="list-group-item bg-light d-flex justify-content-between"
                                                                key={index}>{selected.label}
                                                                <button className="btn badge"
                                                                        id={selected.value}
                                                                        checked={!selected.checked}
                                                                        name={selected.label}
                                                                        onClick={this.props.onStateChange}
                                                                        type="button"
                                                                >x
                                                                </button>
                                                            </li>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
                {!_.isEmpty(this.props.completionYear) &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onYearSelectedChange} type="button">Completion Year
                                            x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item bg-light d-flex justify-content-between">
                                                    <button className="btn badge pl-0" type="button">
                                                        {this.props.completionYear.label}
                                                    </button>
                                                </li>
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
                {!_.isEmpty(this.props.showMultiSelectedSectors) &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onClearAllSelectedSectors} type="button">Sector
                                            x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                {this.props.showMultiSelectedSectors.map((selected, index) => {
                                                    return (
                                                        <li className="list-group-item bg-light d-flex justify-content-between"
                                                            key={index}>{selected.label}
                                                            <button className="btn badge"
                                                                    id={selected.value}
                                                                    name={selected.label}
                                                                    onClick={this.onSectorChange}
                                                                    type="button"
                                                            >x
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
                {!_.isEmpty(this.props.showMultiSelectedInvolvements) &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onClearAllSelectedInvolvements} type="button">Involvement
                                            x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                {this.props.showMultiSelectedInvolvements.map((selected, index) => {
                                                    return (
                                                        <li className="list-group-item bg-light d-flex justify-content-between"
                                                            key={index}>{selected.label}
                                                            <button className="btn badge"
                                                                    id={selected.value}
                                                                    name={selected.label}
                                                                    onClick={this.onInvolvementChange}
                                                                    type="button"
                                                            >x
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
                {(this.props.minSquare || this.props.maxSquare) &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onClearSquareFeet} type="button">SquareFeet
                                            x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                {this.props.minSquare &&
                                                    <li className="list-group-item bg-light d-flex justify-content-between">
                                                        <button className="btn badge pl-0" type="button">
                                                            {this.props.minSquare} SF
                                                        </button>
                                                    </li>
                                                }
                                                {this.props.maxSquare &&
                                                    <li className="list-group-item bg-light d-flex justify-content-between">
                                                        <button className="btn badge pl-0" type="button">
                                                            {this.props.maxSquare} SF
                                                        </button>
                                                    </li>
                                                }
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
                {this.props.keywords &&
                    <Form className="form-general side-filter-form mb-0">
                        <Card className="filter-card">
                            <div className="states-data states-data-inputs">
                                <div className="data-pane state-params">
                                    <Card.Header className="d-flex align-items-between">
                                        <Button className="btn btn-link p-0 clear-filter-btn" variant=""
                                                onClick={this.props.onClearKeywords} type="button">Keywords x</Button>
                                        <Form.Group controlId="formSquareFeet">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item bg-light d-flex justify-content-between">
                                                    <button className="btn badge pl-0" type="button">
                                                        {this.props.keywords}
                                                    </button>
                                                </li>
                                            </ul>
                                        </Form.Group>
                                    </Card.Header>
                                </div>
                            </div>
                        </Card>
                    </Form>}
            </>
        );
    }
});
