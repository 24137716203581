/* eslint-disable */
import React, { Component } from 'react';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import iconback from '../../assets/images/icons/icon-back-mobile.svg';
import iconDown from '../../assets/images/icons/small-down.svg';
import _ from "lodash";
import {ToastContainer} from 'react-toastify';
import AppSpinner from "../Projects/AppSpinner";
import Lightbox from 'react-image-lightbox';
import {withRouter} from 'react-router-dom';
import SharedHeader from "./SharedHeader";
import AwardList from "../shared/AwardList";
import {ArchitectureServices, RyanServicesMapping} from "../shared/FilterConstant";
export default withRouter(class ShowShareableProjectMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveType: false,
            isActiveInvolvement: false,
            isActiveAward: false,
            readMore: false,
            descriptionData: [],
            projectDescription: '',
            isOpen: false,
            photoIndex: 0
        }
    }
    componentDidMount() {
        if (!_.isEmpty(this.props.data.project.description)) {
            let descriptionData = this.props.data.project.description.split('\n').filter(str => {
                if (str !== "") {
                    return str
                }
            })
            this.setState({projectDescription: descriptionData})
        }
    }
    show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }

    openAccordionType = (e) => {
        this.setState({isActiveType: !this.state.isActiveType})
    }
    openAccordionInvolvement = (e) => {
        this.setState({isActiveInvolvement: !this.state.isActiveInvolvement})
    }
    onImageClick = (e) => {
        let index = e.currentTarget.id
        this.setState({ isOpen: true, photoIndex: index })
    }
    goBackButton = () => {
        this.props.history.push(`/shareable?token=${this.props.list.token}`)
    }
    getUsername = (username) => {
        return _.isEmpty(username) ? "" : username.split("@")[0]
    }
    openAccordionAward = (e) => {
        this.setState({isActiveAward: !this.state.isActiveAward})
    }
    render() {
        const settings = {
            dots: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '30px',
        };
        const { photoIndex, isOpen } = this.state;
        const linkName = this.props.readMore? 'Read Less' : 'Read More'
        const arrow = this.props.readMore? 'upward' : 'downward'
        let images = [];
        if (this.props.data.project) {
            images = this.props.data.project.images.map(obj => {return obj.url});
        }
        const coverImgStyle = {
            maxWidth: '100%'
        }
        return (
            <main className="description-page-content-mobile">
                <AppSpinner customClass="delete-loader" area="file-upload-loader"/>

                <section className="project-title-pane project-title-pane-shared">
                    <SharedHeader/>
                    <Container>
                        <Row>
                            <Col xs={12} className="title-col d-flex">
                                <div className="back-pane">
                                    <a className="back-link" onClick={this.goBackButton}>
                                        <img src={iconback} alt="back-icon" />
                                    </a>
                                </div>
                                <div className="share-top">
                                    <div className="profile-box">
                                        <div className="media">
                                            {this.props.list &&
                                            <img src={`https://rpaprofiles.sfo2.cdn.digitaloceanspaces.com/${this.getUsername(this.props.list.user_name)}.jpg`} alt="photo"/>
                                            }
                                        </div>
                                        <div className="name-part">
                                            <h3 className="name">
                                                {this.props.list && this.props.list.name}
                                            </h3>
                                            <span>Shared by {this.props.list && `${this.props.list.user_first_name} ${this.props.list.user_last_name}`}</span>
                                            <div>
                                                <span>{this.props.list && `${this.props.list.user_first_name}.${this.props.list.user_last_name}@ryancompanies.com`.toLowerCase()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-title-box">
                                    <h4 className="project-title">
                                        {_.isEmpty(this.props.data.project.marketing_project_name) ? this.props.data.project.project_name : this.props.data.project.marketing_project_name}
                                    </h4>
                                    <div className="box-footer d-flex justify-content-between align-items-center">
                                        { this.props.data.project.project_city && this.props.data.project.project_city.includes('Multiple') ?
                                            <span className="state">{this.show_states(this.props.data.project.states)}</span>
                                            :
                                            <span className="state">
                                                {this.props.data.project.project_city}, {this.props.data.project.project_state}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="project-slider-section">
                    <Container>
                        <Row>
                        <Col xs={12} className="px-0">
                        <div className="image-view">
                            {images.length < 1 && <img src={this.props.coverImage} alt="photo" style={coverImgStyle}/>}
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={images[photoIndex]}
                                                nextSrc={images[(photoIndex + 1) % images.length]}
                                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                                })
                                                }
                                                onMoveNextRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + 1) % images.length,
                                                })
                                                }
                                            />
                                        )}
                                    </div>
                                <div className="project-gallery">
                                    <div className="gallery-slider px-0">
                                        <div>
                                            <Slider {...settings}>
                                                {this.props.data.project.images.length > 0 ? this.props.data.project.images.map((image, index) => {
                                                        return (
                                                            <div>
                                                                <div className="img-box mx-auto">
                                                                    <a className="img-link" onClick={this.onImageClick} id={index}>
                                                                        <img src={image.url}
                                                                             alt="photo" onClick={(e) => {
                                                                            this.props.updateCurrentImage(e)
                                                                        }}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                           </Col>
                        </Row>
                        <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                    </Container>
                </section>
                <section className="project-description">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="project-info project-info-mobile">
                                    <div className="project-attributes">
                                        <div className="cards-pane">
                                            {this.props.ShowStories(this.props.data.project)}
                                            {this.props.projectUnits(this.props.data.project)}
                                            {this.props.data.project.square_footage_number > 0 &&
                                            <div>
                                                <h3>
                                                    {this.props.data.project.square_footage}
                                                </h3>
                                                <span>
                                                    Square Feet
                                                </span>
                                            </div>
                                            }
                                        </div>

                                        <Accordion>
                                            <Card className="info-card info-card-mobile">
                                                <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" className={`${this.state.isActiveType? 'active' : "" }`} onClick={this.openAccordionType}>
                                                    Type of Project
                                                </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul>
                                                            <li>{this.props.data.project.project_type_name}</li>
                                                        </ul>
                                                        <ul>
                                                            <li>{this.props.data.project.project_sub_type_name}</li>
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <Accordion>
                                            <Card className="info-card info-card-mobile">
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className={`${this.state.isActiveInvolvement? 'active' : "" }`} onClick={this.openAccordionInvolvement}>
                                                        Ryan Responsibility
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>
                                                            {this.props.getProjectServices(this.props.data.project, RyanServicesMapping, ArchitectureServices)}
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <Accordion>
                                            <Card className="info-card info-card-mobile">
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1"
                                                                      className={`${this.state.isActiveAward ? 'active' : ""}`}
                                                                      onClick={this.openAccordionAward}>
                                                        Certifications & Recognition
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>
                                                            {Object.entries(_.groupBy(this.props.data.project.awards, awd => awd.issuer))
                                                                .map((awd, index) => {
                                                                    return (<AwardList index={index} triggerOn={['click']} name={awd[0]} issuer={awd[1]} getProjectAwards={this.props.getProjectAwards}/>)
                                                                })
                                                            }
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>

                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="project-details">
                                    <div className="description-heading">
                                        <h5>
                                            Description
                                        </h5>
                                    </div>
                                    {this.props.renderDescription(this.props.data.projectDescription)}
                                </div>
                                {!_.isEmpty(this.props.data.projectDescription) && this.props.data.projectDescription.length > 250 ?
                                <div className="read-more">
                                    <a onClick={()=> this.props.onReadMore()}>
                                        {linkName}
                                        <span className={`icon ml-2 ${arrow}`}>
                                            <img src={iconDown} alt="" />
                                        </span>
                                    </a>
                                </div>
                                : ''}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        );
    }
});
