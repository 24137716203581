import React from 'react';
import {Button,Modal} from "react-bootstrap";
import close from '../../../assets/images/icons/close-circle.svg';
import warning from '../../../assets/images/icons/alert-triangle.svg';
import {trackPromise} from "react-promise-tracker";
import rpa from "../../../apis/rpa";
import {toast} from "react-toastify";
const DeleteNestedProject = (props) => {

    const deleteSearchListProject = () => {
        props.onDeleteHide()
        trackPromise(rpa.delete(`/users/${props.userDetails.sub}/save_lists/${props.listId}/projects/${props.projectId}/delete_project`, {
            params: {
                page: props.currentPage || 1,
                sortBy: props.sortBy
            }
        }).then(response => {
            if(response.data.success){
                props.UpdateCurrentListOnProjectDelete(response.data.save_lists[0])
                toast.success("Project Deleted Successfully")
            }
            else {
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Delete List Error', err)
        }), 'delete-save-list-project');
    }
    const show = props.isOpenDelete
    return (
        <>
            <Modal show={show} onHide={props.onDeleteHide} size="sm" className="delete-modal" centered>
                <Modal.Header>
                    <Modal.Title>Delete Project Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="msg-box">
                        <div className="close-box text-right">
                            <Button variant="close" onClick={props.onDeleteHide}>
                                <img src={close} alt="" />
                            </Button>
                        </div>
                        <div className="media">
                            <img src={warning} alt="" />
                        </div>
                        <p>
                            Are you sure you want to delete <br/>
                            {`"${props.projectName}"`}
                        </p>
                        <Button variant="primary delete" onClick={deleteSearchListProject}>
                            Yes, Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default DeleteNestedProject;
