import React, { Component } from 'react';
import _ from "lodash";
import iconBack from "../../assets/images/icons/back-btn.svg";
import {withRouter} from 'react-router-dom';
export default (withRouter(class ShareAblePageTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
    }
    goBackButton = () => {
        if(this.props.currentPage > 1){
            this.props.history.push(`/shareable?token=${this.props.list.token}`, {currentPage: this.props.currentPage})
        }else{
            this.props.history.push(`/shareable?token=${this.props.list.token}`)
        }
    }
    extractUsername = () => {
        return !_.isEmpty(this.props.list) ? this.getUsername(this.props.list.user_name) : this.getUsername(this.props.project.user_name)
    }
    getUsername = (username) => {
        return _.isEmpty(username) ? "" : username.split("@")[0]
    }
    extractFullName = (email=true) => {
        return !_.isEmpty(this.props.list) ? this.getFullName(this.props.list, email) : this.getFullName(this.props.project, email)
    }
    getFullName = (data, email) => {
        return email ? `${data.user_first_name}.${data.user_last_name}@ryancompanies.com`.toLowerCase() : `${data.user_first_name} ${data.user_last_name}`
    }
    render () {
        return (
            <div className="share-top">
                <div className="profile-box">
                    {this.props.from == 'detailPage' &&
                        <a className="btn btn-back" onClick={this.goBackButton}>
                            <img src={iconBack} alt="back" />
                        </a>
                    }
                    <div className="media">
                        {(this.props.list || this.props.project) &&
                            <img src={`https://rpaprofiles.sfo2.cdn.digitaloceanspaces.com/${this.extractUsername()}.jpg`} alt="photo"/>
                        }
                        {/*<img src={profile} alt="photo"/>*/}
                    </div>
                    <div className="name-part">
                        <h3 className="name">
                            {!_.isEmpty(this.props.list) ? this.props.list.name : this.props.project.project_name}
                        </h3>
                        <span>Shared by {this.extractFullName(false)}</span>
                        <div>
                            <span>{this.extractFullName()}</span>
                        </div>
                    </div>
                </div>
                {!this.props.from == 'detailPage' &&
                <div className="count-pane">
                    <span className="count">
                        No. of projects: {!_.isEmpty(this.props.projects) ? this.props.projects.length : 0 }
                    </span>
                </div>
                }
            </div>
        );
    }
}));
