import React from 'react'
import Routes from "./Config/Routes";
import './assets/stylesheets/app.scss'
import {BrowserRouter as Router} from "react-router-dom";

function App() {
    return (
        <div className='ui'>
            <Router>
                <Routes/>
            </Router>
        </div>
    );
}

export default App;
