/* eslint-disable */
import React, { Component } from 'react';
import Slider from "react-slick";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import iconback from '../../assets/images/icons/icon-back-mobile.svg';
import iconDown from '../../assets/images/icons/small-down.svg';
import iconEdit from '../../assets/images/icons/Icon-edit.svg';
import uploadIcon from '../../assets/images/icons/edit-icon-white.svg';
import ImageUpload from "../Projects/ImageUpload";
import _ from "lodash";
import {ToastContainer} from 'react-toastify';
import saveIcon from '../../assets/images/icons/save-icon.svg';
import {Dropdown, Form} from "react-bootstrap";
import AppSpinner from "../Projects/AppSpinner";
import Lightbox from 'react-image-lightbox';
import SaveListSection from "./SaveListModule/SaveListSection";
import share from "../../assets/images/icons/share-icon.svg";
import ShareLink from "./shareLink";
import AwardList from "../shared/AwardList";
import {ArchitectureServices, RyanServicesMapping} from "../shared/FilterConstant";
export default class ShowProjectMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveType: false,
            isActiveInvolvement: false,
            isActiveAward: false,
            readMore: false,
            descriptionData: [],
            isUploadModalOpen: false,
            editable: 0,
            projectDescription: '',
            isOpen: false,
            photoIndex: 0
        }
    }
    componentDidMount() {
        if (!_.isEmpty(this.props.data.project.description)) {
            let descriptionData = this.props.data.project.description.split('\n').filter(str => {
                if (str !== "") {
                    return str
                }
            })
            this.setState({projectDescription: descriptionData})
        }
    }
    show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }

    openAccordionType = (e) => {
        this.setState({isActiveType: !this.state.isActiveType})
    }
    openAccordionInvolvement = (e) => {
        this.setState({isActiveInvolvement: !this.state.isActiveInvolvement})
    }
    openAccordionAward = (e) => {
        this.setState({isActiveAward: !this.state.isActiveAward})
    }
    // renderDescriptionMobile = (des) => {
    //     if (!_.isEmpty(this.state.projectDescription)) {
    //         return (<p>{this.state.projectDescription}</p>)
    //     }
    // }
    ShowExtraContent = () => {
        return (this.state.descriptionData.slice(1).map(str => <p>{str}</p>))
    }
    onImageClick = (e) => {
        let index = e.currentTarget.id
        this.setState({ isOpen: true, photoIndex: index })
    }
    render() {
        const settings = {
            dots: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '30px',
        };
        const { photoIndex, isOpen } = this.state;
        const linkName = this.props.readMore? 'Read Less' : 'Read More'
        const arrow = this.props.readMore? 'upward' : 'downward'
        let images = [];
        if (this.props.data.project) {
            images = this.props.data.project.images.map(obj => {return obj.url});
        }
        const coverImgStyle = {
            maxWidth: '100%'
        }
        return (
            <main className="description-page-content-mobile">
                <AppSpinner customClass="delete-loader" area="file-upload-loader"/>
                <section className="project-title-pane">
                    <Container>
                        <Row>
                            <Col xs={12} className="title-col d-flex">
                                <div className="back-pane">
                                    <a className="back-link" onClick={this.props.goBackButton}>
                                        <img src={iconback} alt="back-icon" />
                                    </a>
                                </div>
                                <div className="project-title-box">
                                    <h4 className="project-title">
                                        {_.isEmpty(this.props.data.project.marketing_project_name) ? this.props.data.project.project_name : this.props.data.project.marketing_project_name}
                                    </h4>
                                    <div className="box-footer d-flex justify-content-between align-items-center">
                                        { this.props.data.project.project_city && this.props.data.project.project_city.includes('Multiple') ?
                                            <span className="state">{this.show_states(this.props.data.project.states)}</span>
                                            :
                                            <span className="state">
                                                {this.props.data.project.project_city}, {this.props.data.project.project_state}
                                            </span>
                                        }
                                        <Button variant="btn-option save-btn share-ind-project">
                                            <img src={share} alt="share" onClick={(e)=> this.props.handleShareLink(e)}/>
                                        </Button>
                                        {this.props.openShare &&
                                            <ShareLink show={this.props.openShare} handleShareClose={() => this.props.handleShareClose()}
                                                       projectShare='projectShareLink'
                                                       userDetails={this.props.userDetails}
                                                       project={this.props.project}
                                            />
                                        }
                                        <Dropdown className="search-dropdown">
                                            <Dropdown.Toggle  variant="save save-btn" id="close-save-list">
                                                Save Project to List
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="p-0">
                                                <SaveListSection closeDropMenu={this.props.closeDropMenu} userDetails={this.props.userDetails} selectedProjects={[this.props.project.id]}/>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="project-slider-section">
                    <Container>
                        <Row>
                        <Col xs={12} className="px-0">
                        <div className="image-view">
                            {images.length < 1 && <img src={this.props.coverImage} alt="photo" style={coverImgStyle}/>}
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={images[photoIndex]}
                                                nextSrc={images[(photoIndex + 1) % images.length]}
                                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                                })
                                                }
                                                onMoveNextRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + 1) % images.length,
                                                })
                                                }
                                            />
                                        )}
                                    </div>
                                <div className="project-gallery">
                                    <div className="gallery-slider px-0">
                                        <div>
                                            <Slider {...settings}>
                                                {this.props.data.project.images.length > 0 ? this.props.data.project.images.map((image, index) => {
                                                        return (
                                                            <div>
                                                                <div className="img-box mx-auto">
                                                                    <a className="img-link" onClick={this.onImageClick} id={index}>
                                                                        <img src={image.url}
                                                                             alt="photo" onClick={(e) => {
                                                                            this.props.updateCurrentImage(e)
                                                                        }}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            {this.props.userDetails && this.props.verifyUserRights() ?
                                <div className="gallery-upload-box">
                                    <Button variant="upload-img" onClick={this.props.onUploadModalHide}>
                                        <img src={uploadIcon} alt="upload" />
                                        Edit Gallery Images
                                    </Button>
                                </div> : ''}
                                <ImageUpload isUploadModalOpen={this.props.data.isUploadModalOpen} onUploadModalHide={this.props.onUploadModalHide} updateStateDate={this.props.updateStateDate} images={this.props.data.project.images} project={this.props.data.project} screenWidth={this.props.width} updateTime={this.props.updateTime}/>

                        </Col>
                        </Row>
                        <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                    </Container>
                </section>
                <section className="project-description">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="project-info project-info-mobile">
                                    <div className="project-attributes">
                                        <div className="cards-pane">
                                            {this.props.ShowStories(this.props.data.project)}
                                            {this.props.projectUnits(this.props.data.project)}
                                            {this.props.data.project.square_footage_number > 0 &&
                                            <div>
                                                <h3>
                                                    {this.props.data.project.square_footage}
                                                </h3>
                                                <span>
                                                    Square Feet
                                                </span>
                                            </div>
                                            }
                                        </div>

                                        <Accordion>
                                            <Card className="info-card info-card-mobile">
                                                <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0" className={`${this.state.isActiveType? 'active' : "" }`} onClick={this.openAccordionType}>
                                                    Type of Project
                                                </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul>
                                                            <li>{this.props.data.project.project_type_name}</li>
                                                        </ul>
                                                        <ul>
                                                            <li className="list-sub-type">{this.props.data.project.project_sub_type_name}</li>
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        <Accordion>
                                            <Card className="info-card info-card-mobile">
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className={`${this.state.isActiveInvolvement? 'active' : "" }`} onClick={this.openAccordionInvolvement}>
                                                        Ryan Responsibility
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <ul>{this.props.getProjectServices(this.props.data.project, RyanServicesMapping, ArchitectureServices)}</ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                        { !_.isEmpty(this.props.project.awards) &&
                                            <Accordion>
                                                <Card className="info-card info-card-mobile">
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey="1"
                                                                      className={`${this.state.isActiveAward ? 'active' : ""}`}
                                                                      onClick={this.openAccordionAward}>
                                                            Certifications & Recognition
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <ul>
                                                                {Object.entries(_.groupBy(this.props.data.project.awards, awd => awd.issuer))
                                                                    .map((awd, index) => {
                                                                        return (<AwardList index={index} triggerOn={['click']} name={awd[0]} issuer={awd[1]} getProjectAwards={this.props.getProjectAwards}/>)
                                                                    })
                                                                }
                                                            </ul>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="project-details">
                                    <div className="description-heading">
                                        <h5>
                                            Description
                                        </h5>
                                        {this.props.userDetails && this.props.verifyUserRights() ?
                                        (this.props.data.editable === 0) && <Button variant="edit" onClick={this.props.updateEditable}>
                                                <img src={iconEdit} alt="edit"/>
                                            </Button> : '' }
                                    </div>
                                    {(this.props.data.editable === 1) &&
                                    <Form className="form-description-edit" onSubmit={this.props.descriptionFormSubmit}>
                                        <Button variant="edit edit-save" type="submit">
                                            <img src={saveIcon} alt="save"/>
                                        </Button>
                                        <Form.Group>
                                            <Form.Control as="textarea" row="5" aria-label="With textarea" value={this.props.data.projectDescription} onChange={this.props.textChanged} />
                                        </Form.Group>
                                    </Form>}
                                    {(this.props.data.editable === 0) && this.props.renderDescription(this.props.data.projectDescription)}
                                    {/* {this.renderDescriptionMobile(this.props.data.project.description)} */}
                                </div>
                                {!_.isEmpty(this.props.data.projectDescription) && this.props.data.projectDescription.length > 250 ?
                                <div className="read-more">
                                    <a onClick={()=> this.props.onReadMore()}>
                                        {linkName}
                                        <span className={`icon ml-2 ${arrow}`}>
                                            <img src={iconDown} alt="" />
                                        </span>
                                    </a>
                                </div>
                                : ''}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
        );
    }
};
