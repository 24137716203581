import React from 'react';
import {Button,Modal} from "react-bootstrap";
import close from '../../../assets/images/icons/close-circle.svg';
import warning from '../../../assets/images/icons/alert-triangle.svg';
import {trackPromise} from "react-promise-tracker";
import rpa from "../../../apis/rpa";
import {toast} from "react-toastify";
const DeleteModal = (props) => {

    const deleteSearchList = () => {
        props.onDeleteHide()
        trackPromise(rpa.delete(`/users/${props.userDetails.sub}/search_lists/${props.id}`, {
            params: {
            }
        }).then(response => {
            if(response.data.success){
                // props.onDeleteHide()
                props.updateSelectedList(response.data.search_list)
            }
            else {
                toast.error(response.data.errors.name[0])
                // props.onDeleteHide()
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Delete List Error', err)
        }), 'delete-search-list');
    }
    const show = props.isOpen
        return (
          <>
            <Modal show={show} onHide={props.onDeleteHide} size="sm" className="delete-modal" centered>
              <Modal.Header>
                <Modal.Title>Delete List Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="msg-box">
                      <div className="close-box text-right">
                        <Button variant="close" onClick={props.onDeleteHide}>
                          <img src={close} alt="" />
                        </Button>
                      </div>
                      <div className="media">
                          <img src={warning} alt="" />
                      </div>
                      <p>
                        Are you sure you want to delete <br/>
                          {props.listName}
                      </p>
                      <Button variant="primary delete" onClick={deleteSearchList}>
                        Yes, Delete
                      </Button>
                  </div>
              </Modal.Body>
            </Modal>
          </>
        );
};
export default DeleteModal;
