const config = {
    issuer: 'https://ryancompanies.okta.com',
    clientId: '0oa1k5qecqsgQZ4em0h8',
    redirectUri: 'https://ryanprojectapp.com/login/callback',
    pkce: true,
    scope: ['openid', 'profile'],
};

//scope: ['openid', 'profile', 'email', 'phone', 'address', 'groups', 'offline_access']

// local keys
// const config = {
//     issuer: 'https://dev-2019326.okta.com/oauth2/default',
//     clientId: '0oa2qcn1hGhjH5KXV5d6',
//     redirectUri: 'http://localhost:3000/login/callback',
//     pkce: true,
// };

//staging keys
// const config = {
//     issuer: 'https://dev-2019326.okta.com/oauth2/default',
//     clientId: '0oa1f7mxvy3Qq9Iqc5d6',
//     redirectUri: 'https://rpa-react-staging-cf283.ondigitalocean.app/login/callback',
//     pkce: true,
// };

//ryan companies keys
// const config = {
//     issuer: 'https://ryancompanies.okta.com/oauth2/default',
//     clientId: '0oa1k5qecqsgQZ4em0h8',
//     redirectUri: 'https://ryanprojectapp.com/login/callback',
//     pkce: true,
// };
export default config
