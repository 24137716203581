import React, { Component } from "react";
import { Form } from "react-bootstrap";
import proImage from "../../assets/images/project-image.png";
import { Link } from "react-router-dom";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import share from "../../assets/images/icons/share-icon-dark.svg";
import edit from "../../assets/images/icons/edit-icon-dark.svg";
import del from "../../assets/images/icons/trash-icon-red.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import saveIcon from "../../assets/images/icons/save-icon.svg";
import SearchParams from "../CustomHooks/SearchParams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import ShareLink from "../Projects/shareLink";

export default (class SavListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: undefined,
      err: undefined,
      projects: this.props.projects,
    };
    this.saveListSide = React.createRef();
  }
  onListClick = () => {
    SearchParams.setValue('sortKey', "")
    SearchParams.setValue('sortByData', "")
  }
  render() {
    const list = this.props.list;
    const projects = this.props.projects;
    let imgOne = proImage
    let imgTwo = proImage
    let imgThree = proImage
    if (!_.isEmpty(projects)) {
      imgOne = projects.length >= 1 ? projects[0].cover_url : proImage;
      imgTwo = projects.length >= 2 ? projects[1].cover_url : proImage;
      imgThree = projects.length >= 3 ? projects[2].cover_url : proImage;
    }
    return (
      <div className="saved-list-link">
        <div className="card saved-list-card">
          <Link
            to={{
              pathname: `/projects/save_list/${list.id}`,
              list: list,
            }}
            className=""
            onClick={()=> this.onListClick()}
          >
            <div className="card-header">
              <div className="img-gallery">
                <div className="main-img">
                  <div className="media">
                    <img className="img-fluid" src={imgOne || proImage} alt="" />
                  </div>
                </div>
                <div className="sub-img">
                  <div className="media">
                    <img className="" src={imgTwo || proImage} alt="" />
                  </div>
                  <div className="media">
                    <img className="" src={imgThree || proImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <div className="card-body">
            <Link
              to={{
                pathname: `/projects/save_list/${list.id}`,
                list: list,
              }}
              className="text-decoration-none"
            >
              <h3 className={`list-name list-${list.id}`}>{list.name}</h3>
            </Link>
            <Form.Group className="d-none" id={`list-field-${list.id}`}>
              <InputGroup className="">
                <FormControl
                  id={`name_${list.id}`}
                  onChange={this.props.onchangeListName}
                  name="editListName"
                  value={this.props.editListName}
                  placeholder="List name"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    role="button"
                    className="pointer"
                    onClick={this.props.onListNameUpdate}
                    id={list.id}
                  >
                    <img src={saveIcon} alt="save" />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </div>
          <div className="card-footer">
            <div className="counter">
              <span>{this.props.count}</span>
              Projects added
            </div>
            <ul className="options">
              <li>
                <Button
                    variant="option"
                    onClick={(e) => this.props.ShowBehalfList(e)}
                    id={list.id}
                    aria-label={list.token}
                    name={list.name}
                    className="behalf-list-img"
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </Button>
              </li>
              <li>
                <Button
                  variant="option"
                  onClick={(e) => this.props.handleShareLink(e)}
                  id={list.id}
                  aria-label={list.token}
                  name={list.name}
                >
                  <img src={share} alt="share" />
                </Button>
              </li>
              <li>
                <Button
                  variant="option"
                  onClick={this.props.onClickDelete}
                  id={list.id}
                  name={list.name}
                >
                  <img src={del} alt="del" />
                </Button>
              </li>
              <li>
                <Button
                  variant="option"
                  {...(this.props.isEdit
                    ? {}
                    : { onClick: (e) => this.props.onEditClick(e) })}
                  id={list.id}
                  name={list.name}
                >
                  <img src={edit} alt="filter" />
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
});
