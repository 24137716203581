import React, {useState} from 'react'
import proImage from '../../assets/images/project-image.png';
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import del from "../../assets/images/icons/trash-icon-red.svg";
import Button from "react-bootstrap/Button";
import DeleteNestedProject from "./SaveListModule/DeleteNestedProject";
import _ from "lodash";

function image_path (coverUrl) {
    if(!_.isEmpty(coverUrl)) {
        return coverUrl
    } else {
        return proImage
    }
}
const ProjectCard = ({ id, projectName, projectCity, projectState, listId, userDetails, UpdateCurrentListOnProjectDelete, coverUrl, currentPage, sortBy, multipleStates, sector }) => {
    const [show, setShow] = useState(false);
    const onDeleteHide = () => {
        setShow(!show)
    }
    const show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }

    return (
        <div>
            <div className="card project-card">
                <Link to={{ pathname: `/project/${id}`, state: {currentPage: currentPage, listId: listId}}} className="project-card-link">
                    <div className="img-pane media">
                        <LazyLoadImage
                            className="card-img-top"
                            alt="Project Image"
                            effect="blur"
                            src={image_path(coverUrl)}/>
                    </div>
                </Link>
                <div className="card-body">
                    <h4 className="card-text project-sector">
                        {projectName}
                    </h4>
                    <small>{sector}</small>
                    <div className="check-label-pane d-flex justify-content-between align-items-center">
                        { projectCity && projectCity.includes('Multiple') ?
                            <small>{show_states(multipleStates)}</small>
                            :
                            <small>{projectCity}, {projectState}</small>
                        }
                        <DeleteNestedProject isOpenDelete={show} listId={listId} userDetails={userDetails}
                                             projectId={id} onDeleteHide={onDeleteHide}
                                             UpdateCurrentListOnProjectDelete={UpdateCurrentListOnProjectDelete} projectName={projectName} currentPage={currentPage} sortBy={sortBy}/>
                        <Button variant="option p-0" onClick={onDeleteHide}>
                            <img src={del} alt="del"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default ProjectCard;
