import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {Provider} from 'react-redux';
import {combineReducers, createStore, compose, applyMiddleware} from 'redux';
import {sessionService, sessionReducer} from 'redux-react-session';
import thunkMiddleware from 'redux-thunk';

const reducer = combineReducers({
    session: sessionReducer
});
const store = createStore(reducer, undefined, compose(applyMiddleware(thunkMiddleware)));
sessionService.initSessionService(store).then(() =>
    console.log('Redux React Session is ready and a session was refreshed from your storage'))
    .catch(() =>
        console.log('Redux React Session is ready and there is no session in your storage'));
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
