const SearchParams = (function () {

    let getValue = function (key, initialValue='') {
        const item =  window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    };

    let setValue = function (key, value) {
        window.localStorage.setItem(key, value)
    };

    return {
        getValue: getValue,
        setValue: setValue
    }
})();

export default SearchParams;
