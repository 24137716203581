import React from 'react'
import {withRouter} from 'react-router-dom';
import proImage from '../../assets/images/project-image.png'
import rpa from "../../apis/rpa";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import _ from "lodash";
import iconLarge from "../../assets/images/icons/expand-icon.svg";
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "../Projects/AppSpinner";
import iconDown from "../../assets/images/icons/small-down.svg";
import {withOktaAuth} from "@okta/okta-react";
import SharedHeader from "../shareModule/SharedHeader";
import SharedAblePageTop from "../shareModule/ShareAblePageTop";
import {ArchitectureServices, RyanServicesMapping} from "../shared/FilterConstant"
import ShareProjectMobile from "./ShareProjectMobile";
import AwardList from "../shared/AwardList";
import {getProjectServices} from "../Actions/ProjectActions";
export default withOktaAuth(withRouter(class ShareProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: undefined,
            projectDescription: '',
            coverImage: undefined,
            coverName: '',
            coverValue: '',
            file: undefined,
            width:null,
            response: undefined,
            err: undefined,
            photoIndex: 0,
            isOpen: false,
            userDetails: null,
            userRights: null,
            updateTime: '',
            readMore: false,
            token: '',
            list: undefined,
            currentPage: 1,
            ryanServices: RyanServicesMapping,
            architectServices: ArchitectureServices
        }
        this.fileInput = React.createRef()
    }
    componentDidMount() {
        let token = this.props.match.params.token
        let currentPage = this.state.currentPage
        if(!_.isEmpty(this.props.location.state)){
            currentPage =  this.props.location.state.currentPage > 0 ? this.props.location.state.currentPage : 1
        }
        this.setState({token: token, currentPage: currentPage})
        this.getProject(token)
    }
    updateStateDate = (project) => {
        this.getProject(project.id)
    }
    getProject = (token) => {
        trackPromise(rpa.get(`/show_shareable_project?token=${token}`, {
        })
            .then(res => {
                let innerWidth = window.innerWidth
                if (!_.isEmpty(res.data) && !_.isEmpty(res.data)) {
                    let project = null
                    if(!_.isEmpty(res.data.project)) {
                        project = res.data.project
                        this.setState({
                            project: project,
                            width: innerWidth,
                            projectDescription: project.description
                        })
                        if (_.isEmpty(project.images)) {
                            this.setState({coverImage: proImage})
                        } else {
                            if (!_.isEmpty(project.cover_url)) {
                                this.setState({
                                    coverImage: project.cover_url,
                                    coverName: project.cover_name,
                                    coverValue: project.cover_value
                                })
                            } else {
                                this.setState({coverImage: proImage})
                            }
                        }
                    }
                }
            })
            .catch(err => {
                this.setState({err: err})
            }), 'file-upload-loader');
    }
    updateCurrentImage = (e) => {
        if (this.state.project) {
            this.setState({coverImage: e.target.src, photoIndex: e.target.id})
        }
    }
    projectStats = (value, stats) => {
        if (value !== 'NULL' && parseInt(value) > 0) {
            return (
                <div>
                    <h3>{value}</h3>
                    <span>{stats}</span>
                </div>
            )
        }
    }

    getProjectAwards = (awards) => {
        return( <ul className="list-sub-awards">{awards.map(award => <li>{award.name}</li>)} </ul>)
    }
    projectUnits = (project) => {
        if (project.project_type_name === 'Multifamily' || project.project_type_name === 'Senior Living') {
            return this.projectStats(project.no_of_keys_beds_units, "Units")
        }
        else if (project.project_type_name === 'Alternative Energy' || project.project_type_name === 'Entertainment' || project.project_type_name === 'Office'  || project.project_type_name === 'Parking' || project.project_type_name === 'Public' || project.project_type_name === 'Sector' || project.project_type_name === '"Public Sector"'){
            return this.projectStats(project.parking_stalls, "Parking Stalls")
        }
        else if (project.project_type_name === 'Hospitality'){
            return this.projectStats(project.no_of_keys_beds_units, "Keys/Units")
        }
        else if (project.project_type_name === 'Mission Critical'){
            return this.projectStats(project.no_of_keys_beds_units, "Const.Duration")
        }
        else if (project.project_type_name === 'R&D' || project.project_type_name === 'Education' || project.project_type_name === 'Religious' || project.project_type_name === 'Industrial' || project.project_type_name === 'Site Development'){
            return this.projectStats(project.site_acres, "Acres")
        } else {
            return ''
        }
    }
    ShowStories = (project) => {
        if (project.no_of_stories !== 'NULL' && !_.isEmpty(project.no_of_stories)) {
            return (
                <div>
                    <h3>
                        {this.state.project.no_of_stories}
                    </h3>
                    <span>Stories</span>
                </div>
            )
        }
    }
    renderDescription = (des) => {
        if (!_.isEmpty(des)){
            if(this.state.readMore){
                return (des.split('\n').map(str => <p>{str}</p>))
            } else{
                return <p>{des.substring(0,250)}</p>
            }
        }
        else
            return <div className="empty-msg-description"><strong></strong></div>
    }
    show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }
    closeDropMenu = () => {
        document.getElementById('close-save-list').click();
    }
    onReadMore = () => {
        this.setState({readMore: !this.state.readMore })
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        let images = [];
        if (this.state.project) { images = this.state.project.images.map(obj => {return obj.url}); }
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        };
        const arrow = this.state.readMore? 'upward' : 'downward'
        const linkName = this.state.readMore? 'Read Less' : 'Read More'
        return (
            <>{this.state.project && this.state.width <= 575 ? <ShareProjectMobile data={this.state} updateCurrentImage={this.updateCurrentImage} projectUnits={this.projectUnits} ShowStories={this.ShowStories} renderDescription={this.renderDescription}  updateStateDate={this.updateStateDate} readMore={this.state.readMore} onReadMore={this.onReadMore} closeDropMenu={this.closeDropMenu} project={this.state.project} userDetails={this.state.userDetails} coverImage={this.state.coverImage} width={this.state.width} updateTime={this.state.updateTime} userRights={this.state.userRights} getProjectServices={getProjectServices} getProjectAwards={this.getProjectAwards}/> :
            <main className="description-page-content">
                <SharedHeader/>
                <AppSpinner customClass="delete-loader" area="file-upload-loader"/>
                {this.state.project &&
                <div>
                <section className="page-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <SharedAblePageTop project={this.state.project}/>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                </section>
                <section className="project-description">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-8">
                                <div className="project-gallery">
                                    <div className="image-view">
                                        <img src={this.state.coverImage} alt="cover"/>
                                        <Button variant="large-link" onClick={() => this.setState({ isOpen: true })}>
                                            <img src={iconLarge} alt="icon" />
                                        </Button>
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={images[photoIndex]}
                                                nextSrc={images[(photoIndex + 1) % images.length]}
                                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                                })
                                                }
                                                onMoveNextRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + 1) % images.length,
                                                })
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="gallery-slider">
                                        <div>
                                            <Slider {...settings}>
                                                {this.state.project.images.length > 0 ? this.state.project.images.map((image, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className="img-box mx-auto">
                                                                    <a className="img-link">
                                                                        <img src={image.url}
                                                                             alt="photo"
                                                                             id={index}
                                                                             onClick={(e) => {
                                                                            this.updateCurrentImage(e)
                                                                        }}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-details">
                                    <div className="heading-box">
                                        <div className="heading">
                                            <h5>
                                                Description
                                            </h5>
                                        </div>
                                    </div>
                                    {this.renderDescription(this.state.projectDescription)}
                                    {!_.isEmpty(this.state.projectDescription) && this.state.projectDescription.length > 250 ?
                                        <div className="read-more">
                                            <a onClick={()=> this.onReadMore()}>
                                                {linkName}
                                                <span className={`icon ml-2 ${arrow}`}>
                                                <img src={iconDown} alt="" />
                                            </span>
                                            </a>
                                        </div>
                                        : '' }
                                </div>
                            </div>
                            <div className="col-12 col-md-6  col-lg-4">
                                <div className="project-info">
                                    <div className="project-title-pane">
                                        <h4>
                                            {_.isEmpty(this.state.project.marketing_project_name) ? this.state.project.project_name : this.state.project.marketing_project_name}
                                        </h4>
                                        { this.state.project.project_city && this.state.project.project_city.includes('Multiple') ?
                                            <span className="location">{this.show_states(this.state.project.states)}</span>
                                            :
                                            <span className="location">
                                                {this.state.project.project_city}, {this.state.project.project_state}
                                            </span>
                                        }
                                    </div>
                                    <div className="project-attributes">
                                        <div className="cards-pane">
                                            {this.ShowStories(this.state.project)}
                                            {this.projectUnits(this.state.project)}
                                            {this.state.project.square_footage_number > 0 &&
                                            <div>
                                                <h3>
                                                    {this.state.project.square_footage}
                                                </h3>
                                                <span>
                                                    Square Feet
                                                </span>
                                            </div>
                                            }
                                        </div>
                                        <div className="info-card">
                                            <h5>
                                                Type of Project
                                            </h5>
                                            <ul>
                                                <li>{this.state.project.project_type_name}</li>
                                            </ul>
                                            <ul>
                                                <li>{this.state.project.project_sub_type_name}</li>
                                            </ul>
                                        </div>
                                        <div className="info-card">
                                            <h5>
                                                Ryan Responsibility
                                            </h5>
                                            <ul>
                                                {getProjectServices(this.state.project, RyanServicesMapping, ArchitectureServices)}
                                            </ul>
                                        </div>
                                        { !_.isEmpty(this.state.project.awards) &&
                                            <div className="info-card">
                                                <h5>
                                                    Certifications & Recognition
                                                </h5>
                                                <ul>
                                                    {Object.entries(_.groupBy(this.state.project.awards, awd => awd.issuer))
                                                        .map((awd, index) => {
                                                            return (<AwardList index={index} triggerOn={['hover', 'focus', 'click']} name={awd[0]} issuer={awd[1]} getProjectAwards={this.getProjectAwards}/>)                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                </div>}
            </main>}
        </>
        );
    }
}));
