 /* eslint-disable */
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Form, Dropdown} from "react-bootstrap";
import iconUp from '../../assets/images/icons/down-arrow.svg'
import iconArrow from '../../assets/images/icons/arrow-white.svg'
import iconFilter from '../../assets/images/icons/filter-icon.svg'
import iconSave from '../../assets/images/icons/icon-save.svg'
import MultiSelect from "react-multi-select-component";
import _ from "lodash";
import {ToastContainer} from "react-toastify";
 import SearchListSection from "./SearchModule/SearchListSection";
 import {
    onFilterChangeUpdateSelectedSectors,
    onFilterClearAll,
     onFilterClearAllSelectedInvolvements,
    onFilterClearAllSelectedSectors,
    onFilterSectorChange,
    onFilterStateChange,
    verifyLocalSelectedStates
} from "../Actions/FilterActions";

export default withOktaAuth(class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extractedData: {},
            multiSelectedStates: [],
            showMultiSelectedStates: [],
            localTotalStates:[],
            localAbrStates:[],
            localTotalSectors:[],
            localAbrSectors:[],
            multiSelectedSectors: [],
            showMultiSelectedSectors: [],
            localTotalInvolvements:[],
            localAbrInvolvements:[],
            multiSelectedInvolvements: [],
            showMultiSelectedInvolvements: [],
            completionYear: '',
            rangCompletionYear: [],
            keywords: '',
            maxSquare: '',
            minSquare: '',
            filters: {},
            err: undefined,
            isActive:false,
            activeClasses: [false, false, false, false],
            activeList: false,
            isOpen: false,
            minSquareError: true,
            maxSquareError: true,
        }
        this.myState = React.createRef();
        this.myStateAccordion = React.createRef();
        this.myStateList = React.createRef();
        this.myStateListAccordion = React.createRef();
        this.myYear = React.createRef();
        this.myYearAccordion = React.createRef();
        this.mySector = React.createRef();
        this.mySectorAccordion = React.createRef();
        this.myInvovlement = React.createRef();
        this.myInvovlementAccordion = React.createRef();
        this.showMoreStates= this.showMoreStates.bind(this);
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.filters)){
            this.setState({filters: this.props.filters, extractedData: this.props.extractedData,
                showMultiSelectedStates: this.props.extractedData["selectedStates"], showMultiSelectedSectors: this.props.extractedData["SelectedSector"],
                localTotalStates: this.props.extractedData["totalStates"], localAbrStates: this.props.extractedData["states"],
                localTotalSectors: this.props.filters["totalSectors"], localAbrSectors: this.props.filters["sector"], localTotalInvolvements: this.props.filters["totalInvolvement"],
                localAbrInvolvements: this.props.filters["involvement"], showMultiSelectedInvolvements: this.props.filters["selectedInvolvement"],
                keywords: this.props.filters["keywords"], maxSquare: this.props.filters["maxSquare"], minSquare: this.props.filters["minSquare"],
                rangCompletionYear: this.props.filters["rangCompletionYear"], completionYear: this.props.filters["completionYear"]
            })
        }
    }
    filterOptions =(options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }
    updateButtonOnApply = () => {
        this.setState(prevState => {
            let activeClasses = Object.assign([], prevState.activeClasses);
            activeClasses = activeClasses.map((currElement) => {
                currElement =  false
                return currElement;
            });
            return {activeClasses};
        })
    }

    onChangeUpdateSelectedStates = (e) => {
        e = e.map(state => {state.checked = true; return state})
        let multiSelectedStates = e
        let showMultiSelectedStates = Object.assign([], this.state.showMultiSelectedStates);
        let localTotalStates = Object.assign([], this.state.localTotalStates);
        multiSelectedStates.map(state => {
            if (!this.state.showMultiSelectedStates.some(o => o.label === state.label)){
                showMultiSelectedStates.push(state)
            }
        })
        localTotalStates = localTotalStates.filter(state => {
            if (!multiSelectedStates.some(o => o.label === state.label)){
                state.checked = false
                return state
            }
        })
        localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
        showMultiSelectedStates = _.orderBy(showMultiSelectedStates, ['label'], ['asc']);
        let localAbrStates = showMultiSelectedStates.map(state => { return state.value });
        multiSelectedStates = []
        this.setState({multiSelectedStates: multiSelectedStates, showMultiSelectedStates: showMultiSelectedStates, localTotalStates: localTotalStates, localAbrStates: localAbrStates}, () => this.updatePayload())
    }

    closeSideFilter = (e) => {
        document.body.classList.remove('asside-filter-open');
    }

    showMoreStates = index => {
        this.setState(prevState => {
            let activeClasses = Object.assign([], prevState.activeClasses);
            let activeList = prevState.activeList
            if (index===0 && activeList){
                activeList = !activeList
                this.myStateList.current.parentElement.classList.remove('show')
            }
            activeClasses = activeClasses[index] ? activeClasses.map(currElement => currElement = false) : activeClasses.map((currElement, i) => currElement = (i === index))
            return {activeClasses, activeList};
        })
    }

    onStateChange = (e) => {
        let updatedData = onFilterStateChange(this.state, e)
        this.setState(updatedData, () => this.updatePayload())
    }

    onClearAllSelectedStates = () => {
        let localTotalStates =  Object.assign([], this.state.localTotalStates);
        localTotalStates = localTotalStates.map(localState => {localState.checked = false; return localState})
        // this.state.showMultiSelectedStates.forEach(selectedState => localTotalStates.push(selectedState))
        localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
        let localAbrStates = localTotalStates.filter(localState => localState.checked)
        this.setState({showMultiSelectedStates: [], localAbrStates: localAbrStates, localTotalStates: localTotalStates}, () => this.updatePayload())
    }
    onClearAll = () => {
        this.myYear.current.parentElement.classList.remove('show')
        this.myYearAccordion.current.classList.add('hide-filter')
        this.setState(onFilterClearAll(this.state), () => this.updatePayload())
    }
    onClearAllSelectedSectors = () => {
        this.setState(onFilterClearAllSelectedSectors(this.state), () => this.updatePayload())
    }
    onClearAllSelectedInvolvements = () => {
        this.setState(onFilterClearAllSelectedInvolvements(this.state), () => this.updatePayload())
    }
    onClearSquareFeet = () => {
        this.setState({maxSquare: '', minSquare: ''}, () => this.updatePayload())
    }
    onClearKeywords = () => {
        this.setState({keywords: ''}, () => this.updatePayload())
    }

    updatePayload = () => {
        this.updateButtonOnApply()
        let filters =  Object.assign({}, this.state.filters);
        filters["selectedStates"] = this.state.showMultiSelectedStates
        filters["states"] = this.state.localAbrStates
        filters["SelectedSector"] = this.state.showMultiSelectedSectors
        filters["totalStates"] = this.state.localTotalStates
        filters["totalSectors"] = this.state.localTotalSectors
        filters["totalInvolvement"] = this.state.localTotalInvolvements
        filters["rangCompletionYear"] = this.state.rangCompletionYear
        filters["sector"] = this.state.localAbrSectors
        filters["selectedInvolvement"] = this.state.showMultiSelectedInvolvements
        filters["involvement"] = this.state.localAbrInvolvements
        filters["keywords"] = this.state.keywords
        filters["minSquare"] = this.state.minSquare
        filters["maxSquare"] = this.state.maxSquare
        filters["rangCompletionYear"] = this.state.rangCompletionYear
        filters["completionYear"] = this.state.completionYear
        this.setState({filters: filters}, () => this.props.updateFilters(filters))
    }
    onChangeUpdateSelectedSectors = (e) => {
        let updatedData = onFilterChangeUpdateSelectedSectors(this.state, e)
        this.setState(updatedData, () => this.updatePayload())
    }

    onSectorChange = (e) => {
        let updatedData = onFilterSectorChange(this.state, e)
        this.setState(updatedData, () => this.updatePayload())
    }
    onChangeUpdateSelectedInvolvement = (e) => {
        e = e.map(inv => {inv.checked = true; return inv})
        let multiSelectedInvolvements = e
        let showMultiSelectedInvolvements = Object.assign([], this.state.showMultiSelectedInvolvements);
        let localTotalInvolvements = Object.assign([], this.state.localTotalInvolvements);
        multiSelectedInvolvements.map(inv => {
            if (!this.state.showMultiSelectedInvolvements.some(o => o.label === inv.label)){
                showMultiSelectedInvolvements.push(inv)
            }
        })
        localTotalInvolvements = localTotalInvolvements.filter(inv => {
            if (!multiSelectedInvolvements.some(o => o.label === inv.label)){
                inv.checked = false
                return inv
            }
        })
        localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);
        showMultiSelectedInvolvements = _.orderBy(showMultiSelectedInvolvements, ['label'], ['asc']);
        let localAbrInvolvements = showMultiSelectedInvolvements.map(inv => { return inv.value });
        multiSelectedInvolvements = []
        this.setState({multiSelectedInvolvements: multiSelectedInvolvements, showMultiSelectedInvolvements: showMultiSelectedInvolvements, localTotalInvolvements: localTotalInvolvements, localAbrInvolvements: localAbrInvolvements}, () => this.updatePayload())
    }
    onInvolvementChange = (e) => {
        let filters =  Object.assign({}, this.state.filters);
        let localTotalInvolvements =  Object.assign([], this.state.localTotalInvolvements);
        let selectedInvolvements = this.state.showMultiSelectedInvolvements.filter(inv => {
            if (e.target.name !== inv.label){
                return inv
            }
            else {
                inv.checked = false
                localTotalInvolvements.push(inv)
            }
        })
        let selectedInvolvementsAbr = this.state.localAbrInvolvements.filter(invAbr => {
            if (e.target.id !== invAbr){
                return invAbr
            }
        })
        localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);
        selectedInvolvements = _.orderBy(selectedInvolvements, ['label'], ['asc']);
        filters["selectedInvolvement"] = selectedInvolvements
        filters["involvement"] = selectedInvolvementsAbr
        this.setState({showMultiSelectedInvolvements: selectedInvolvements, localAbrInvolvements: selectedInvolvementsAbr, localTotalInvolvements: localTotalInvolvements}, () => this.updatePayload())
    }
    updateKeywords = (e) => {
        this.setState({keywords: e.target.value})
    }
    updateMin = (e) => {
        this.setState({minSquare: e.target.value}, ()=> this.isNumber(e))
    }
    updateMax = (e) => {
        this.setState({maxSquare: e.target.value}, ()=> this.isNumber(e))
    }
    onFormSubmitSquareFootage = () => {
        if (this.state.maxSquareError && this.state.minSquareError) {
            this.updatePayload()
        }
    }
    onFormSubmitKeywords = () => {
        this.updatePayload()
    }
    onYearFormSubmit = () => {
        this.myYear.current.parentElement.classList.remove('show')
        this.myYearAccordion.current.classList.add('hide-filter')
        this.updateButtonOnApply()
        let rangCompletionYear = Object.assign([], this.state.rangCompletionYear);
        let completionYear = Object.assign([], this.state.completionYear);
        rangCompletionYear.map(obj => {
            if(obj.checked === true){
                completionYear = obj
            }
            return obj;
        })
        this.setState({rangCompletionYear: rangCompletionYear, completionYear: completionYear}, () => this.updatePayload())
    }
    onYearListChange = (e) => {
        this.myYear.current.parentElement.classList.remove('show')
        this.myYearAccordion.current.classList.add('hide-filter')
        this.updateButtonOnApply()
        let rangCompletionYear = Object.assign([], this.state.rangCompletionYear);
        let completionYear = Object.assign([], this.state.completionYear);
        if(e.target.checked === true){
            rangCompletionYear = rangCompletionYear.map(obj => {
                if(e.target.id === obj.label){
                    obj.checked = e.target.checked
                    completionYear = obj
                }
                else{
                    obj.checked = false
                }
                return obj;
            })
        }
        else if(e.target.checked === false){
            rangCompletionYear = rangCompletionYear.map(obj => {
                obj.checked = false
                return obj;
            })
            completionYear = ''
        }
        this.setState({rangCompletionYear: rangCompletionYear, completionYear: completionYear}, () => this.updatePayload())
    }
    onYearSelectedChange = () => {
        this.myYear.current.parentElement.classList.remove('show')
        this.myYearAccordion.current.classList.add('hide-filter')
        let completionYear = Object.assign([], this.state.completionYear);
        let rangCompletionYear = Object.assign([], this.state.rangCompletionYear);
        rangCompletionYear = rangCompletionYear.map(obj => {
            obj.checked = false
            return obj;
        })
        completionYear = ''
        this.setState({completionYear: completionYear, rangCompletionYear: rangCompletionYear}, () => this.updatePayload())
    }

    closeDropdown = () => {
        document.getElementById('save-search-dropdown').click();
    }
    isNumber = (e) => {
        const reg = /^[0-9\b]+$/;
        _.isEmpty(e.target.value) ?
            this.setState({[e.target.name+"Error"]: true})
            : this.setState({[e.target.name+"Error"]: reg.test(e.target.value)})
    }

    render() {
        const activeClasses = this.state.activeClasses.slice();
        return (
            <>
                <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {!_.isEmpty(this.state.filters) &&
                <aside className={'filters-pane'}>
                    <div className="filter-heading">
                        <span className="icon-pane">
                            <img src={iconFilter} alt="icon"/>
                        </span>
                        <Dropdown className="search-dropdown">
                            <Dropdown.Toggle variant="" id="save-search-dropdown">
                                Save My Search
                                <img src={iconSave} alt="icon"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <SearchListSection closeDropdown={this.closeDropdown}  closeSideFilter={this.closeSideFilter} userDetails={this.props.userDetails} filters={this.state.filters}/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <Accordion>
                        <Form className="form-general side-filter-form">
                            <Card className="states-datas filter-card">
                                <Card.Header className="">

                                    <div className={`filter-actions ${activeClasses[0]? "filter-actions" : "hide-filter"}`} onClick={() => this.showMoreStates(0)} ref={this.myStateAccordion}>

                                        <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center" variant="link" eventKey="0">
                                            <strong className="filter-name">
                                                States
                                            </strong>
                                            <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                        </Accordion.Toggle>
                                    </div>

                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body ref={this.myState} className="states-data" >
                                            {/*<Form.Group controlId="formStateFilter">*/}
                                            {/*    <MultiSelect*/}
                                            {/*        options={this.state.localTotalStates}*/}
                                            {/*        value={this.state.multiSelectedStates}*/}
                                            {/*        onChange={(e) => this.onChangeUpdateSelectedStates(e)}*/}
                                            {/*        // labelledBy={"Select"}*/}
                                            {/*        hasSelectAll={false}*/}
                                            {/*        // filterOptions={this.filterOptions}*/}
                                            {/*        // overrideStrings={{*/}
                                            {/*        //     // "search": "Type to filter",*/}
                                            {/*        //     "selectSomeItems": "Select"*/}
                                            {/*        // }}*/}
                                            {/*        disableSearch={true}*/}
                                            {/*        isOpen={true}*/}
                                            {/*    />*/}
                                            {/*</Form.Group>*/}
                                        <ul className="filtered-list">
                                            {this.state.localTotalStates.map(selected => {
                                                // if (this.props.projectByStates[selected.value] > 0){
                                                return (
                                                    <li key={selected.label}>
                                                        <Form.Group controlId={selected.label}>
                                                            {selected.label}
                                                            <div className="count-check">
                                                                {/*<span className="counter">{this.props.projectByStates[selected.value]}</span>*/}
                                                                { selected.checked &&
                                                                    <span className="counter">{this.props.projectByStates[selected.value] > 0 ? this.props.projectByStates[selected.value] : '0'}</span>
                                                                }
                                                                <Form.Check htmlFor={selected.label} type="checkbox"
                                                                            label="" id={selected.value}
                                                                            name={selected.label}
                                                                            checked={selected.checked}
                                                                            onChange={this.onStateChange}/>
                                                            </div>
                                                        </Form.Group>
                                                    </li>
                                                )
                                                // }
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Form>
                        <Form className="form-general side-filter-form">
                            <Card className="filter-card">
                                <Card.Header className="">

                                    <div className={`filter-actions ${activeClasses[1]? "filter-actions" : "hide-filter"}`} onClick={() => this.showMoreStates(1)} ref={this.myYearAccordion}>

                                        <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center" variant="link" eventKey="1">
                                            <strong className="filter-name">
                                                Completion Year
                                            </strong>
                                            <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                        </Accordion.Toggle>
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body ref={this.myYear}>
                                        <ul className="completion-year-options filtered-list">
                                            {this.state.rangCompletionYear.map(selected => {
                                                if(!selected.checked){
                                                    return (
                                                        <li key={selected.label}>
                                                            <Form.Group controlId={selected.label}>
                                                                {selected.label}
                                                                <div className="count-check">
                                                                    <Form.Check htmlFor={selected.label} type="checkbox" label="" id={selected.label} name={selected.label}
                                                                                checked={selected.checked} onChange={this.onYearListChange}/>
                                                                </div>
                                                            </Form.Group>
                                                        </li>
                                                    )
                                                }
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="states-data">
                                <div className="data-pane">
                                    <ul className="filtered-list">
                                        { !_.isEmpty(this.state.completionYear)?
                                            <li>
                                                <Form.Group controlId={this.state.completionYear.label}>
                                                    {this.state.completionYear.label}
                                                    <div className="count-check">
                                                        <Form.Check htmlFor={this.state.completionYear.label} type="checkbox" label="" id={this.state.completionYear.label} name={this.state.completionYear.label}
                                                                    checked={this.state.completionYear.checked} onChange={this.onYearSelectedChange}/>
                                                    </div>
                                                </Form.Group>
                                            </li>
                                            : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Form>
                        <Form className="form-general side-filter-form">
                            <Card className="filter-card">
                                <Card.Header className="">

                                    <div className={`filter-actions ${activeClasses[2]? "filter-actions" : "hide-filter"}`} onClick={() => this.showMoreStates(2)} ref={this.mySectorAccordion}>

                                        <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center" variant="link" eventKey="2">
                                            <strong className="filter-name">
                                                Sector
                                            </strong>
                                            <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                        </Accordion.Toggle>
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body ref={this.mySector}>
                                            <Form.Group controlId="formSectorFilter">
                                                <MultiSelect
                                                    options={this.state.localTotalSectors}
                                                    value={this.state.multiSelectedSectors}
                                                    onChange={(e) => this.onChangeUpdateSelectedSectors(e)}
                                                    labelledBy={"Select"}
                                                    hasSelectAll={false}
                                                    filterOptions={this.filterOptions}
                                                    overrideStrings={{
                                                        "search": "Type to filter",
                                                        "selectSomeItems": "Select"
                                                    }}
                                                    isOpen={true}
                                                />
                                            </Form.Group>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="states-data">
                                <div className="data-pane">
                                    <ul className="filtered-list">
                                        {this.state.showMultiSelectedSectors.map(selected => {
                                            // if (this.props.projectBySectors[selected.value] > 0){
                                                return (
                                                    <li key={selected.label}>
                                                        <Form.Group controlId={selected.label}>
                                                            {selected.label}
                                                            <div className="count-check">
                                                                {/*<span className="counter">{this.props.projectBySectors[selected.value]}</span>*/}
                                                                <span className="counter">{this.props.projectBySectors[selected.value] > 0 ? this.props.projectBySectors[selected.value] : '0' }</span>
                                                                <Form.Check htmlFor={selected.label} type="checkbox" label="" id={selected.value} name={selected.label}
                                                                            checked={selected.checked} onChange={this.onSectorChange}/>
                                                            </div>
                                                        </Form.Group>
                                                    </li>
                                                )
                                            // }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Form>
                        <Form className="form-general side-filter-form">
                            <Card className="filter-card">
                                <Card.Header className="">

                                    <div className={`filter-actions ${activeClasses[3]? "filter-actions" : "hide-filter"}`} onClick={() => this.showMoreStates(3)} ref={this.myInvovlementAccordion}>
                                        <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center" variant="link" eventKey="3">
                                            <strong className="filter-name">
                                                Ryan Involvement
                                            </strong>
                                            <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                        </Accordion.Toggle>
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body ref={this.myInvovlement}>
                                            <Form.Group controlId="formSectorFilter">
                                                <MultiSelect
                                                    options={this.state.localTotalInvolvements}
                                                    value={this.state.multiSelectedInvolvements}
                                                    onChange={(e) => this.onChangeUpdateSelectedInvolvement(e)}
                                                    labelledBy={"Select"}
                                                    hasSelectAll={false}
                                                    filterOptions={this.filterOptions}
                                                    overrideStrings={{
                                                        "search": "Type to filter",
                                                        "selectSomeItems": "Select"
                                                    }}
                                                    isOpen={true}
                                                />
                                            </Form.Group>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="states-data">
                                <div className="data-pane">
                                    <ul className="filtered-list">
                                        {this.state.showMultiSelectedInvolvements.map(selected => {
                                            return (
                                                <li key={selected.label}>
                                                    <Form.Group controlId={selected.label}>
                                                        {selected.label}
                                                        <div className="count-check">
                                                            <span className="counter">{this.props.projectByInvolvement[selected.value] ? this.props.projectByInvolvement[selected.value] : '0' }</span>
                                                            <Form.Check htmlFor={selected.label} type="checkbox" label="" id={selected.value} name={selected.label}
                                                                        checked={selected.checked} onChange={this.onInvolvementChange}/>
                                                        </div>
                                                    </Form.Group>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Form>
                        <Form className="form-general side-filter-form">
                            <Card className="filter-card">
                                <Card.Header className="d-flex justify-content-between align-items-between">
                                    <div className="title-pane">
                                        <strong className="filter-name">
                                            Square Feet
                                        </strong>
                                    </div>
                                    <Button className="btn btn-link p-0 apply-btn" variant="" onClick={this.onFormSubmitSquareFootage} type="button">Apply</Button>
                                </Card.Header>
                            </Card>
                            <div className="states-data states-data-inputs">
                                <div className="data-pane">
                                    <Form.Group controlId="formSquareFeet">
                                        <Form.Control className="feet feet-left" placeholder="Min" name="minSquare" type="text" value={this.state.minSquare}
                                                      onChange={e => this.updateMin(e)}/>
                                        <Form.Control className="feet" placeholder="Max" name="maxSquare" type="text" value={this.state.maxSquare}
                                                      onChange={e => this.updateMax(e)}/>
                                    </Form.Group>
                                    { (this.state.minSquareError && this.state.maxSquareError)
                                        ? ""
                                        : <p className="text-danger">Please enter numeric value</p> }
                                </div>
                            </div>
                        </Form>
                        <Form className="form-general side-filter-form">
                            <Card className="filter-card">
                                <Card.Header className="d-flex justify-content-between align-items-between">
                                    <div className="title-pane">
                                        <strong className="filter-name">
                                            Keywords
                                        </strong>
                                    </div>
                                    <Button className="btn btn-link p-0 apply-btn" variant="" onClick={this.onFormSubmitKeywords} type="button">Apply</Button>
                                </Card.Header>
                            </Card>
                            <div className="states-data states-data-inputs">
                                <div className="data-pane">
                                    <Form.Group controlId="formSquareFeet">
                                        <Form.Control className="" placeholder="" type="text" value={this.state.keywords}
                                                      onChange={e => this.updateKeywords(e)}/>
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>
                        <Form className="form-general side-filter-form mb-0">
                            <Card className="filter-card">
                                <div className="states-data states-data-inputs pb-0">
                                    <div className="data-pane state-params">
                                        <Card.Header className="">
                                            <Button className="btn btn-link p-0 clear-all-btn" variant="" onClick={this.onClearAll} type="button">CLEAR ALL</Button>
                                        </Card.Header>
                                    </div>
                                </div>
                            </Card>
                        </Form>
                        {verifyLocalSelectedStates(this.state.localTotalStates) &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onClearAllSelectedStates} type="button">States x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        {this.state.localTotalStates.map((selected, index) => {
                                                            if (selected.checked) {
                                                                return (
                                                                    <li className="list-group-item bg-light d-flex justify-content-between"
                                                                        key={index}>{selected.label}
                                                                        <button className="btn badge"
                                                                                id={selected.value}
                                                                                checked={!selected.checked}
                                                                                name={selected.label}
                                                                                onClick={this.onStateChange}
                                                                                type="button"
                                                                        >x
                                                                        </button>
                                                                    </li>
                                                                );
                                                            }
                                                        })}
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                        {!_.isEmpty(this.state.completionYear) &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onYearSelectedChange} type="button">Completion Year x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item bg-light d-flex justify-content-between">
                                                            <button className="btn badge pl-0" type="button">
                                                                {this.state.completionYear.label}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                        {!_.isEmpty(this.state.showMultiSelectedSectors) &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onClearAllSelectedSectors} type="button">Sector x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        {this.state.showMultiSelectedSectors.map((selected, index) => {
                                                            return (
                                                                <li className="list-group-item bg-light d-flex justify-content-between"
                                                                    key={index}>{selected.label}
                                                                    <button className="btn badge"
                                                                            id={selected.value}
                                                                            name={selected.label}
                                                                            onClick={this.onSectorChange}
                                                                            type="button"
                                                                    >x
                                                                    </button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                        {!_.isEmpty(this.state.showMultiSelectedInvolvements) &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onClearAllSelectedInvolvements} type="button">Involvement x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        {this.state.showMultiSelectedInvolvements.map((selected, index) => {
                                                            return (
                                                                <li className="list-group-item bg-light d-flex justify-content-between"
                                                                    key={index}>{selected.label}
                                                                    <button className="btn badge"
                                                                            id={selected.value}
                                                                            name={selected.label}
                                                                            onClick={this.onInvolvementChange}
                                                                            type="button"
                                                                    >x
                                                                    </button>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                        {(this.state.minSquare || this.state.maxSquare) &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onClearSquareFeet} type="button">SquareFeet x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        {this.state.minSquare &&
                                                            <li className="list-group-item bg-light d-flex justify-content-between">
                                                                <button className="btn badge pl-0" type="button">
                                                                    {this.state.minSquare} SF
                                                                </button>
                                                            </li>
                                                        }
                                                        {this.state.maxSquare &&
                                                            <li className="list-group-item bg-light d-flex justify-content-between">
                                                                <button className="btn badge pl-0" type="button">
                                                                    {this.state.maxSquare} SF
                                                                </button>
                                                            </li>
                                                        }
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                        {this.state.keywords &&
                            <Form className="form-general side-filter-form mb-0">
                                <Card className="filter-card">
                                    <div className="states-data states-data-inputs">
                                        <div className="data-pane state-params">
                                            <Card.Header className="d-flex align-items-between">
                                                <Button className="btn btn-link p-0 clear-filter-btn" variant="" onClick={this.onClearKeywords} type="button">Keywords x</Button>
                                                <Form.Group controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item bg-light d-flex justify-content-between">
                                                            <button className="btn badge pl-0" type="button">
                                                                {this.state.keywords}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                    </div>
                                </Card>
                            </Form>}
                    </Accordion>
                </aside>}
                <div className="overlay">
                    <a className={`filter-close-link`} onClick={this.closeSideFilter}>
                        <img src={iconArrow} alt="icon" />
                    </a>
                </div>
            </>
        );
    }
});
