/* eslint-disable */
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import SaveListSection from "../SaveListModule/SaveListSection";
import iconFilterGreen from '../../../assets/images/icons/filter-btn-green.svg';
import _ from "lodash";
import {withRouter} from "react-router-dom";
import iconBack from "../../../assets/images/icons/back-btn.svg";
import backarrowGreen from "../../../assets/images/icons/back-arrow-green.svg";

export default withOktaAuth(class ListTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            sortByData: [false, false, false, false, false, false, false, false],
            isOpen:false,
        }
    }
    componentDidMount() {
        if (!_.isEmpty(this.props.projects)) {
            this.setState({projects: this.props.projects})
        }
    }
    onChangeCompletionYear = (e) => {
        let data = Object.assign([], this.state.sortByData)
        data = data.map(selection => {return selection = false })
        if (e.target.name === 'oldest'){
            if (e.target.checked){
                data[0] = true
            }
        }
        else
        {
            if (e.target.checked){
                data[1] = true
            }
        }
        this.setState({sortByData: data})
    }
    sortByCompletionYear = (projects) => {
        if(this.state.sortByData[0])
        {
            return _.orderBy(projects, ['substantial_completion_date'], ['desc']);
        }
        else if (this.state.sortByData[1]){
            return _.orderBy(projects, ['substantial_completion_date'], ['asc']);
        }
        else
        {
            return projects
        }
    }
    onChangeSquareFootage = (e) => {
        let data = Object.assign([], this.state.sortByData)
        data = data.map(selection => {return selection = false })
        if (e.target.name === 'smallest'){
            if (e.target.checked){
                data[2] = true
            }
        }
        else
        {
            if (e.target.checked){
                data[3] = true
            }
        }
        this.setState({sortByData: data})
    }
    sortBySquareFootage = (projects) => {
        if(this.state.sortByData[2])
        {
            return _.orderBy(projects, ['no_of_keys_beds_units'], ['asc']);
        }
        else if (this.state.sortByData[3]){
            return _.orderBy(projects, ['no_of_keys_beds_units'], ['desc']);
        }
        else
        {
            return projects
        }
    }
    onChangeProjectName = (e) => {
        let data = Object.assign([], this.state.sortByData)
        data = data.map(selection => {return selection = false })
        if (e.target.name === 'AToZ'){
            if (e.target.checked){
                data[4] = true
            }
        }
        else
        {
            if (e.target.checked){
                data[5] = true
            }
        }
        this.setState({sortByData: data})
    }
    sortByProjectName = (projects) => {
        if(this.state.sortByData[4])
        {
            return _.sortBy(projects, [(item)=> {return item.marketing_project_name || item.project_name}], ['asc']);
        }
        else if (this.state.sortByData[5]){
            return _.orderBy(projects, ['project_state'], ['asc']);
        }
        else
        {
            return projects
        }
    }
    onChangeNoOfUnits = (e) => {
        let data = Object.assign([], this.state.sortByData)
        data = data.map(selection => {return selection = false })
        if (e.target.name === 'smallest'){
            if (e.target.checked){
                data[6] = true
            }
        }
        else
        {
            if (e.target.checked){
                data[7] = true
            }
        }
        this.setState({sortByData: data})
    }
    sortByNoOfUnits = (projects) => {
        if(this.state.sortByData[6])
        {
            return _.orderBy(projects, ['no_of_keys_beds_units'], ['asc']);
        }
        else if (this.state.sortByData[7]){
            return _.orderBy(projects, ['no_of_keys_beds_units'], ['desc']);
        }
        else
        {
            return projects
        }
    }
    onUpdateSortBy = () => {
        let projects = this.sortByCompletionYear(this.state.projects)
        projects = this.sortBySquareFootage(projects)
        projects = this.sortByProjectName(projects)
        projects = this.sortByNoOfUnits(projects)
        this.props.updateByOrder(projects)
    }
    openSideFilter = (e) => {
        document.body.classList.add('asside-filter-open');
    }
    goBackButton = () => {
        this.props.history.push({
            pathname: '/projects/search'
        })
    }
    closeDropMenu = () => {
        document.getElementById('close-save-list').click();
    }
    render() {
        return(
            <div className="list-top  d-flex justify-content-between align-items-center">
                <div className="msg-pane d-none d-md-flex">
                    <a className="btn btn-back" onClick={this.props.onShowHideFilter}>
                        <img src={iconBack} alt="back" />
                    </a>
                    <h3>
                        {this.props.currentList.name}
                    </h3>
                    {/* <div className={`ml-4 ${this.props.isFilterOpen? "col-hide" : ""}`}> */}
                    {/*<div className={`ml-4`}>*/}
                    {/*    <a onClick={this.props.onShowHideFilter}>*/}
                    {/*        <img src={iconFilterGreen} alt="filter" />*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>

                <div className="search-title-mble d-flex d-md-none">
                    <div className="name-box">
                        <h3 className="mb-2">
                            {this.props.currentList.name}
                        </h3>
                    </div>
                    <Dropdown className="search-dropdown search-dropdown-search-list">
                        <Dropdown.Toggle  variant="save" id="close-save-list" disabled={this.props.isSaveButtonDisabled}>
                            Save to Lists
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0" >
                            <SaveListSection userDetails={this.props.userDetails}
                                             selectedProjects={this.props.selectedProjects}
                                             closeDropMenu={this.closeDropMenu}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="btn-pane d-flex align-items-center">
                    {/* ${this.state.isActive?'active':""} */}
                    <Button className={'btn d-inline-block d-md-none'} onClick={this.openSideFilter} variant="menu" >
                        <img src={iconFilterGreen} alt="icon" />
                    </Button>
                    <div className="search-name-box-mobile mr-auto d-block d-md-none">
                        <a className="btn btn-back p-0" onClick={this.props.inActiveList}>
                            <img src={backarrowGreen} alt="back" />
                        </a>
                        <h5 className="d-inline-block">
                            My Saved Searches
                        </h5>
                    </div>
                    <Dropdown className="search-dropdown d-none d-md-block">
                        <Dropdown.Toggle  variant="save" id="close-save-list" disabled={this.props.isSaveButtonDisabled}>
                            Save Selected Projects to List
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-0" >
                            <SaveListSection userDetails={this.props.userDetails} selectedProjects={this.props.selectedProjects} closeDropMenu={this.closeDropMenu}/>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Sort By Here */}

                    <Dropdown>
                        <Dropdown.Toggle className="btn-sort" variant="" id="dropdown-basic">
                            Sort by
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Form className="form-general">
                                <h3 className="list-title">
                                    Completion Year
                                </h3>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Oldest to Newest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label="" name={'oldest'} checked={this.state.sortByData[0]} onChange={this.onChangeCompletionYear}/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Newest to Oldest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label="" name={'newest'} checked={this.state.sortByData[1]} onChange={this.onChangeCompletionYear}/>
                                    </div>
                                </Form.Group>

                                <h3 className="list-title">
                                    Square Feet
                                </h3>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Smallest to Largest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label="" name={'smallest'} checked={this.state.sortByData[2]} onChange={this.onChangeSquareFootage}/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Largest to Smallest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label="" name={'largest'} checked={this.state.sortByData[3]} onChange={this.onChangeSquareFootage}/>
                                    </div>
                                </Form.Group>
                                <h3 className="list-title">
                                    Alphabetically
                                </h3>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Project Name A-Z</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label=""  name={'AToZ'} checked={this.state.sortByData[4]} onChange={this.onChangeProjectName}/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Location A-Z</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label=""  name={'ALZ'} checked={this.state.sortByData[5]} onChange={this.onChangeProjectName}/>
                                    </div>
                                </Form.Group>
                                <h3 className="list-title">
                                    Total no. of Units
                                </h3>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Smallest to Largest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label=""  name={'smallest'} checked={this.state.sortByData[6]} onChange={this.onChangeNoOfUnits}/>
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <span className="data">Largest to Smallest</span>
                                    <div className="count-check">
                                        <Form.Check type="checkbox" label="" name={'largest'} checked={this.state.sortByData[7]} onChange={this.onChangeNoOfUnits}/>
                                    </div>
                                </Form.Group>
                                <div className="btn-box">
                                    <Button type="button" onClick={this.onUpdateSortBy} className="btn btn-primary">
                                        View Results
                                    </Button>
                                </div>
                            </Form>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
});
