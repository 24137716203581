import React from 'react'
import {Form} from "react-bootstrap";
import proImage from '../../assets/images/project-image.png';
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from "lodash";
function image_path (coverUrl) {
    if(!_.isEmpty(coverUrl)) {
        return coverUrl
    }else{
        return proImage
    }
}
const show_states = (states) => {
    return(
        states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
    )
}
const ProjectCard = ({ id, projectName, projectCity, projectState, onProjectCheckBoxChange, coverUrl, currentPage, multipleStates, sector}) => {
    return (
            <Link to={{pathname: `/project/${id}`, state: {currentPage: currentPage} }} className="project-card-link">
                <div className="card project-card">
                    <div className="img-pane media">
                       <LazyLoadImage
                           className="card-img-top"
                           alt="Project Image"
                           effect="blur"
                           src={image_path(coverUrl)} />
                    </div>
                    <div className="card-body">
                        <h4 className="card-text project-sector">
                            {projectName}
                        </h4>
                        <small>{sector}</small>
                        <div className="check-label-pane d-flex justify-content-between">
                            { projectCity && projectCity.includes('Multiple') ?
                                <small>{show_states(multipleStates)}</small>
                                :
                                <small>{projectCity}, {projectState}</small>
                            }
                            <Form.Group className="mb-0" controlId={`gridCheck_${id}`}>
                                <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Link>
    );
}
export default ProjectCard;
