import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import _, {differenceBy} from "lodash";
import rpa from "../../apis/rpa";
import {Col, Form} from "react-bootstrap";
import {toast} from "react-toastify";
import close from '../../assets/images/icons/close-circle.svg';
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "../Projects/AppSpinner";
import MultiSelect from "react-multi-select-component";
import InputGroup from "react-bootstrap/InputGroup";
import saveIcon from "../../assets/images/icons/save-icon.svg";
import Card from 'react-bootstrap/Card';
import {userFullName} from "../Actions/ListActions"

export default (function UsersBehalfList({show, handleBehalfListClose, UpdateSaveListUsers, userDetails, listId, listToken, listName, listUsers, creatorId}) {
    const [users, setUsers] = useState([]);
    const [currentListUsers, setCurrentListUsers] = useState([]);

    useEffect(() => {
        if(_.isEmpty(users)){
            fetchUsers()
        }
    }, []);

    const verifyListUserUpdate = () => {
        let preUserIds = differenceBy(listUsers.filter(u => u.uid !== userDetails.sub), currentListUsers, 'id').map(u => u.id)
        let updatedList = [...currentListUsers]
        let creator = listUsers.filter(user => user.label===userDetails.preferred_username.split('@')[0])
        updatedList.push(creator[0])
        return updatedList
    }

    const verifyUserExistence = (user) => {
        return _.isEmpty(listUsers.filter(usr => usr.id === user.id))
    }

    const fetchUsers = () => {
        trackPromise(rpa.get('/users', {
            params: {
                behalfList: true,
            },
        }).then(res => {
            let usersData = res.data.users.flatMap(user => user.uid !== userDetails.sub ?
                {label: userFullName(user), value: userFullName(user), id: user.id, uid: user.uid, checked: !verifyUserExistence(user)} : []
            )
            usersData = _.orderBy(usersData, ['email'], ['asc']);
            setCurrentListUsers(listUsers.filter(user => user.uid !== userDetails.sub))
            setUsers(usersData);
        }).catch(err => {}), 'share-link-spinner');
    }

    const saveUsersToList = (updatedUsers) => {
        if (!Array.isArray(updatedUsers)) updatedUsers = [...currentListUsers]
        updatedUsers.push(fetchCurrentUser())
        trackPromise(rpa.patch(`/users/${userDetails.sub}/save_lists/${listId}`, {
            save_list: {
                users: updatedUsers
            },
        }).then(res => {
            UpdateSaveListUsers(res.data)
            let updatedListUsers = res.data.users?.filter(user => user.uid !== userDetails.sub)
                                                  .map(user => {return {label: userFullName(user),
                                                      value: userFullName(user), id: user.id,
                                                      uid: user.uid, checked: true}})
            setCurrentListUsers(updatedListUsers)
            toast.success(`${res.data.name} Updated Successfully`)
        }).catch(err => {}), 'share-link-spinner');
    }

   const onRemoveUser = (e) => {
        if (!_.isEmpty(e.currentTarget.id)){
            let updatedUsers = currentListUsers.filter(user => user.id !== parseInt(e.currentTarget.id))
            setCurrentListUsers(updatedUsers)
            saveUsersToList(updatedUsers)
        }
   }

    const onSelectUsers = (e) => {
        e = e.map(user => {user.checked = true; return user})
        setCurrentListUsers(e)
    }

    const fetchCurrentUser = () => {
        return listUsers.find(user => user.uid === userDetails.sub)
    }

    const filterOptions =(options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }
    return (
        <div className="App">
            <Modal show={show} className="delete-modal" onHide={handleBehalfListClose}>
                <Modal.Header>
                    <Modal.Title>
                        Collaborator List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppSpinner customClass="delete-loader" area="share-link-spinner"/>
                    <div className="msg-box">
                        <div className="close-box d-flex behalf-list-close">
                            <p className="project-message mb-0">
                                {listName && listName}
                            </p>
                            <Button variant="close">
                                <img src={close} alt="" onClick={handleBehalfListClose}/>
                            </Button>
                        </div>
                        <section className="behalf-filter-pane mx-0 px-0">
                            <div className="filter-pane bg-light-behalf">
                                <div className="container">
                                    <Form.Row className="align-items-center">
                                        <Form.Group controlId="formGridState" as={Col}  md="12" lg="12">
                                            <InputGroup className="">
                                                <MultiSelect
                                                    options={users}
                                                    value={currentListUsers}
                                                    onChange={(e) => onSelectUsers(e)}
                                                    labelledBy={"Select"}
                                                    // f8f9fa
                                                    hasSelectAll={false}
                                                    filterOptions={filterOptions}
                                                    overrideStrings={{
                                                        "search": "Type to search",
                                                        "selectSomeItems": "Select User"
                                                    }}
                                                    disableSearch={false}
                                                    // isOpen={true}
                                                />
                                                <InputGroup.Text
                                                    role="button"
                                                    className="pointer"
                                                >
                                                    <img src={saveIcon} alt="save" onClick={saveUsersToList}/>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            </div>
                        </section>
                        <section className="behalf-filter-pane mt-2 bg-white">
                            <div className="selected-list-container">
                                <Card className="">
                                        <div className="user-params">
                                            <Card.Header className="d-flex">
                                                <Form.Group  className="pl-2" controlId="formSquareFeet">
                                                    <ul className="list-group list-group-flush">
                                                        {currentListUsers.map((selected, index) => {
                                                            if (selected.checked) {
                                                                return (
                                                                    <li className={`list-group-item bg-light d-flex justify-content-between ${creatorId === selected.id ? "disabled" : "" }`}
                                                                        key={index}>{selected.label}
                                                                        <button className="btn badge"
                                                                                id={selected.id}
                                                                                checked={!selected.checked}
                                                                                name={selected.label}
                                                                                onClick={creatorId === selected.id ? '' : onRemoveUser }
                                                                                type="button"
                                                                        >x
                                                                        </button>
                                                                    </li>
                                                                );
                                                            }
                                                        })}
                                                    </ul>
                                                </Form.Group>
                                            </Card.Header>
                                        </div>
                                </Card>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    );
});
