import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import add from '../../assets/images/icons/plus-circle.svg';
import _ from "lodash";
import { withRouter } from "react-router-dom";
import iconBack from "../../assets/images/icons/back-btn.svg";

export default withOktaAuth(
  withRouter(
    class ListTop extends Component {
      constructor(props) {
        super(props);
        this.state = {
          projects: [],
          sortByData: this.props.sortPaginationData,
          isOpen: false,
        };
      }
      componentDidMount() {
        if (!_.isEmpty(this.props.projects)) {
          this.setState({ projects: this.props.projects });
        }
      }
      componentDidUpdate(prevProps, prevState, snapshot) {
        let prevProjects = prevProps.projects
        let currentProjects = this.props.projects
        if(!this.props.listPage){
            if(prevProjects.length > 0 && currentProjects.length > 0){
                let oldProjects = currentProjects.filter(prj => {
                    if(prevProjects.filter(p => prj.id == p.id)){
                        return prj
                    }
                })
                if(oldProjects.length == 0){
                    this.setState({sortByData: this.props.sortPaginationData}, ()=> this.onUpdateSortBy())
                    document.getElementById('dropdown-basic').click();
                }
            }
        }
      }
      // onChangeCompletionYear = (e) => {
      //   let data = Object.assign([], this.state.sortByData);
      //   data = data.map((selection) => {
      //     return (selection = false);
      //   });
      //   if (e.target.name === "oldest") {
      //     if (e.target.checked) {
      //       data[0] = true;
      //     }
      //   } else {
      //     if (e.target.checked) {
      //       data[1] = true;
      //     }
      //   }
      //   this.setState({ sortByData: data });
      // };
      // sortByCompletionYear = (projects) => {
      //   if (this.state.sortByData[0]) {
      //     return _.orderBy(projects, ["substantial_completion_date"], ["desc"]);
      //   } else if (this.state.sortByData[1]) {
      //     return _.orderBy(projects, ["substantial_completion_date"], ["asc"]);
      //   } else {
      //     return projects;
      //   }
      // };
      // onChangeSquareFootage = (e) => {
      //   let data = Object.assign([], this.state.sortByData);
      //   data = data.map((selection) => {
      //     return (selection = false);
      //   });
      //   if (e.target.name === "smallest") {
      //     if (e.target.checked) {
      //       data[2] = true;
      //     }
      //   } else {
      //     if (e.target.checked) {
      //       data[3] = true;
      //     }
      //   }
      //   this.setState({ sortByData: data });
      // };
      // sortBySquareFootage = (projects) => {
      //   if (this.state.sortByData[2]) {
      //     return _.orderBy(projects, ["square_footage_number"], ["asc"]);
      //   } else if (this.state.sortByData[3]) {
      //     return _.orderBy(projects, ["square_footage_number"], ["desc"]);
      //   } else {
      //     return projects;
      //   }
      // };
      // onChangeProjectName = (e) => {
      //   let data = Object.assign([], this.state.sortByData);
      //   data = data.map((selection) => {
      //     return (selection = false);
      //   });
      //   if (e.target.name === "AToZ") {
      //     if (e.target.checked) {
      //       data[4] = true;
      //     }
      //   } else {
      //     if (e.target.checked) {
      //       data[5] = true;
      //     }
      //   }
      //   this.setState({ sortByData: data });
      // };
      // sortByProjectName = (projects) => {
      //   if (this.state.sortByData[4]) {
      //     return _.orderBy(projects, [(item)=> {return item.marketing_project_name || item.project_name}], ["asc"]);
      //   } else if (this.state.sortByData[5]) {
      //     return _.orderBy(projects, ["project_state"], ["asc"]);
      //   } else {
      //     return projects;
      //   }
      // };
      // onChangeNoOfUnits = (e) => {
      //   let data = Object.assign([], this.state.sortByData);
      //   data = data.map((selection) => {
      //     return (selection = false);
      //   });
      //   if (e.target.name === "smallest") {
      //     if (e.target.checked) {
      //       data[6] = true;
      //     }
      //   } else {
      //     if (e.target.checked) {
      //       data[7] = true;
      //     }
      //   }
      //   this.setState({ sortByData: data });
      // };
      // sortByNoOfUnits = (projects) => {
      //   if (this.state.sortByData[6]) {
      //     return _.orderBy(projects, ["no_of_keys_beds_units"], ["asc"]);
      //   } else if (this.state.sortByData[7]) {
      //     return _.orderBy(projects, ["no_of_keys_beds_units"], ["desc"]);
      //   } else {
      //     return projects;
      //   }
      // };
      onUpdateSortBy = () => {
        // let projects = this.sortByCompletionYear(this.props.projects);
        // projects = this.sortBySquareFootage(projects);
        // projects = this.sortByProjectName(projects);
        // projects = this.sortByNoOfUnits(projects);
        this.props.updateProjectsBySavedListOrder()
        document.getElementById('dropdown-basic').click();
      };
      goBackButton = () => {
        this.props.history.push({
          pathname: this.props.listPage ? "/projects/search" : "/projects/save_list"
        });
      };
      onFormSaveListSubmit = (e) => {
        this.props.addSaveLists();
        this.inputText.value = "";
      }
      render() {
        return (
            <div className={`list-top ${this.props.listPage? "saved-list-main-page" : "saved-list-detail-page"} d-flex justify-content-between align-items-center`}>
            <div className="msg-pane">
              <a className="btn btn-back" onClick={this.goBackButton}>
                <img src={iconBack} alt="back" />
              </a>
              <h3>
                 {this.props.listPage ? "My Saved Lists" : this.props.list.name}
              </h3>
            </div>

            {/* <div className="search-title-mble d-flex d-md-none">
              <div className="name-box">
                <h3 className="mb-2">
                  List Name
                </h3>
              </div>
            </div> */}
            {this.props.listPage
                ? <div className="input-box">
                    {/* <AppSpinner customClass="add-search-list-loader" area="save-list-side"/> */}
                    <Form className="p-0">
                        <Form.Group className="mb-0" controlId="">
                          <InputGroup>
                              <Form.Control type="text" placeholder="Create new list"
                                            onChange={this.props.onchangeListName}
                                            ref={el => this.inputText = el} name="listName"/>
                              <InputGroup.Append>
                                <InputGroup.Text role="button" className="pointer" onClick={this.onFormSaveListSubmit}>
                                  <img src={add} alt=""/>
                                </InputGroup.Text>
                              </InputGroup.Append>
                              {/* <Button variant="btn-add" type="button" onClick={this.props.onFormSaveListSubmit}>
                                  <img src={add} alt=""/>
                              </Button> */}
                          </InputGroup>
                        </Form.Group>
                    </Form>
                </div>
                :
                <div className="btn-pane d-flex align-items-center">
                  {/* <div className="search-name-box-mobile mr-auto d-block d-md-none">
                    <a
                        className="btn btn-back p-0"
                        onClick={this.goBackButton}
                    >
                      <img src={backarrowGreen} alt="back"/>
                    </a>
                    <h5 className="d-inline-block">My Saved List</h5>
                  </div> */}
                  <span className="create-date">
                    Created: {this.props.list.list_created_at}
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle
                        className="btn-sort"
                        variant=""
                        id="dropdown-basic"
                    >
                      Sort by
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Form className="form-general">
                        <h3 className="list-title">Completion Year</h3>
                        <Form.Group controlId="completionYearOldest">
                          <span className="data">Oldest to Newest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"oldest"}
                                checked={this.props.sortPaginationData["completionYearOldest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="completionYearOldest"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="completionYearNewest">
                          <span className="data">Newest to Oldest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"newest"}
                                checked={this.props.sortPaginationData["completionYearNewest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="completionYearNewest"
                            />
                          </div>
                        </Form.Group>

                        <h3 className="list-title">Square Feet</h3>
                        <Form.Group controlId="squareFeetSmallest">
                          <span className="data">Smallest to Largest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"smallest"}
                                checked={this.props.sortPaginationData["squareFeetSmallest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="squareFeetSmallest"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="squareFeetLargest">
                          <span className="data">Largest to Smallest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"largest"}
                                checked={this.props.sortPaginationData["squareFeetLargest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="squareFeetLargest"
                            />
                          </div>
                        </Form.Group>
                        <h3 className="list-title">Alphabetically</h3>
                        <Form.Group controlId="AToZ">
                          <span className="data">Project Name A-Z</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"AToZ"}
                                checked={this.props.sortPaginationData["AToZ"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="AToZ"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="ALZ">
                          <span className="data">Location A-Z</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"ALZ"}
                                checked={this.props.sortPaginationData["ALZ"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="ALZ"
                            />
                          </div>
                        </Form.Group>
                        <h3 className="list-title">Total no. of Units</h3>
                        <Form.Group controlId="unitsSmallest">
                          <span className="data">Smallest to Largest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"smallest"}
                                checked={this.props.sortPaginationData["unitsSmallest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="unitsSmallest"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="unitsLargest">
                          <span className="data">Largest to Smallest</span>
                          <div className="count-check">
                            <Form.Check
                                type="checkbox"
                                label=""
                                name={"largest"}
                                checked={this.props.sortPaginationData["unitsLargest"]}
                                onChange={(e)=> this.props.onChangeSortBy(e)}
                                aria-label="unitsLargest"
                            />
                          </div>
                        </Form.Group>
                        <div className="btn-box">
                          <Button
                              type="button"
                              onClick={this.onUpdateSortBy}
                              className="btn btn-primary"
                          >
                            View Results
                          </Button>
                        </div>
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            }
          </div>
        );
      }
    }
  )
);
