/* eslint-disable */
import React from 'react'
import Select from 'react-select';
import {withRouter} from 'react-router-dom';
import {Button, Form, Col} from "react-bootstrap";
import rpa from "../../apis/rpa";
import MultiSelect from "react-multi-select-component";
import '../../assets/stylesheets/projectsearch.css'
import iconSearch from '../../assets/images/icons/search-go.svg';
import iconRight from '../../assets/images/icons/right-arrow-dark.svg';
import _ from "lodash";
import {trackPromise} from "react-promise-tracker";
import MenuAppSpinner from "./MenuAppSpinner";
import SearchParams from "../CustomHooks/SearchParams";
import {ryanInvolvement} from "../shared/FilterConstant"
import {rangCompletionYear} from "../shared/FilterConstant"

export default withRouter(class ProjectSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedStates: [],
            SelectedSector: [],
            keywords:'',
            minSquare: '',
            maxSquare: '',
            CompletionYear: '',
            sectorValues: [],
            ryanValues: [],
            abbreviation: [],
            involvement: [],
            result: [],
            isDisable: true,
            success: undefined,
            isSubmit: false,
            err: undefined,
            isActive:false,
            minSquareError: true,
            maxSquareError: true,
            states: [],
            sectors: []
        }
    }
    componentDidMount() {
        this.searchData()
        SearchParams.setValue('listCurrentPage', "")
    }
    searchData = () => {
        trackPromise(rpa.get('/get_search_data', {
        })
            .then(res => {
                var states = _.orderBy(res.data.states, ['label'], ['asc'])
                var sectors = _.orderBy(res.data.project_type_name, ['label'], ['asc'])
                this.setState({states: states, sectors: sectors, isDisable: false})
            })
            .catch(err => {
                this.setState({err: err, success: false})
            }), 'search-data-section');
    }
    onFormSubmit = e => {
        e.preventDefault();
        if (this.state.maxSquareError && this.state.minSquareError) {
            this.setState({isSubmit: true}, () => this.renderRedirect())
        }
        SearchParams.setValue('sortKey', "")
        SearchParams.setValue('sortByData', "")
    };
    renderRedirect = () => {
        let  resetYear = rangCompletionYear
        if(_.isEmpty(this.state.CompletionYear)) { resetYear = resetYear.map(year => { year.checked = false; return year})}
        // let selectedStates = Object.assign([], this.state.SelectedStates);
        let totalStates = Object.assign([], this.state.states);
        // let totalStates = this.state.states.filter(state => {
        //     if (!selectedStates.some(o => o.label === state.label)){
        //         state.checked = false
        //         return state
        //     }
        // })
        let SelectedSector = Object.assign([], this.state.SelectedSector);
        let totalSectors = this.state.sectors.filter(sector => {
            if (!SelectedSector.some(o => o.label === sector.label)){
                sector.checked = false
                return sector
            }
        })
        let selectedInvolvement = Object.assign([], this.state.involvement);
        let totalInvolvement = ryanInvolvement.filter(inv => {
            if (!selectedInvolvement.some(o => o.label === inv.label)){
                inv.checked = false
                return inv
            }
        })
        // if (!_.isEmpty(this.state.SelectedStates) || !_.isEmpty(this.state.sector) || !_.isEmpty(this.state.involvement)) {
            return (
                this.props.history.push({
                pathname: '/projects/search', search: '?',
                data: {states: this.state.abbreviation, sector: this.state.sectorValues, involvement: this.state.ryanValues,
                       selectedStates: this.state.SelectedStates, completionYear: this.state.CompletionYear,
                       minSquare: this.state.minSquare, maxSquare: this.state.maxSquare, keywords: this.state.keywords,
                       SelectedSector: this.state.SelectedSector, totalStates: totalStates, totalSectors: totalSectors, totalInvolvement: totalInvolvement,
                       selectedInvolvement: this.state.involvement, rangCompletionYear: resetYear }
            })
            );
        // }
    };

    customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.length
            : "Select";
    };
    updateSelectedStates = (e) => {
        e = e.map(state => {state.checked = true; return state})
        let abbreviation = e.map(state => {
            return state.value
        })
        this.setState({SelectedStates: e, abbreviation: abbreviation, isSubmit: false})
    }
    updateInvolvement = (e) => {
        let values = e.map(inv => {return inv.value})
        e = e.map(sector => {sector.checked = true; return sector})
        this.setState({involvement: e, ryanValues: values, isSubmit: false})
    }
    updateCompletionYear = (e) => {
        // this.setState({CompletionYear: e.target.value})
        e.checked = true
        this.setState({CompletionYear: e})
    }
    updateSelectedSector = (e) => {
        e = e.map(sector => {sector.checked = true; return sector})
        let values = e.map(sector => {
            return sector.value
        })
        this.setState({SelectedSector: e, sectorValues: values, isSubmit: false})
    }
    updateKeywords = (e) => {
        this.setState({keywords: e.target.value})
    }
    updateMin = (e) => {
        this.setState({minSquare: e.target.value}, () => this.isNumber(e))
    }
    updateMax = (e) => {
        this.setState({maxSquare: e.target.value}, () => this.isNumber(e))
    }
    showAdvanced = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {isActive} = this.state;
        this.setState({isActive:!isActive})
    }
    filterOptions =(options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        // let sortedData = options.filter(({ value }) => value && value.match(re));
        // return _.orderBy(sortedData, ['label'], ['asc'])
        return options.filter(({ value }) => value && value.match(re));
    }
    isNumber = (e) => {
        const reg = /^[0-9\b]+$/;
        _.isEmpty(e.target.value) ?
        this.setState({[e.target.name+"Error"]: true})
        : this.setState({[e.target.name+"Error"]: reg.test(e.target.value)})
    }
    render() {
        const {
            isClearable,
            isDisabled,
            isLoading,
        } = this.state;
        return (
            <main className="project-search-page">
                {/*{this.state.isSubmit && <Redirect to={{pathname: '/projects/search', data: { states: this.state.abbreviation, sector: this.state.sector }}}/>}*/}
                <section className="section-hero mx-0 d-block d-lg-none">
                    <h1 className="web-title">
                        Creating spaces that bring your story to life.
                    </h1>
                    <p className="slogon">
                        EVERY PROJECT HAS A STORY BEYOND THE STRUCTURE
                    </p>
                </section>
                <section className="section-hero section-search mx-0 px-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <h1 className="web-title">
                                    Creating spaces that bring your story to life.
                                </h1>
                            </div>
                            <div className="col-md-12 col-lg-10 z-1">
                                <div className="filter-pane bg-light">
                                    <div className="container">
                                        <Form onSubmit={this.onFormSubmit}>
                                            <Form.Row className="align-items-center">
                                                <Form.Group as={Col}  md="12" lg="3" className="pl-5p"
                                                            controlId="formGridState">
                                                    <Form.Label>State</Form.Label>
                                                    <MultiSelect
                                                        options={this.state.states}
                                                        value={this.state.SelectedStates}
                                                        onChange={(e) => this.updateSelectedStates(e)}
                                                        labelledBy={"Select"}
                                                        hasSelectAll={false}
                                                        placeholder={"type"}
                                                        filterOptions={this.filterOptions}
                                                        // isOpen={true}
                                                        overrideStrings={{"search": "Type to filter","selectSomeItems": "Select"}}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="12" lg="3"
                                                            controlId="formYear">
                                                    <Form.Label>Completion Year</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isDisabled={isDisabled}
                                                        isLoading={isLoading}
                                                        isClearable={isClearable}
                                                        isSearchable={false}
                                                        options={rangCompletionYear}
                                                        value={this.state.CompletionYear}
                                                        onChange={this.updateCompletionYear}
                                                        // menuIsOpen={true}
                                                        placeholder={"Select"}
                                                    />

                                                </Form.Group>
                                                <Form.Group as={Col} md="12" lg="3"
                                                            controlId="formSector">
                                                    <Form.Label>Sector</Form.Label>
                                                    <MultiSelect
                                                        options={this.state.sectors}
                                                        value={this.state.SelectedSector}
                                                        onChange={(e) => this.updateSelectedSector(e)}
                                                        labelledBy={"Select"}
                                                        hasSelectAll={false}
                                                        filterOptions={this.filterOptions}
                                                        overrideStrings={{"search": "Type to filter","selectSomeItems": "Select"}}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}  md="12" lg="3" controlId="formGridSector" className="border-0 advance-col">
                                                    <Button className="btn p-0" variant="" type="submit" disabled={this.state.isDisable ? true : false}>
                                                        <img src={iconSearch} alt="icon" />
                                                        <MenuAppSpinner className="search-spinner" area="search-data-section"/>
                                                    </Button>{' '}
                                                    <a className={`expand-link ${this.state.isActive?'active':""}`} onClick={this.showAdvanced}>
                                                        {this.state.isActive?'Less Search':"Advanced Search"}
                                                        <span className="icon ml-1">
                                                            <img src={iconRight} className="" alt="arrow"/>
                                                        </span>
                                                    </a>
                                                </Form.Group>

                                                <div className={`advance-pane ${this.state.isActive?'active':""}`}>
                                                    <Form.Group as={Col} lg="9" className="border-col d-none d-lg-block">
                                                    </Form.Group>

                                                        <Form.Row className="mx-0">

                                                            <Form.Group as={Col}  md="12" lg="3" className="pl-5p"
                                                                        controlId="formGridInvolvement">
                                                                <Form.Label>Ryan Involvement</Form.Label>
                                                                <MultiSelect
                                                                    options={ryanInvolvement}
                                                                    value={this.state.involvement}
                                                                    onChange={(e) => this.updateInvolvement(e)}
                                                                    labelledBy={"Select"}
                                                                    hasSelectAll={false}
                                                                    // isOpen={true}
                                                                    filterOptions={this.filterOptions}
                                                                    overrideStrings={{"search": "Type to filter","selectSomeItems": "Select"}}

                                                                />
                                                            </Form.Group>

                                                                <Form.Group className="input-pane" as={Col} md="12" lg="3" controlId="formSquareFeet">
                                                                    <Form.Label className="d-block">Square Feet</Form.Label>
                                                                    <Form.Control className="feet feet-left" placeholder="Min" type="text" name="minSquare" onChange={e => this.updateMin(e)}/>
                                                                    <Form.Control className="feet" placeholder="Max" type="text" name="maxSquare" onChange={e => this.updateMax(e)}/>
                                                                    <p className="text-danger">{(this.state.minSquareError && this.state.maxSquareError)
                                                                    ? "" : "Please enter numeric value." }</p>
                                                                </Form.Group>
                                                            <Form.Group className="input-pane input-pane-last pr-lg-0" as={Col}  md="12" lg="3" controlId="formSquareFeet">
                                                                <Form.Label>Keywords</Form.Label>
                                                                <Form.Control className="keyinput" type="text" onChange={e => this.updateKeywords(e)}/>
                                                            </Form.Group>
                                                        </Form.Row>
                                                </div>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
})


