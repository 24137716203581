import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import SaveListSection from "./SaveListModule/SaveListSection";
import iconFilterGreen from '../../assets/images/icons/filter-btn-green.svg';

export default withOktaAuth(class ListTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortByData: this.props.sortPaginationData,
            isOpen:false
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // let prevProjects = prevProps.projects
        // let currentProjects = this.props.projects
        // if(prevProjects.length > 0 && currentProjects.length > 0){
        //     let oldProjects = currentProjects.filter(prj => {
        //         if(prevProjects.includes(prj)){
        //             return prj
        //         }
        //     })
        //     if(oldProjects.length == 0){
        //         this.setState({sortByData: this.props.sortPaginationData}, ()=> this.onUpdateSortBy())
        //         document.getElementById('dropdown-basic').click();
        //     }
        // }
    }
    //
    // onChangeCompletionYear = (e) => {
    //     let data = Object.assign([], this.state.sortByData)
    //     data = data.map(selection => {return selection = false })
    //     if (e.target.name === 'oldest'){
    //         if (e.target.checked){
    //             data[0] = true
    //         }
    //     }
    //     else
    //     {
    //         if (e.target.checked){
    //             data[1] = true
    //         }
    //     }
    //     this.setState({sortByData: data})
    // }
    // sortByCompletionYear = (projects) => {
    //     if(this.state.sortByData[0])
    //     {
    //         return _.orderBy(projects, ['substantial_completion_date'], ['desc']);
    //     }
    //     else if (this.state.sortByData[1]){
    //         return _.orderBy(projects, ['substantial_completion_date'], ['asc']);
    //     }
    //     else
    //     {
    //         return projects
    //     }
    // }
    // onChangeSquareFootage = (e) => {
    //     let data = Object.assign([], this.state.sortByData)
    //     data = data.map(selection => {return selection = false })
    //     if (e.target.name === 'smallest'){
    //         if (e.target.checked){
    //             data[2] = true
    //         }
    //     }
    //     else
    //     {
    //         if (e.target.checked){
    //             data[3] = true
    //         }
    //     }
    //     this.setState({sortByData: data})
    // }
    // sortBySquareFootage = (projects) => {
    //     if(this.state.sortByData[2])
    //     {
    //         return _.orderBy(projects, ['no_of_keys_beds_units'], ['asc']);
    //     }
    //     else if (this.state.sortByData[3]){
    //         return _.orderBy(projects, ['no_of_keys_beds_units'], ['desc']);
    //     }
    //     else
    //     {
    //         return projects
    //     }
    // }
    // onChangeProjectName = (e) => {
    //     let data = Object.assign([], this.state.sortByData)
    //     data = data.map(selection => {return selection = false })
    //     if (e.target.name === 'AToZ'){
    //         if (e.target.checked){
    //             data[4] = true
    //         }
    //     }
    //     else
    //     {
    //         if (e.target.checked){
    //             data[5] = true
    //         }
    //     }
    //     this.setState({sortByData: data})
    // }
    // sortByProjectName = (projects) => {
    //     if(this.state.sortByData[4])
    //     {
    //         return _.orderBy(projects, [(item)=> {return item.marketing_project_name || item.project_name}], ['asc']);
    //     }
    //     else if (this.state.sortByData[5]){
    //         return _.orderBy(projects, ['project_state'], ['asc']);
    //     }
    //     else
    //     {
    //         return projects
    //     }
    // }
    // onChangeNoOfUnits = (e) => {
    //     let data = Object.assign([], this.state.sortByData)
    //     data = data.map(selection => {return selection = false })
    //     if (e.target.name === 'smallest'){
    //         if (e.target.checked){
    //             data[6] = true
    //         }
    //     }
    //     else
    //     {
    //         if (e.target.checked){
    //             data[7] = true
    //         }
    //     }
    //     this.setState({sortByData: data})
    // }
    // sortByNoOfUnits = (projects) => {
    //     if(this.state.sortByData[6])
    //     {
    //         return _.orderBy(projects, ['no_of_keys_beds_units'], ['asc']);
    //     }
    //     else if (this.state.sortByData[7]){
    //         return _.orderBy(projects, ['no_of_keys_beds_units'], ['desc']);
    //     }
    //     else
    //     {
    //         return projects
    //     }
    // }
    onUpdateSortBy = () => {
        // let projects = this.sortByCompletionYear(this.props.projects)
        // projects = this.sortBySquareFootage(projects)
        // projects = this.sortByProjectName(projects)
        // projects = this.sortByNoOfUnits(projects)
        this.props.updateProjectsByOrder()
        document.getElementById('dropdown-basic').click();
    }
    onSortClick = (e) => {
        this.props.onChangeSortBy(e)
    }
    openSideFilter = (e) => {
        document.body.classList.add('asside-filter-open');
    }
    closeDropMenu = () => {
        document.getElementById('close-save-list').click();
    }
    render() {
        return(
            <div className="list-top d-flex justify-content-between align-items-center">
                <div className="msg-pane">
                    <p className="result-msg">
                    {this.props.found} listings found as of applied filters
                    </p>
                </div>

                <div className="btn-pane d-flex align-items-center flex-grow-1">
                {/* ${this.state.isActive?'active':""} */}
                    <Button className={'btn d-inline-block d-md-none'} onClick={this.openSideFilter} variant="menu" >
                        <img src={iconFilterGreen} alt="icon" />
                    </Button>
                    {/* <Button className="btn" variant="save">
                        Save Selected Projects to List
                    </Button> */}
                    <Dropdown className="search-dropdown save-list-dropdown">
                        <Dropdown.Toggle  className="save-list-icon" variant="save" id="close-save-list" disabled={this.props.isSaveButtonDisabled}>
                            Save Selected Projects to List
                            {/*<img src={bookMark} alt="SaveToList"/>*/}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-0 align-menu-items">
                            <SaveListSection closeDropMenu={this.closeDropMenu} userDetails={this.props.userDetails} selectedProjects={this.props.selectedProjects}/>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="sort-dropdown mr-2" onSelect={this.props.onChangeProjectListView} >
                        <Dropdown.Toggle className="btn-sort" variant="" id="dropdown-basic">
                            {this.props.projectViewList ? 'List View' : 'Grid View'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sort-dropdown-menu grid-list-view">
                            <Dropdown.Item eventKey="1">Grid View</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="2">List View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="sort-dropdown">
                        <Dropdown.Toggle className="btn-sort" variant="" id="dropdown-basic">
                            Sort by
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="sort-dropdown-menu">
                            <Form className="form-general">
                                <h3 className="list-title">
                                    Completion Year
                                </h3>
                                <Form.Group controlId="completionYearOldest">
                                            <span className="data">Oldest to Newest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="completionYearOldest" label="" name={'oldest'} checked={this.props.sortPaginationData["completionYearOldest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <Form.Group controlId="completionYearNewest">
                                            <span className="data">Newest to Oldest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="completionYearNewest" label="" name={'newest'} checked={this.props.sortPaginationData["completionYearNewest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>

                                <h3 className="list-title">
                                    Square Feet
                                </h3>
                                <Form.Group controlId="squareFeetSmallest">
                                            <span className="data">Smallest to Largest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="squareFeetSmallest" label="" name={'smallest'} checked={this.props.sortPaginationData["squareFeetSmallest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <Form.Group controlId="squareFeetLargest">
                                            <span className="data">Largest to Smallest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="squareFeetLargest" label="" name={'largest'} checked={this.props.sortPaginationData["squareFeetLargest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <h3 className="list-title">
                                    Alphabetically
                                </h3>
                                <Form.Group controlId="AToZ">
                                            <span className="data">Project Name A-Z</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="AToZ" label=""  name={'AToZ'} checked={this.props.sortPaginationData["AToZ"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <Form.Group controlId="ALZ">
                                            <span className="data">Location A-Z</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="ALZ" label=""  name={'ALZ'} checked={this.props.sortPaginationData["ALZ"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <h3 className="list-title">
                                    Total no. of Units
                                </h3>
                                <Form.Group controlId="unitsSmallest">
                                            <span className="data">Smallest to Largest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="unitsSmallest" label=""  name={'smallest'} checked={this.props.sortPaginationData["unitsSmallest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <Form.Group controlId="unitsLargest">
                                            <span className="data">Largest to Smallest</span>
                                            <div className="count-check">
                                                <Form.Check type="checkbox" aria-label="unitsLargest" label="" name={'largest'} checked={this.props.sortPaginationData["unitsLargest"]} onChange={this.onSortClick}/>
                                            </div>
                                </Form.Group>
                                <div className="btn-box">
                                    <Button type="button" onClick={this.onUpdateSortBy} className="btn btn-primary">
                                        View Results
                                    </Button>
                                </div>
                            </Form>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    }
});
