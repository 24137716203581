import React, { Component } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Badge from '../../assets/images/logo-main.svg';
import logo from "../../assets/images/logo-main.svg";

export default (class SharedFooter extends Component {

    render() {
        return (
            <header className="shared-footer">
                <div className="container">
                    <div className="footer-content">
                        <p>
                            {`©2014-${new Date().getFullYear()} All Rights Reserved. ® Ryan Companies and/or its subsidiaries or affiliates are copyrights`}
                        </p>
                    </div>
                </div>
            </header>
        // <footer className="inner-footer">
        //     <div className="footer-primary internal-footer">
        //         <div className="container">
        //             <div className="row justify-content-between align-items-center">
        //                 <div className="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
        //                     <a className="footer-logo mr-3">
        //                         <img className="img-fluid" src={logo}/>
        //                     </a>
        //                     <p className="m-0">
        //                         ©2014-${new Date().getFullYear()} All Rights Reserved. ® Ryan Companies and/or its subsidiaries or affiliates are copyrights
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </footer>
        );
    }
});
