import React, {Component} from "react";
import {OktaAuth} from '@okta/okta-auth-js';
import {withOktaAuth} from '@okta/okta-react';
import '../../assets/stylesheets/LoginForm.css';
import rpa from "../../apis/rpa";
import Hero from "../shared/Hero";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import email from '../../assets/images/icons/email-icon.svg';
import key from '../../assets/images/icons/key-icon.svg';
import eye from '../../assets/images/icons/eye-on.svg';
import eyeOff from '../../assets/images/icons/eye-off.svg';
import {Button} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "../Projects/AppSpinner";
import {withRouter} from "react-router-dom";
import {UserParams} from "../CustomHooks/UserDetails";
import _ from "lodash";
export default withOktaAuth(withRouter(class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionToken: null,
            username: '',
            password: '',
            user: '',
            isChecked: false,
            isSubmitting: false,
            EmailError: '',
            PasswordError: '',
            type: 'password',
        };
        this.oktaAuth = new OktaAuth({
            issuer: props.issuer
        });
    }
    componentDidMount() {
        sessionStorage.setItem('okta-pkce-storage', '{}')
        let [checkbox, userName, password]   = [UserParams.getItem('checkbox'), UserParams.getItem('username'), UserParams.getItem('password')]
        if (checkbox && userName !== '') {
            this.setState({isChecked: true, username: userName, password: password})
        }
        this.oktaAuth.session.exists()
            .then((exists) => {
                if (exists && !this.props.authState.isAuthenticated && !this.props.authState.isPending) {
                    this.oktaAuth.token.getWithoutPrompt({
                        responseType: ['token', 'id_token'],
                        clientId: '0oa1k5qecqsgQZ4em0h8',
                        scopes: ['openid', 'profile', 'email'],
                        redirectUri: 'https://ryanprojectapp.com/login/callback',
                    })
                        .then((res) => {
                            this.oktaAuth.handleLoginRedirect(res.tokens)
                        }).catch(err => {
                            this.props.history.push('/login');
                            console.log('inactivity error handle loginRedirect', err);
                        }
                    );
                }
            }).catch((err) => {
            this.props.history.push('/login');
            console.log('inactivity error handle sessionExist', err);
        });
    }
    showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'text' ? 'password' : 'text'
        });
    }

    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }

    createUser = (userData) => {
        console.log("userData", userData)
            rpa.post('/users', {
                LoginServerResponse: {
                    res: userData
                },
            user: {
                uid: userData.user.id,
                email: userData.user.profile.login,
                first_name: userData.user.profile.firstName,
                last_name: userData.user.profile.lastName,
                division: (!_.isEmpty(userData.division) ? userData.division : ''),
                department: (!_.isEmpty(userData.department) ? userData.department : '')
            }
        }).then(rr => {
            console.log("serverResponse", rr.data)

        }).catch(err => console.log('user request error', err))

    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.isChecked === true) {
            UserParams.setItem('username', this.state.username)
            UserParams.setItem('password', this.state.password)
            UserParams.setItem('checkbox', this.state.isChecked)
        }
        this.setState({isSubmitting: true});
        // let token ={
        //     clientId: "0oa2qcn1hGhjH5KXV5d6",
        //     idToken: "eyJraWQiOiJhdHBPVkZ4Y0tGbTdDWUpmWXRFd3RKYXl5c05ieC1FZExhdlVSQUtUaFJ3IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxZTA4M2NvSWJWSDQ5MDVkNiIsIm5hbWUiOiJBZGlsIFphaGlkIiwibG9jYWxlIjoiZW4tVVMiLCJlbWFpbCI6ImFkaWwubXVoYW1tYWRAbnhiLmNvbS5wayIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9kZXYtMjAxOTMyNi5va3RhLmNvbSIsImF1ZCI6IjBvYTJxY24xaEdoakg1S1hWNWQ2IiwiaWF0IjoxNjE0MDAzODY4LCJleHAiOjE2MTQwMDc0NjgsImp0aSI6IklELmFhUDFXMjNBOHlkejgtVkFac3NwaVJhUFFyM1FxU0hNTnBRZ2lpaE9lbjQiLCJhbXIiOlsicHdkIl0sImlkcCI6IjAwbzFlMDd6a3U5aFlaUkFSNWQ2IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWRpbC5tdWhhbW1hZEBueGIuY29tLnBrIiwiZ2l2ZW5fbmFtZSI6IkFkaWwiLCJmYW1pbHlfbmFtZSI6IlphaGlkIiwiem9uZWluZm8iOiJBbWVyaWNhL0xvc19BbmdlbGVzIiwidXBkYXRlZF9hdCI6MTYxMjUwMzMxMCwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF1dGhfdGltZSI6MTYxNDAwMDcyNCwiYWRtaW4iOnRydWUsInVzZXJEaXZpc2lvbiI6IkhlYWQgb2YgRGVwYXJ0bWVudCJ9.ZJPmp3qa_siwMMpPq_PqcPchiiIwUi_1b4Hv_K2lJEdff04QKnDusbHoZdaLSmvylMeNxaYSTbCdmRr2rfJc0lN4qySJ23HY4ECiRmIBMqDKUO8V8ZS68iUGZg39hJtJJpCwlx1t92_djfDnQjPZTP05P44tU_OA3EkGfPVUyKkdRIJygyCDVtc3J0lVsA_LP3-rWtezY-TkIWwfs3oEPaz6GYUCLeqAQDNt1hjwqXSasRp9sHausUxIOlzbPorWWORup5oMSObjBHHS6v7GlurhA_TnvCUy5ca8GyjQAfpkjQcMhOwDTENJ8HJpDyclInkJnt25FuruA2NKYMJLSA",
        //     expiresAt: 1614007468,
        //     scopes: (2) ["openid", "email"],
        //     issuer: "https://dev-2019326.okta.com/oauth2/default"
        // }
        // "eyJraWQiOiJhdHBPVkZ4Y0tGbTdDWUpmWXRFd3RKYXl5c05ieC1FZExhdlVSQUtUaFJ3IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxZTA4M2NvSWJWSDQ5MDVkNiIsIm5hbWUiOiJBZGlsIFphaGlkIiwibG9jYWxlIjoiZW4tVVMiLCJlbWFpbCI6ImFkaWwubXVoYW1tYWRAbnhiLmNvbS5wayIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9kZXYtMjAxOTMyNi5va3RhLmNvbSIsImF1ZCI6IjBvYTJxY24xaEdoakg1S1hWNWQ2IiwiaWF0IjoxNjE0MDA1Mzk2LCJleHAiOjE2MTQwMDg5OTYsImp0aSI6IklELk0zS05vVzlPQThkUlJOOFZnMkJMcXc1Y2lhRFVhczgyUnczTHVHbnJmOFUiLCJhbXIiOlsicHdkIl0sImlkcCI6IjAwbzFlMDd6a3U5aFlaUkFSNWQ2IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWRpbC5tdWhhbW1hZEBueGIuY29tLnBrIiwiZ2l2ZW5fbmFtZSI6IkFkaWwiLCJmYW1pbHlfbmFtZSI6IlphaGlkIiwiem9uZWluZm8iOiJBbWVyaWNhL0xvc19BbmdlbGVzIiwidXBkYXRlZF9hdCI6MTYxMjUwMzMxMCwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF1dGhfdGltZSI6MTYxNDAwNTM4OSwiYWRtaW4iOnRydWUsInVzZXJEaXZpc2lvbiI6IkhlYWQgb2YgRGVwYXJ0bWVudCJ9.LgoWuxs0Cimbna7XD2Sro0SDHnk9lW82KtuB1bDIfC3eyucDRFXGWIvPFkoiguydI4LAl0DWgCwfXyLvBqkBphdpvgsbA8NGWl9KIVS6mmbEstilFK-UEZ45r92ff5v4ZdkVYSPxgJHDqSwGj48WpyVqUvcpPQdlREd7-BZ1Zt2wmPcpcJGOGzGh3gTAXtNIcH4foHp32lwXJvPilkSCy_YH7VAixxTabIcz03Vg3P7gkK-jsjzZV6eDk3FSE_wTBdbTZCJ705WDXImSv3FD4tHCYAumpJ2P3HHNhvo24UGa1qnuCpIUGSMNWhTcOhac-S6-B9fKZRJOVq7iY7lBGg"
        // this.oktaAuth.session.exists()
        //     .then((exists) => {
        //         debugger
        //         this.oktaAuth.token.getWithoutPrompt({
        //             responseType: ['token', 'id_token'],
        //             clientId: '0oa2qcn1hGhjH5KXV5d6',
        //             scopes: ['openid', 'profile', 'email'],
        //             redirectUri: 'http://localhost:3000/login/callback',
        //         })
        //             .then((res) => {
        //                 debugger
        //                 this.oktaAuth.handleLoginRedirect(res.tokens);
        //             });
        //     });
        // this.oktaAuth.token.getWithoutPrompt({
        //     responseType: 'id_token', // or array of types
        //     clientId: '0oa2qcn1hGhjH5KXV5d6',
        //     redirectUri: 'http://localhost:3000/login/callback',
        //     pkce: true
        // })
        //     .then(function(res) {
        //         let tokens = res.tokens;
        //         debugger
        //         this.oktaAuth.tokenManager.setTokens(tokens);
        // // tokens.forEach(token => {
        // //     if (token.idToken) {
        // //         this.oktaAuth.tokenManager.add('idToken', token);
        // //     }
        // //     if (token.accessToken) {
        // //         this.oktaAuth.tokenManager.add('accessToken', token);
        // //     }
        // // });
        //     })
        //     .catch(function(err) {
        //         debugger
        //     });
            trackPromise(
            this.oktaAuth.signIn({
                username: this.state.username,
                password: this.state.password
            })
                .then(res => {
                    const sessionToken = res.sessionToken;
                    // sessionService.saveSession(res)
                    this.setState(
                        {sessionToken},
                        () => {
                            this.createUser(res);
                            this.props.authService.redirect({sessionToken});
                        }
                    );
                })
                .catch(err => {
                    toast.error(err.message);
                    // console.log('login error', err.message);
                    console.log('Invalid Credentials');
                    this.setState({isSubmitting: false});
                }), 'login-request');
    }
    handleUsernameChange = (e) => {
        this.setState({username: e.target.value})
        if (e.target.value.length > 0) {
            e.target.labels[0].classList.add("floatingBootstrap");
        } else {
            e.target.labels[0].classList.remove("floatingBootstrap");
        }
    }
    handlePasswordChange = (e) => {
        this.setState({password: e.target.value})
        if (e.target.value.length > 0) {
            e.target.labels[0].classList.add("floatingBootstrap");
        } else {
            e.target.labels[0].classList.remove("floatingBootstrap");
        }
    }
    render() {
        console.log('props', this.props.authState)
        return (
            <section className="login-section">
                {this.state.isSubmitting ? <AppSpinner area="login-request"/> :
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-7 pl-lg-0">
                            <Hero/>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div className="form-pane-login">
                                <h5 className="form-name text-right">Login</h5>
                                <div className="container p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                                            <Form className="form-general">
                                                <Form.Group>
                                                    <InputGroup className="mb-2">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>
                                                                <img alt="icon" src={email}/>
                                                            </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl id="email" placeholder="Username" required
                                                                     onChange={this.handleUsernameChange} value={this.state.username}/>
                                                        <Form.Label htmlFor="email">
                                                            Username
                                                        </Form.Label>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group>

                                                    <InputGroup className="mb-2">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>
                                                                <img alt="icon" src={key}/>
                                                            </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl id="password" type={this.state.type}
                                                                     placeholder="password" required
                                                                     onChange={this.handlePasswordChange} value={this.state.password}/>
                                                        <Form.Label htmlFor="password">
                                                            Password
                                                        </Form.Label>
                                                        <a className="visible">
                                                            {this.state.type=='password'? <img src={eye} alt="" onClick={this.showHide}/> : <img src={eyeOff} alt="" onClick={this.showHide}/>
                                                            }
                                                        </a>
                                                    </InputGroup>
                                                </Form.Group>
                                                <div className="login-footer d-flex align-items-center">
                                                    <Form.Group className="mb-0">
                                                        <div className="form-check">
                                                            <input className="styled-checkbox mr-2" id="remember"
                                                                   type="checkbox" checked={this.state.isChecked} onChange={this.onChangeCheckbox}/>
                                                            <label htmlFor="remember">Remember Me</label>
                                                        </div>
                                                    </Form.Group>
                                                    <Button className="ml-auto btn btn-primary" type="button" onClick={this.handleSubmit}>
                                                            Login
                                                    </Button>
                                                </div>
                                                <div className="btn-pane text-right">
                                                        {/*<a className="btn btn-forget p-0">*/}
                                                        {/*    <span className="icon mr-1">*/}
                                                        {/*        <img src={alert} alt="" />*/}
                                                        {/*    </span>*/}
                                                        {/*    Forgot Password?*/}
                                                        {/*</a>*/}
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>
        );
    }
}));
