import React, {Component} from 'react'
import _ from 'lodash'
import ProjectCard from "./ProjectCard";
import '../../assets/stylesheets/projectlist.css'
import rpa from "../../apis/rpa";
import {withRouter} from "react-router-dom";
import {Form} from "react-bootstrap";
import {layoutViews} from "../shared/FilterConstant";
import Filters from "./Filter";
import ListTop from "./ListTop";
import SearchParams from "../CustomHooks/SearchParams";
import {sessionService} from "redux-react-session";
import {trackPromise} from "react-promise-tracker";
import ReactPaginate from 'react-paginate';
import AppSpinner from "./AppSpinner";
import ListView from "./ListView";

export default withRouter(class ProjectList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            extractedData: {},
            projects: [],
            pageCount: null,
            currentPage: 1,
            selectedProjects: [],
            projectsCount: '',
            projectByStates: [],
            projectBySectors: [],
            projectByInvolvement:[],
            load: undefined,
            userDetails: null,
            response: undefined,
            err: undefined,
            projectListView: false,
            isSaveButtonDisabled: true,
            sortByData: {completionYearOldest: false, completionYearNewest: false, squareFeetSmallest: false, squareFeetLargest: false, AToZ: false, ALZ: false, unitsSmallest: false, unitsLargest: false},
            sortKey: undefined
        }
    }
    componentDidMount() {
        this.setUserDetails()
        if(!_.isEmpty(this.props.location.state) && this.props.location.state.currentPage > 0){
            this.setState({currentPage: this.props.location.state.currentPage})
        }
        if (!_.isEmpty(this.props.location.data)) {
            if (_.isEmpty(this.state.filters)) {
                SearchParams.setValue('filters', JSON.stringify(this.props.location.data))
                this.setState({filters: this.props.location.data}, () => this.getProjects())
            } else {
                this.getProjects()
            }
        } else {
            this.getSearchParams()
        }
    }
    updateFilters = (payload) => {
        SearchParams.setValue('filters', JSON.stringify(payload))
        this.setState({filters: payload, currentPage: 1}, () => this.getProjects())
    }
    getExtractedData = () => {
        let data = Object.assign({}, this.state.filters)
        if (_.isEmpty(data["selectedStates"])){
            data["selectedStates"] = []
            data["states"] = []
            data["totalStates"] = data["totalStates"].map(state => { state.checked = false; return state })
        }
        this.setState({extractedData: data})
    }

    getSearchParams = () => {
        let filters = SearchParams.getValue('filters')
        let sortBy = SearchParams.getValue('sortKey')
        let sortByData = SearchParams.getValue('sortByData')
        if (!_.isEmpty(filters)) {
            this.setState({filters: filters, sortKey: sortBy}, () => this.getProjects())
        }
        if(!_.isEmpty(sortByData)){
            this.setState({sortByData: sortByData})
        }
    }
    setUserDetails = () => {
        if(_.isEmpty(this.userDetails)){
            sessionService.loadSession().then(response => {
                this.setState({userDetails: response})
            }).catch(err => {
                console.log('Load Session Not Found ProjectList!')
            })
        }
    }
    handlePageClick = (event) => {
        console.log("page clicked",event)
        this.setState({currentPage: event.selected + 1}, () => this.getProjects())
        this.setState({isSaveButtonDisabled: true})
    }
    getProjects = () => {
        this.getExtractedData()
        let currentPage = this.state.currentPage
        trackPromise(rpa.get(`/projects?year=${this.state.filters["completionYear"]["value"]}&page=${currentPage}`, {
            params: {
                states: this.state.filters["states"],
                sector: this.state.filters["sector"],
                involvement: this.state.filters["involvement"],
                minSquare: this.state.filters["minSquare"],
                maxSquare: this.state.filters["maxSquare"],
                keywords: this.state.filters["keywords"],
                sortBy: this.state.sortKey
            }
        })
            .then(res => {
                this.setState({projects: res.data.projects, projectByStates: res.data.project_by_state, projectBySectors: res.data.project_by_sector, projectByInvolvement: res.data.project_by_involvement, load: true, pageCount: res.data.page_count, currentPage: res.data.page, projectsCount: res.data.projects_count})
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'project-list-loader');
    }
    updateProjectsByOrder = () => {
        SearchParams.setValue('sortKey', JSON.stringify(this.state.sortKey))
        SearchParams.setValue('sortByData', JSON.stringify(this.state.sortByData))
        this.setState({filters: this.state.filters}, () => this.getProjects())
    }
    onProjectCheckBoxChange = (e) => {
        let selectedProjects = Object.assign([], this.state.selectedProjects)
        if (e.target.checked){
            selectedProjects.push(e.target.id)
        }
        else
        {
            selectedProjects = selectedProjects.filter(id => id !== e.target.id)
        }
        this.setState({selectedProjects: selectedProjects})
        this.disbaleSaveProjectButton();
    }
    disbaleSaveProjectButton = () => {
        let checked = document.querySelectorAll(".projectCheckbox input[type='checkbox']:checked").length
        if(checked > 0){
            this.setState({isSaveButtonDisabled: false})
        }else{
            this.setState({isSaveButtonDisabled: true})
        }
    }
    onChangeSortBy = (e) => {
        let sortName = e.target.ariaLabel
        let sortValue = e.target.checked
        let sortData = this.state.sortByData
        let sortKey = {}
        Object.keys(sortData).forEach(function(key) {
            sortData[key] = false
        });
       if(sortValue){
           sortData[sortName] = true
           sortKey = this.setSortKey(sortName)
       }
        this.setState({sortByData: sortData, sortKey: sortKey})
    }
    onChangeProjectListView = (k, e) => {
        switch (layoutViews[k]){
            case 'listView':
                return this.setState({projectListView: true})
            case 'gridView':
                return this.setState({projectListView: false})
            default:
        }
    }
    setSortKey = (sortName) => {
        switch (sortName){
            case 'completionYearOldest':
                return {'substantial_completion_date': 'asc'}
            case 'completionYearNewest':
                return {'substantial_completion_date': 'desc'}
            case 'squareFeetSmallest':
                return {'square_footage_number': 'asc'}
            case 'squareFeetLargest':
                return {'square_footage_number': 'desc'}
            case 'AToZ':
                return {'marketing_project_name': 'asc', 'project_name': 'asc'}
            case 'ALZ':
                return {'project_state': 'asc'}
            case 'unitsSmallest':
                return {'no_of_keys_beds_units': 'asc'}
            case 'unitsLargest':
                return {'no_of_keys_beds_units': 'desc'}
            default:
                return {}
        }
    }
    render() {
        let load = this.state.load;

        return (
            <main className="project-list-page">
                <AppSpinner area="project-list-loader" customClass="projects-loader" />
                {load &&
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <ListTop found={this.state.projectsCount} projects={this.state.projects}
                                 updateProjectsByOrder={this.updateProjectsByOrder} userDetails={this.state.userDetails}
                                 selectedProjects={this.state.selectedProjects}
                                 isSaveButtonDisabled={this.state.isSaveButtonDisabled}
                                 sortPaginationData={this.state.sortByData} onChangeSortBy={this.onChangeSortBy}
                                 onChangeProjectListView={this.onChangeProjectListView}
                                 projectViewList={this.state.projectListView}/>
                    </div>
                        <div className="col-12 col-md-4 col-lg-3">
                            <Filters projects={this.state.projects} filters={this.state.filters} extractedData={this.state.extractedData}
                                     updateFilters={this.updateFilters} projectByStates={this.state.projectByStates} projectBySectors={this.state.projectBySectors}
                                     projectByInvolvement={this.state.projectByInvolvement} userDetails={this.state.userDetails}
                            />
                        </div>
                        <div className="col-12 col-md-8 col-lg-9">
                            <div className="project-list-pane">
                                {this.state.load &&

                                    <Form>
                                        <Form.Row className="align-items-center list-view-details">
                                            {this.state.projects.length > 0 ? this.state.projects.map(project => {
                                                return (
                                                    this.state.projectListView ?
                                                    <div className="col-12" key={project.id}>
                                                        <ListView
                                                            id={project.id}
                                                            onProjectCheckBoxChange = {this.onProjectCheckBoxChange}
                                                            projectName={_.isEmpty(project.marketing_project_name) ? project.project_name : project.marketing_project_name}
                                                            substantialCompletionDate={project.substantial_completion_date}
                                                            sector={project.project_type_name}
                                                            projectTypeName={project.project_type_name}
                                                            projectCity={project.project_city}
                                                            projectState={project.project_state}
                                                            images={project.images}
                                                            coverUrl={project.cover_url}
                                                            currentPage={this.state.currentPage}
                                                            multipleStates = {project.states}
                                                        />
                                                    </div> :
                                                    <div className="col-12 col-md-6 col-lg-4" key={project.id}>
                                                        <ProjectCard
                                                            id={project.id}
                                                            onProjectCheckBoxChange = {this.onProjectCheckBoxChange}
                                                            projectName={_.isEmpty(project.marketing_project_name) ? project.project_name : project.marketing_project_name}
                                                            substantialCompletionDate={project.substantial_completion_date}
                                                            sector={project.project_type_name}
                                                            projectTypeName={project.project_type_name}
                                                            projectCity={project.project_city}
                                                            projectState={project.project_state}
                                                            images={project.images}
                                                            coverUrl={project.cover_url}
                                                            currentPage={this.state.currentPage}
                                                            multipleStates = {project.states}
                                                        />
                                                    </div>
                                                )
                                            })
                                                : <div className="col-12 empty-msg">
                                                    <p>No Project Found !</p>
                                                </div>}
                                        </Form.Row>
                                    </Form>

                                }
                            </div>
                            <div className="pagination-box">
                                <ReactPaginate className="pagination" id="react-paginate"
                                    // labels
                                               previousLabel={''}
                                               nextLabel={''}
                                               breakLabel={'…'}
                                    // classNames
                                               containerClassName={'react-paginate'}
                                               previousClassName={'previous'}
                                               breakClassName={'break-me'}
                                               nextClassName={'next'}
                                               pageClassName={'page'}
                                               activeClassName={'active'}
                                               pageLinkClassName={'page-link'}
                                               disabledClassName={'disabled'}
                                    // functionality
                                               pageCount={this.state.pageCount}
                                               marginPagesDisplayed={1}
                                               pageRangeDisplayed={2}
                                               onPageChange={this.handlePageClick}
                                               disableInitialCallback={true}
                                               forcePage={this.state.currentPage - 1}
                                />
                                <span className="current-page">
                                    Page {this.state.currentPage} of {this.state.pageCount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </main>
        );
    }
})
