/* eslint-disable */
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Form, Dropdown} from "react-bootstrap";
import iconFilter from '../../../assets/images/icons/filter-icon.svg'
import iconSave from '../../../assets/images/icons/icon-save.svg'
import iconUp from '../../../assets/images/icons/down-arrow.svg'
import iconArrow from '../../../assets/images/icons/arrow-white.svg'
import MultiSelect from "react-multi-select-component";
import _ from "lodash";
import {toast, ToastContainer} from "react-toastify";
import {ryanInvolvement} from "../../shared/FilterConstant"
import {rangCompletionYear} from "../../shared/FilterConstant"
import SearchEditBar from "./SearchEditBar";
import rpa from "../../../apis/rpa";
import {
    clearFilters,
    onFilterChangeUpdateSelectedInvolvement,
    onFilterChangeUpdateSelectedSectors,
    onFilterClearAll,
    onFilterClearAllSelectedInvolvements,
    onFilterClearAllSelectedSectors,
    onFilterInvolvementChange,
    onFilterSectorChange,
    onFilterStateChange,
} from "../../Actions/FilterActions";
import SelectedParameters from "./SelectedParameters";

export default withOktaAuth(class SaveListFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentList: {},
            filters: {},
            localTotalStates: [],
            localAbrStates:[],
            multiSelectedStates: [],
            showMultiSelectedStates: [],
            localTotalSectors: [],
            localAbrSectors:[],
            multiSelectedSectors: [],
            showMultiSelectedSectors: [],
            localTotalInvolvements: ryanInvolvement,
            localAbrInvolvements:[],
            multiSelectedInvolvements: [],
            showMultiSelectedInvolvements: [],
            completionYear: '',
            rangCompletionYear: rangCompletionYear,
            keywords: '',
            maxSquare: '',
            minSquare: '',
            isActive: false,
            activeClasses: [false, false, false, false],
        }
        this.myState = React.createRef();
        this.myStateAccordion = React.createRef();
        this.myYear = React.createRef();
        this.myYearAccordion = React.createRef();
        this.mySector = React.createRef();
        this.mySectorAccordion = React.createRef();
        this.myInvovlement = React.createRef();
        this.myInvovlementAccordion = React.createRef();
        this.showMoreStates = this.showMoreStates.bind(this);
    }
    componentDidUpdate(prevProps,prevState) {
        if (this.props.filters.id !== prevProps.filters.id) {
            let updateStates = this.state.totalStates.map(currentState => {
                currentState.checked = false;
                return currentState
            })
            let updateSectors = this.state.totalSectors.map(currentSector => {
                currentSector.checked = false;
                return currentSector
            })
            this.setState({localTotalStates: updateStates, localTotalSectors: updateSectors},
                () => this.currentListToFilters(this.props.filters))
        }
    }
    componentDidMount() {
        this.searchData()
    }
    currentListToFilters = (filters) => {
        this.setState({filters: filters})
        if (!_.isEmpty(filters.states)){
            let localTotalStates = Object.assign([], this.state.localTotalStates);
            localTotalStates = localTotalStates.map(localState => {
                if (filters.states.includes(localState.value)){
                    localState.checked = true
                }
                return localState
            })
            let selectedStates = localTotalStates.filter(state => state.checked)
            selectedStates = _.orderBy(selectedStates, ['label'], ['asc']);
            localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
            let localAbrStates = selectedStates.map(state => { return state.value})
            this.setState({
                multiSelectedStates: selectedStates,
                localAbrStates: localAbrStates,
                localTotalStates: localTotalStates
            })
        }
        else{
            this.setState({multiSelectedStates: [], localAbrStates: []})
        }

        if (!_.isEmpty(filters.sectors)){
            let multiSelectedSectors = this.state.localTotalSectors.filter(sector => {
                if(filters.sectors.includes(sector.value)){
                    sector.checked = true
                    return sector
                }
            })
            let localTotalSectors = Object.assign([], this.state.localTotalSectors);
            localTotalSectors = localTotalSectors.filter(sector => {
                if (!multiSelectedSectors.some(o => o.label === sector.label)){
                    sector.checked = false
                    return sector
                }
            })
            multiSelectedSectors = _.orderBy(multiSelectedSectors, ['label'], ['asc']);
            localTotalSectors = _.orderBy(localTotalSectors, ['label'], ['asc']);
            let localAbrSectors = multiSelectedSectors.map(sector => { return sector.value})
            this.setState({
                showMultiSelectedSectors: multiSelectedSectors,
                multiSelectedSectors: multiSelectedSectors,
                localAbrSectors: localAbrSectors,
                localTotalSectors: localTotalSectors
            })
        }
        else{
            this.setState({multiSelectedSectors: [], localAbrSectors: [], showMultiSelectedSectors: []})
        }

        if (!_.isEmpty(filters.involvement)){
            let multiSelectedInvolvements = this.state.localTotalInvolvements.filter(inv => {
                if(filters.involvement.includes(inv.value)){
                    inv.checked = true
                    return inv
                }
            })
            let localTotalInvolvements = Object.assign([], this.state.localTotalInvolvements);
            localTotalInvolvements = localTotalInvolvements.filter(inv => {
                if (!multiSelectedInvolvements.some(o => o.label === inv.label)){
                    inv.checked = false
                    return inv
                }
            })
            multiSelectedInvolvements = _.orderBy(multiSelectedInvolvements, ['label'], ['asc']);
            localTotalInvolvements = _.orderBy(localTotalInvolvements, ['label'], ['asc']);
            let localAbrInvolvements = multiSelectedInvolvements.map(inv => { return inv.value})
            this.setState({
                showMultiSelectedInvolvements: multiSelectedInvolvements,
                multiSelectedInvolvements: multiSelectedInvolvements,
                localAbrInvolvements: localAbrInvolvements,
                localTotalInvolvements: localTotalInvolvements
            })
        }
        else{
            this.setState({
                multiSelectedInvolvements: [], localAbrInvolvements: [], showMultiSelectedInvolvements: [],
                localTotalInvolvements: ryanInvolvement.map(inv => {
                    inv.checked = false;
                    return inv
                })
            })
        }

        if (!_.isEmpty(filters.year)){
            let rangCompletionYear = this.state.rangCompletionYear.map(year => {
                if(filters.year === year.value){
                    year.checked = true
                }
                return year
            })
            let completionYear = rangCompletionYear.filter(year => {
                if(filters.year === year.value){
                    return year.value
                }
            })
            this.setState({rangCompletionYear: rangCompletionYear, completionYear: completionYear[0]})
        }
        else{
            this.setState({
                rangCompletionYear: rangCompletionYear.map(range => {
                    range.checked = false;
                    return range
                }), completionYear: ''
            })
        }

        if (!_.isEmpty(filters.minSquare) || !_.isEmpty(filters.maxSquare)){
            let minSquare = ''
            let maxSquare = ''
            minSquare = !_.isEmpty(filters.minSquare)? filters.minSquare : ''
            maxSquare = !_.isEmpty(filters.maxSquare)? filters.maxSquare : ''
            this.setState({minSquare: minSquare, maxSquare: maxSquare})
        }
        else{
            this.setState({maxSquare: '', minSquare: ''})
        }

        if (!_.isEmpty(filters.keywords)){
            this.setState({keywords: filters.keywords})
        }
        else{
            this.setState({keywords: ''})
        }
    }
    fetchUpdatedDataFilters = (event, action) => {
        let filters =  Object.assign({}, this.state.filters);
        switch (action.type) {
            case "StateChange": {
                let updatedData = onFilterStateChange(this.state, event)
                filters.states = updatedData.localAbrStates
                updatedData["filters"] = filters
                return updatedData
            }
            case "SectorChange": {
                let updatedData = onFilterSectorChange(this.state, event)
                filters.sectors = updatedData.localAbrSectors
                updatedData["filters"] = filters
                return updatedData;
            }
            case "InvolvementChange": {
                let updatedData = onFilterInvolvementChange(this.state, event)
                filters.involvement = updatedData.localAbrInvolvements
                updatedData["filters"] = filters
                return updatedData;
            }
            default:
        }
    }
    onStateChange = (e) => {
        let data = this.fetchUpdatedDataFilters(e, {type: 'StateChange'});
        this.setState(data, () => this.props.getProjects(data.filters))
    }
    onChangeUpdateSelectedSectors = (e) => {
        let updatedData = onFilterChangeUpdateSelectedSectors(this.state, e)
        let filters =  Object.assign({}, this.state.filters);
        filters.sectors = updatedData.localAbrSectors
        updatedData["filters"] = filters
        this.setState(updatedData, () => this.props.getProjects(filters))
    }
    onSectorChange = (e) => {
        let data = this.fetchUpdatedDataFilters(e, {type: 'SectorChange'});
        this.setState(data, () => this.props.getProjects(data.filters))
    }
    onChangeUpdateSelectedInvolvement = (e) => {
        let updatedData = onFilterChangeUpdateSelectedInvolvement(this.state, e)
        let filters =  Object.assign({}, this.state.filters);
        filters.involvement = updatedData.localAbrInvolvements
        updatedData["filters"] = filters
        this.setState(updatedData, () => this.props.getProjects(filters))
    }
    onInvolvementChange = (e) => {
        let data = this.fetchUpdatedDataFilters(e, {type: 'InvolvementChange'});
        this.setState(data, () => this.props.getProjects(data.filters))
    }
    filterOptions =(options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }
    updateButtonOnApply = () => {
        this.setState(prevState => {
            let activeClasses = Object.assign([], prevState.activeClasses);
            activeClasses = activeClasses.map((currElement) => {
                currElement =  false
                return currElement;
            });
            return {activeClasses};
        })
    }
    closeSideFilter = (e) => {
        document.body.classList.remove('asside-filter-open');
    }
    showMoreStates = index => {
        this.setState(prevState => {
            let activeClasses = Object.assign([], prevState.activeClasses);
            if (activeClasses[index]===true){
                activeClasses = activeClasses.map((currElement) => {
                    currElement =  false
                    return currElement;
                });
            }
            else {
                activeClasses = activeClasses.map((currElement) => {
                    currElement =  false
                    return currElement;
                });
                activeClasses[index] = true
            }
            return {activeClasses};
        })
    }
    onYearListChange = (e) => {
        let rangCompletionYear = Object.assign([], this.state.rangCompletionYear);
        let completionYear = Object.assign([], this.state.completionYear);
        let filters =  Object.assign({}, this.state.filters);
        if(e.target.checked === true){
            rangCompletionYear = rangCompletionYear.map(obj => {
                if(e.target.id === obj.label){
                    obj.checked = e.target.checked
                    completionYear = obj
                }
                else{
                    obj.checked = false
                }
                return obj;
            })
        }
        else if(e.target.checked === false){
            rangCompletionYear = rangCompletionYear.map(obj => {
                obj.checked = false
                return obj;
            })
            completionYear = ''
        }
        filters.year = completionYear.value
        this.setState({
            rangCompletionYear: rangCompletionYear,
            completionYear: completionYear,
            filters: filters
        }, () => this.props.getProjects(filters))
    }
    onYearSelectedChange = (e) => {
        let filters =  Object.assign({}, this.state.filters);
        let completionYear = Object.assign([], this.state.completionYear);
        let rangCompletionYear = Object.assign([], this.state.rangCompletionYear);
        rangCompletionYear = rangCompletionYear.map(obj => {
            obj.checked = false
            return obj;
        })
        completionYear = ''
        filters.year = ''
        this.setState({
            rangCompletionYear: rangCompletionYear,
            completionYear: completionYear,
            filters: filters
        }, () => this.props.getProjects(filters))
    }
    updateKeywords = (e) => {
        this.setState({keywords: e.target.value})
    }
    updateMin = (e) => {
        this.setState({minSquare: e.target.value})
    }
    updateMax = (e) => {
        this.setState({maxSquare: e.target.value})
    }
    onFormSubmitSquareFootage = () => {
        let filters =  Object.assign({}, this.state.filters);
        filters.minSquare = this.state.minSquare
        filters.maxSquare = this.state.maxSquare
        this.setState({filters: filters}, () => this.props.getProjects(filters))
    }
    onFormSubmitKeywords = () => {
        let filters =  Object.assign({}, this.state.filters);
        filters.keywords = this.state.keywords
        this.setState({filters: filters}, () => this.props.getProjects(filters))
    }
    closeDropdown = () => {
        document.getElementById('save-search-dropdown').click();
    }
    onClearAllSelectedStates = () => {
        let localTotalStates =  Object.assign([], this.state.localTotalStates);
        localTotalStates = localTotalStates.map(localState => {localState.checked = false; return localState})
        let filters =  Object.assign({}, this.state.filters);
        filters.states =  []
        localTotalStates = _.orderBy(localTotalStates, ['label'], ['asc']);
        let localAbrStates = localTotalStates.filter(localState => localState.checked)
        this.setState({
            showMultiSelectedStates: [],
            multiSelectedStates: [],
            localAbrStates: localAbrStates,
            localTotalStates: localTotalStates
        }, () => this.props.getProjects(filters))
    }
    onClearAll = () => {
        let updatedData = onFilterClearAll(this.state)
        this.myYear.current.parentElement.classList.remove('show')
        this.myYearAccordion.current.classList.add('hide-filter')
        let filters =  Object.assign({}, this.state.filters);
        filters = clearFilters(filters)
        this.setState(updatedData, () => this.props.getProjects(filters))
    }
    onClearAllSelectedSectors = () => {
        let updatedData = onFilterClearAllSelectedSectors(this.state)
        let filters =  Object.assign({}, this.state.filters);
        filters.sectors =  []
        updatedData["filters"] = filters
        this.setState(updatedData, () => this.props.getProjects(filters))
    }
    onClearAllSelectedInvolvements = () => {
        let updatedData = onFilterClearAllSelectedInvolvements(this.state)
        let filters =  Object.assign({}, this.state.filters);
        filters.involvement =  []
        updatedData["filters"] = filters
        this.setState(updatedData, () => this.props.getProjects(filters))
    }
    onClearSquareFeet = () => {
        let filters =  Object.assign({}, this.state.filters);
        filters.maxSquare = ''
        filters.minSquare =  ''
        this.setState({filters: filters, maxSquare: '', minSquare: ''}, () => this.props.getProjects(filters))
    }
    onClearKeywords = () => {
        let filters =  Object.assign({}, this.state.filters);
        filters.keywords = ''
        this.setState({keywords: ''}, () => this.props.getProjects(filters))
    }
    searchData = () => {
        rpa.get('/get_search_data', {})
            .then(res => {
                let states = _.orderBy(res.data.states, ['label'], ['asc'])
                let sectors = _.orderBy(res.data.project_type_name, ['label'], ['asc'])
                this.setState({
                    totalStates: states,
                    localTotalStates: states,
                    localTotalSectors: sectors,
                    totalSectors: sectors
                }, () => {
                    if (!_.isEmpty(this.props.filters)) {
                        this.currentListToFilters(this.props.filters)
                    }
                })
            })
            .catch(err => {
                toast.error(err);
            });
    }
    render() {
        const activeClasses = this.state.activeClasses.slice();
        return (
            <>
                <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {!this.state.isActive &&
                <aside className={'filters-pane'}>
                    <div className={`${this.props.isFilterOpen? "filter-heading" : "col-hide"}`}>
                        <span className="icon-pane">
                            <img src={iconFilter} alt="icon" />
                        </span>
                        <Dropdown className="search-dropdown">
                            <Dropdown.Toggle variant="" id="save-search-dropdown">
                                Save My Search
                                <img src={iconSave} alt="icon"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <SearchEditBar userDetails={this.props.userDetails} filters={this.state.filters}
                                               closeDropdown={this.closeDropdown}/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <Accordion>
                        <div className={`${this.props.isFilterOpen? "" : "col-hide"}`}>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="">

                                        <div
                                            className={`filter-actions ${activeClasses[0] ? "filter-actions" : "hide-filter"}`}
                                            onClick={() => this.showMoreStates(0)} ref={this.myStateAccordion}>

                                            <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center"
                                                              variant="link" eventKey="0">
                                                <strong className="filter-name">
                                                    States
                                                </strong>
                                                <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                            </Accordion.Toggle>
                                        </div>

                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body ref={this.myState} className="states-data">
                                            {/*<Form>*/}
                                            {/*    <Form.Group controlId="formStateFilter">*/}
                                            {/*        <MultiSelect*/}
                                            {/*            options={this.state.localTotalStates}*/}
                                            {/*            value={this.state.multiSelectedStates}*/}
                                            {/*            onChange={(e) => this.onChangeUpdateSelectedStates(e)}*/}
                                            {/*            labelledBy={"Select"}*/}
                                            {/*            hasSelectAll={false}*/}
                                            {/*            filterOptions={this.filterOptions}*/}
                                            {/*            overrideStrings={{*/}
                                            {/*                "search": "Type to filter",*/}
                                            {/*                "selectSomeItems": "Select"*/}
                                            {/*            }}*/}
                                            {/*            isOpen={true}*/}
                                            {/*        />*/}
                                            {/*    </Form.Group>*/}
                                            {/*</Form>*/}
                                            <ul className="filtered-list">
                                                {this.state.localTotalStates.map(selected => {
                                                    // if (this.props.projectByStates[selected.value] > 0){
                                                    return (
                                                        <li key={selected.label}>
                                                            <Form.Group controlId={selected.label}>
                                                                {selected.label}
                                                                <div className="count-check">
                                                                    {/*<span className="counter">{this.props.projectByStates[selected.value]}</span>*/}
                                                                    {selected.checked &&
                                                                        <span
                                                                            className="counter">{this.props.projectByStates[selected.value] > 0 ? this.props.projectByStates[selected.value] : '0'}</span>
                                                                    }
                                                                    <Form.Check htmlFor={selected.label} type="checkbox"
                                                                                label="" id={selected.value}
                                                                                name={selected.label}
                                                                                checked={selected.checked}
                                                                                onChange={this.onStateChange}/>
                                                                </div>
                                                            </Form.Group>
                                                        </li>
                                                    )
                                                    // }
                                                })}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Form>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="">

                                        <div
                                            className={`filter-actions ${activeClasses[1] ? "filter-actions" : "hide-filter"}`}
                                            onClick={() => this.showMoreStates(1)} ref={this.myYearAccordion}>

                                            <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center"
                                                              variant="link" eventKey="1">
                                                <strong className="filter-name">
                                                    Completion Year
                                                </strong>
                                                <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                            </Accordion.Toggle>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body ref={this.myYear}>
                                            <ul className="completion-year-options filtered-list">
                                                {this.state.rangCompletionYear.map(selected => {
                                                    return (
                                                        <li>
                                                            <Form.Group controlId={selected.label}>
                                                                {selected.label}
                                                                <div className="count-check">
                                                                    <Form.Check for={selected.label} type="checkbox"
                                                                                label="" id={selected.label}
                                                                                name={selected.label}
                                                                                checked={selected.checked}
                                                                                onChange={this.onYearListChange}/>
                                                                </div>
                                                            </Form.Group>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <div className="states-data">
                                    <div className="data-pane">
                                        <ul className="filtered-list">
                                            {!_.isEmpty(this.state.completionYear) ?
                                                <li>
                                                    <Form.Group controlId={this.state.completionYear.label}>
                                                        {this.state.completionYear.label}
                                                        <div className="count-check">
                                                            <Form.Check for={this.state.completionYear.label}
                                                                        type="checkbox" label=""
                                                                        id={this.state.completionYear.label}
                                                                        name={this.state.completionYear.label}
                                                                        checked={this.state.completionYear.checked}
                                                                        onChange={this.onYearSelectedChange}/>
                                                        </div>
                                                    </Form.Group>
                                                </li>
                                                : ''
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="">

                                        <div
                                            className={`filter-actions ${activeClasses[3] ? "filter-actions" : "hide-filter"}`}
                                            onClick={() => this.showMoreStates(3)} ref={this.mySectorAccordion}>
                                            <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center"
                                                              variant="link" eventKey="2">
                                                <strong className="filter-name">
                                                    Sector
                                                </strong>
                                                <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                            </Accordion.Toggle>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body ref={this.mySector}>
                                            <Form>
                                                <Form.Group controlId="formSectorFilter">
                                                    <MultiSelect
                                                        options={this.state.localTotalSectors}
                                                        value={this.state.multiSelectedSectors}
                                                        onChange={(e) => this.onChangeUpdateSelectedSectors(e)}
                                                        labelledBy={"Select"}
                                                        hasSelectAll={false}
                                                        filterOptions={this.filterOptions}
                                                        overrideStrings={{
                                                            "search": "Type to filter",
                                                            "selectSomeItems": "Select"
                                                        }}
                                                        isOpen={true}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <div className="states-data">
                                    <div className="data-pane">
                                        <ul className="filtered-list">
                                            {this.state.showMultiSelectedSectors.map(selected => {
                                                // if (this.props.projectBySectors[selected.value] > 0){
                                                return (
                                                    <li>
                                                        <Form.Group controlId={selected.label}>
                                                            {selected.label}
                                                            <div className="count-check">
                                                                {/*<span className="counter">{this.props.projectBySectors[selected.value]}</span>*/}
                                                                <span
                                                                    className="counter">{this.props.projectBySectors[selected.value] > 0 ? this.props.projectBySectors[selected.value] : '0'}</span>
                                                                <Form.Check for={selected.label} type="checkbox"
                                                                            label="" id={selected.value}
                                                                            name={selected.label}
                                                                            checked={selected.checked}
                                                                            onChange={this.onSectorChange}/>
                                                            </div>
                                                        </Form.Group>
                                                    </li>
                                                )
                                                // }
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="">
                                        <div
                                            className={`filter-actions ${activeClasses[4] ? "filter-actions" : "hide-filter"}`}
                                            onClick={() => this.showMoreStates(4)} ref={this.myInvovlementAccordion}>
                                            <Accordion.Toggle as={Button} className="p-0 d-flex align-items-center"
                                                              variant="link" eventKey="3">
                                                <strong className="filter-name">
                                                    Ryan Involvement
                                                </strong>
                                                <span className="icon ml-auto">
                                                <img src={iconUp} alt="hel"/>
                                            </span>
                                            </Accordion.Toggle>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body ref={this.myInvovlement}>
                                            <Form>
                                                <Form.Group controlId="formSectorFilter">
                                                    <MultiSelect
                                                        options={this.state.localTotalInvolvements}
                                                        value={this.state.multiSelectedInvolvements}
                                                        onChange={(e) => this.onChangeUpdateSelectedInvolvement(e)}
                                                        labelledBy={"Select"}
                                                        hasSelectAll={false}
                                                        filterOptions={this.filterOptions}
                                                        overrideStrings={{
                                                            "search": "Type to filter",
                                                            "selectSomeItems": "Select"
                                                        }}
                                                        isOpen={true}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <div className="states-data">
                                    <div className="data-pane">
                                        <ul className="filtered-list">
                                            {this.state.showMultiSelectedInvolvements.map(selected => {
                                                return (
                                                    <li>
                                                        <Form.Group controlId={selected.label}>
                                                            {selected.label}
                                                            <div className="count-check">
                                                                <span
                                                                    className="counter">{this.props.projectByInvolvement[selected.value] ? this.props.projectByInvolvement[selected.value] : '0'}</span>
                                                                <Form.Check for={selected.label} type="checkbox"
                                                                            label="" id={selected.value}
                                                                            name={selected.label}
                                                                            checked={selected.checked}
                                                                            onChange={this.onInvolvementChange}/>
                                                            </div>
                                                        </Form.Group>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="d-flex justify-content-between align-items-between">
                                        <div className="title-pane">
                                            <strong className="filter-name">
                                                Square Feet
                                            </strong>
                                        </div>
                                        <Button className="btn btn-link p-0 apply-btn" variant=""
                                                onClick={this.onFormSubmitSquareFootage} type="button">Apply</Button>
                                    </Card.Header>
                                </Card>
                                <div className="states-data states-data-inputs">
                                    <div className="data-pane">
                                        <Form.Group controlId="formSquareFeet">
                                            <Form.Control className="feet feet-left" placeholder="Min" type="text"
                                                          value={this.state.minSquare}
                                                          onChange={e => this.updateMin(e)}/>
                                            <Form.Control className="feet" placeholder="Max" type="text"
                                                          value={this.state.maxSquare}
                                                          onChange={e => this.updateMax(e)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form>
                            <Form className="form-general side-filter-form">
                                <Card className="filter-card">
                                    <Card.Header className="d-flex justify-content-between align-items-between">
                                        <div className="title-pane">
                                            <strong className="filter-name">
                                                Keywords
                                            </strong>
                                        </div>
                                        <Button className="btn btn-link p-0 apply-btn" variant=""
                                                onClick={this.onFormSubmitKeywords} type="button">Apply</Button>
                                    </Card.Header>
                                </Card>
                                <div className="states-data states-data-inputs">
                                    <div className="data-pane">
                                        <Form.Group controlId="formSquareFeet">
                                            <Form.Control className="" placeholder="keywords" type="text"
                                                          value={this.state.keywords}
                                                          onChange={e => this.updateKeywords(e)}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className={`${this.props.isFilterOpen? "" : "show-parameters"}`}>
                            <SelectedParameters localTotalStates={this.state.localTotalStates}
                                                completionYear={this.state.completionYear}
                                                showMultiSelectedSectors={this.state.showMultiSelectedSectors}
                                                showMultiSelectedInvolvements={this.state.showMultiSelectedInvolvements}
                                                minSquare={this.state.minSquare} maxSquare={this.state.maxSquare}
                                                keywords={this.state.keywords}
                                                onClearAllSelectedStates={this.onClearAllSelectedStates}
                                                onStateChange={this.onStateChange}
                                                onYearSelectedChange={this.onYearSelectedChange}
                                                onClearAllSelectedSectors={this.onClearAllSelectedSectors}
                                                onSectorChange={this.onSectorChange}
                                                onClearAllSelectedInvolvements={this.onClearAllSelectedInvolvements}
                                                onInvolvementChange={this.onInvolvementChange}
                                                onClearKeywords={this.onClearKeywords}
                                                onClearAll={this.onClearAll} onClearSquareFeet={this.onClearSquareFeet}
                            />
                        </div>
                    </Accordion>
                </aside>}
                <div className="overlay">
                    <a className={`filter-close-link`} onClick={this.closeSideFilter}>
                        <img src={iconArrow} alt="icon" />
                    </a>
                </div>
            </>
        );
    }
});
