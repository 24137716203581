import React from 'react'
import proImage from '../../assets/images/project-image.png';
import {Link} from "react-router-dom";
import _ from "lodash";

const ShareableProjectCard = ({ id, projectName, projectCity, projectState, images, list, currentPage, multipleStates, sector }) => {
    let imgOne = proImage
    let imgTwo = proImage
    let imgThree = proImage
    if (!_.isEmpty(images)) {
        imgOne = images.length >= 1 ? images[0].url : proImage;
        imgTwo = images.length >= 2 ? images[1].url : proImage;
        imgThree = images.length >= 3 ? images[2].url : proImage;
    }
    const show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }
    return (
        <Link to={{pathname: `/shareable/${list.token}/project/${id}`, state: {currentPage: currentPage}}} className="share-card-link">
            <div className="card share-list-card">
                <div className="card-header">
                    <div className="img-gallery">
                    <div className="main-img">
                        <div className="media">
                        <img className="img-fluid" src={imgOne} alt="" />
                        </div>
                    </div>
                    <div className="sub-img">
                        <div className="media">
                        <img className="" src={imgTwo} alt="" />
                        </div>
                        <div className="media">
                        <img className="" src={imgThree} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
                <div className="card-body">
                    <h3 className="list-name">{projectName}</h3>
                    { projectCity && projectCity.includes('Multiple') ?
                        <span className="location">{sector}<br/>{show_states(multipleStates)}</span>
                        :
                        <span className="location">{sector}<br/>{projectCity}, {projectState}</span>
                    }
                </div>
            </div>
            {/* <div className="card project-card">
                <div className="img-pane media">
                    <LazyLoadImage
                        className="card-img-top"
                        alt="Project Image"
                        effect="blur"
                        src={image_path(coverUrl)} />
                </div>
                <div className="card-body">
                    <h4 className="card-text">
                        {projectName}
                    </h4>
                    <div className="check-label-pane d-flex justify-content-between">
                        <small>
                            {projectCity}, {projectState}
                        </small>
                    </div>
                </div>
            </div> */}
        </Link>
    );
}
export default ShareableProjectCard;
