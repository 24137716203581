import React, {Component} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import ProjectSearch from "./Projects/ProjectSearch";

export default withOktaAuth(class Home extends Component {
    render()
    {
        return (
            <ProjectSearch/>
        );
    }
});
