/* eslint-disable */
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import close from '../../../assets/images/icons/close-circle.svg';
import add from '../../../assets/images/icons/plus-circle.svg';
import confirm from '../../../assets/images/icons/confirm-icon.svg';
import rpa from "../../../apis/rpa";
import _ from "lodash";
import {toast} from "react-toastify";
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "../AppSpinner";
import {Link} from "react-router-dom";

export default withOktaAuth(class SaveListSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveLists: [],
            userDetails: null,
            listName: '',
            isSaved: false,
            savedListName: '',
        }
        this.saveListInput = React.createRef();
    }
    componentDidMount() {

        if (!_.isEmpty(this.props.userDetails)) {
            this.setState({userDetails: this.props.userDetails}, () => this.getSaveLists())
        }
    }
    getSaveLists = () => {
        trackPromise(rpa.get(`users/${this.state.userDetails.sub}/save_lists`, {
            params: {
                topBar: true
            }
        })
            .then(res => {
                let saveLists = _.orderBy(res.data.save_lists, ['name'], ['asc']);
                this.setState({saveLists: saveLists})
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'save-list-loader');
    }
    onchangeListName = (e) => {
        this.setState({listName: e.target.value})
    }
    addSaveLists = () => {
        trackPromise(rpa.post(`users/${this.state.userDetails.sub}/save_lists`, {
            save_list: {
                name: this.state.listName
            }
        }).then(response => {
            if(response.data.success){
                let saveLists = Object.assign([], this.state.saveLists);
                saveLists.push({id: response.data.save_list.id, name: response.data.save_list.name})
                saveLists = _.orderBy(saveLists, ['name'], ['asc']);
                toast.success('Created Successfully')
                this.setState({saveLists: saveLists})
            }
            else{
                toast.error(response.data.errors)
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Save List Error', err)
        }), 'save-list-loader');
    }
    addProjectsSaveLists = (saveListId, savedListName) => {
        trackPromise(rpa.patch(`users/${this.state.userDetails.sub}/save_lists/${saveListId}`, {
            save_list: {
                projectIds: this.props.selectedProjects
            }
        }).then(response => {
            if(response.data.success){
                let saveLists = Object.assign([], this.state.saveLists);
                saveLists = _.orderBy(saveLists, ['name'], ['asc']);
                // toast.success('Save List Created')
                this.setState({saveLists: saveLists, isSaved: true, savedListName: savedListName})
            }
            else{
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Something wrong In List Update', err)
        }),'save-list-loader');
    }
    onFormUpdateSaveList = (e) => {
        this.addProjectsSaveLists(e.target.id, e.currentTarget.name)
    }
    onFormSaveListSubmit = (e) => {
        this.addSaveLists()
        this.saveListInput.current.value = ''
    }
    onClickCloseButton = () => {
        this.setState({isSaved: false})

        this.props.closeDropMenu();
    }
    render() {
        return (
            <div className="save-search-content">
                <AppSpinner area="save-list-loader"/>
                <div className={`${this.state.isSaved? "my-custom-hide" : ""}`}>
                    <div className="save-search-header">
                        <strong>
                            Select existing or create
                            <a href="#" className="d-inline-block ml-1">new</a>
                        </strong>
                        <Button variant="close-btn p-0" onClick={this.onClickCloseButton}>
                            <img src={close} alt="" />
                        </Button>
                    </div>
                    <div className="form-pane">
                        <Form>
                            <Form.Group controlId="" className="p-0 border-0">
                                <Form.Control type="text" placeholder="Create new list" onChange={this.onchangeListName}
                                              value={this.listName} ref={this.saveListInput}/>
                                <Button variant="btn-add" type="button" onClick={this.onFormSaveListSubmit}>
                                    <img src={add} alt=""/>
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
                <div className="saved-pane">
                    <ul className="saved-list list-unstyled">
                        {this.state.saveLists && this.state.saveLists.map(saveList => {
                            return (
                                <li key={saveList.id}>
                                    <h6 className="search-name">
                                        {saveList.name}
                                    </h6>
                                    <Button className="btn-small" name={saveList.name} id={saveList.id} onClick={this.onFormUpdateSaveList}>
                                        Save
                                        <span id={saveList.id}/>
                                    </Button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                </div>
                <div className={`success-pane ${!this.state.isSaved? "my-custom-hide" : ""}`}>
                    <div className="btn-box pt-0">
                        <Button variant="close-msg p-0" onClick={this.onClickCloseButton}>
                            <img src={close} alt="" />
                        </Button>
                    </div>
                    <div className="msg-pane">
                        <div className="media">
                            <img src={confirm} alt="" />
                        </div>
                        <p>
                            Successfully saved in
                            <br/>
                            "{this.state.savedListName}"
                        </p>
                        <p className="msg-small">
                            Go to <Link to={{pathname: "/projects/save_list", data: {userDetails: this.state.userDetails, searchLists: this.state.searchLists}}}>My Saved Lists</Link> for edits and more
                        </p>
                    </div>
                </div>
            </div>
        );
    }
});

