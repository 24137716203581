import React, {Component} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from '../../assets/images/Badge-logo.svg';
import Info from '../../assets/images/icons/info-green.svg';
import menu from '../../assets/images/icons/menu-icon.svg';
import back from '../../assets/images/icons/arrow-back.svg';
import {Link, NavLink} from "react-router-dom";
import {sessionService} from 'redux-react-session';
import {UserParams} from "../CustomHooks/UserDetails";
import _ from "lodash";
import rpa from "../../apis/rpa";
import profile from '../../assets/images/avatar.png';
import {clearSessionAndStorage} from "../Actions/AppActions";

export default withOktaAuth(class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: null,
            superUserDetails: null,
            isMenuOpen: false,
            username: ''
        };
    }
    componentDidMount() {
        sessionService.loadSession().then(response => {
            let username = this.getUsername(response.preferred_username)
            this.setState({userDetails: response, username: username}, () => this.getSuperUserDetails())
        }).catch(err => {
            this.getOktaUser()
        })
    }
   componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.state.superUserDetails) && this.props.authState.isAuthenticated){
            this.verifyCurrentLogin()
        }
    }
    createUser = (userData) => {
        rpa.post('/users', {
            UserEndPointResponse: {
                res: userData
            },
            user: {
                uid: userData.sub,
                email: userData.preferred_username,
                first_name: userData.given_name,
                last_name: userData.family_name,
                division: (!_.isEmpty(userData.division) ? userData.division : ''),
                department: (!_.isEmpty(userData.department) ? userData.department : '')
            }
        }).then(res => {
            this.getSuperUserDetails(res.data)
        }).catch(err => console.log('user request home', err))
    }
    getSuperUserDetails = (data=null) => {
        sessionService.loadUser().then(response => {
            this.setState({superUserDetails: response.user})
        }).catch(err => {
            if (!_.isEmpty(data)){
                this.setState({superUserDetails: data.user}, () => sessionService.saveUser(data))
            }
        })
    }
    getDate = () => {
        return new Date().toDateString()
    }
    verifyCurrentLogin = () => {
        let last_login = UserParams.getItem('lastLogin')
        if (!_.isEmpty(last_login)) {
            if (last_login !== this.getDate()) this.loginRequest(this.state.superUserDetails)
        } else {
            if (this.getDate() === new Date(this.state.superUserDetails.last_login).toDateString()) this.loginRequest(this.state.superUserDetails)
            else UserParams.setItem('lastLogin', new Date(this.state.superUserDetails.last_login).toDateString())
        }
    }
    getOktaUser = () => {
        if (this.props.authState.isAuthenticated && !this.props.authState.isPending) {
            this.props.authService.getUser().then(
                response => {
                    let username = this.getUsername(response.preferred_username)
                    this.setState({userDetails: response, username: username})
                    sessionService.saveSession(response)
                    this.createUser(response)
                    // this.updateUserDivision(response)
                    this.getSuperUserDetails()
                }
            ).catch(err => console.log('Info userInfo Using Endpoint home Err', err))
        }
    }
    loginRequest = (data) => {
        rpa.patch(`/users/${data.uid}`, {
            user: {trackLogin: true},
        }).then(response => {
            UserParams.setItem('lastLogin', new Date(response.data.user.last_login).toDateString())
            this.setState({superUserDetails: response.data.user}, () => sessionService.saveUser(response.data))
        })
    }
    updateUserDivision = (userData) => {
        rpa.patch(`/users/${userData.sub}`, {
            user: {
                division: (!_.isEmpty(userData.division) ? userData.division : ''),
                department: (!_.isEmpty(userData.department) ? userData.department : '')
            }
        }).then(rr => {
            this.getSuperUserDetails(rr.data)
        }).catch(err => console.log('user update request home', err))
    }
    logoutAndClearData = () => {
        this.props.authService.logout();
        localStorage.removeItem('filters');
        clearSessionAndStorage()
    }
    showUser = () => {
        return !_.isEmpty(this.state.userDetails) ? `${this.state.userDetails.name}` : ''
    }
    closeMenu = () => {
        let menu = document.getElementById('basic-navbar-nav');
        menu.classList.remove('show');
    }
    closeDropdown = () => {
        document.getElementById('dropdown').click();
    }
    getUsername = (username) => {
        return _.isEmpty(username) ? "" : username.split("@")[0]
    }
    showNavbar = () => {
        return(
        (this.props.authState.isAuthenticated && !this.props.authState.isPending) ?
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav "/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="">
                        <Nav.Link className="btn-close" onClick={this.closeMenu}>
                            <img src={back} alt="back" />
                        </Nav.Link>
                        <Nav.Link className="btn btn-primary" onClick={this.closeMenu} as={NavLink} to='/projects/save_list'>My Saved Lists</Nav.Link>
                        <Nav.Link className="btn btn-primary" onClick={this.closeMenu} as={NavLink} to='/projects/save_search'>My Saved Searches</Nav.Link>
                        {this.state.superUserDetails && this.state.superUserDetails.super_admin ? <Nav.Link className="btn btn-primary" onClick={this.closeMenu} as={NavLink} to='/users'>User Management</Nav.Link> : ''}
                        {this.state.superUserDetails && this.state.superUserDetails.super_admin ? <Nav.Link className="btn btn-primary" onClick={this.closeMenu} as={NavLink} to='/activity'>Activity</Nav.Link> : ''}
                        <Nav.Link className="btn btn-primary" onClick={() => { this.logoutAndClearData() }}>Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className="name-pane">
                    <span className="id-name">
                        {this.showUser()}
                    </span>
                    {this.state.username ?
                    <span className="img-pane">
                        <img className="img-fluid" src={`https://rpaprofiles.sfo2.cdn.digitaloceanspaces.com/%5C/${this.state.username}.jpg`} alt='profile'/>
                    </span>
                    :
                    <span className="img-pane">
                        <img className="img-fluid" src={profile} alt="profile"/>
                    </span>
                    }
                </div>
                <Dropdown className="navbar-dropdown ml-3">
                    <Dropdown.Toggle variant="" id="dropdown">
                        <img src={menu} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="info-link-pane">
                            <Nav.Link className="btn btn-primary mr-4" onClick={this.closeDropdown} as={NavLink} to='/projects/save_list'>My Saved Lists</Nav.Link>
                            <Dropdown className="toolpane">
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    <img src={Info} alt="" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <span>
                                        Previously saved personal project lists which may be edited or shared.
                                    </span>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="info-link-pane">
                            <Nav.Link className="btn btn-primary mr-4" onClick={this.closeDropdown} as={NavLink} to='/projects/save_search'>My Saved Searches</Nav.Link>
                            <Dropdown className="toolpane">
                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                    <img src={Info} alt="" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <span>
                                        Previously saved search parameters which may be edited and/or refreshed to view added projects.
                                    </span>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {this.state.superUserDetails && this.state.superUserDetails.super_admin ? <div className="info-link-pane">
                            <Nav.Link className="btn btn-primary mr-4" onClick={this.closeDropdown} as={NavLink} to='/users'>User Management</Nav.Link>
                        </div> : ''}
                        {this.state.superUserDetails && this.state.superUserDetails.super_admin ? <div className="info-link-pane">
                            <Nav.Link className="btn btn-primary mr-4" onClick={this.closeDropdown} as={NavLink} to='/activity'>Activity</Nav.Link>
                        </div> : ''}
                        <a className="btn btn-primary mr-4 nav-link" href="#logout" onClick={() => { this.logoutAndClearData() }}>Logout</a>
                    </Dropdown.Menu>
                </Dropdown>
            </> : '')
    }
    render() {
        return (
            <header className="header">
                <Navbar expand="lg">
                    <Navbar.Brand as={Link} to="/">
                        <img className="img-fluid" src={Badge} alt="badge"/>
                    </Navbar.Brand>
                    {this.showNavbar()}
                </Navbar>
            </header>
        );
    }
});
