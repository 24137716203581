import _ from "lodash";

export const getProjectServices = (project, ryanServices, architectServices) => {
    let sortedServices = Object.entries(project).filter(attr => attr[1] === true)
        .map(filtered_service => ryanServices[filtered_service[0]])
        .sort()
    return (
        <>
            {getArchitectureServices(project, architectServices)}
            {!_.isEmpty(sortedServices) && sortedServices.map(ryan_service => <li
                key={ryan_service}>{ryan_service}</li>)}
        </>
    )
}
export const getArchitectureServices = (project, architectServices) => {
    // if (project.architecture_engineering) {
        let archServices = Object.entries(project).filter(attr => attr[1] === true)
            .map(filtered_service => architectServices[filtered_service[0]])
            .sort()
        return archServices.map(arc => <li key={arc}>{arc}</li>)
    // }
}
