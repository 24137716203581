import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import _ from "lodash";
import rpa from "../../apis/rpa";
import {toast} from "react-toastify";
import close from '../../assets/images/icons/close-circle.svg';
import confirm from '../../assets/images/icons/confirm-icon.svg';
import copy from '../../assets/images/icons/copy-icon.svg';
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "./AppSpinner";

export default (class shareLink extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            updateTime: '',
            updatedListToken: this.props.listToken,
            shareableLink: '',
            listName: ''
        }
    }

    componentDidMount() {
        if(_.isEmpty(this.props.listToken) && _.isEmpty(this.props.projectShare)){
            trackPromise(rpa.get(`users/${this.props.userDetails.sub}/save_lists/${this.props.listId}/get_shareable_url`, {
            }).then(response => {
                if(response.data.success){
                    this.setState({updatedListToken: response.data.save_list.token, listName: response.data.save_list.name}, ()=> this.generateUrl())
                }
                else{
                    toast.error(response.data.errors)
                }
            }).catch(err => {
                toast.error(err.message);
            }), 'share-link-spinner');
        }else return _.isEmpty(this.props.projectShare) ? this.generateUrl() : this.projectShareAbleUrl()
    }

    generateUrl = () => {
        var url = `${window.location.origin}/shareable?token=${this.state.updatedListToken}`
        this.setState({shareableLink: url})
    }
    projectShareAbleUrl = () => {
        trackPromise(rpa.get(`users/${this.props.userDetails.sub}/projects/${this.props.project.id}/shareable_link`, {
        }).then(response => {
            if(response.data.success){
                this.setState({updatedListToken: response.data.shareable_link.token}, ()=> this.generateProjectUrl())
            }
            else{
                toast.error(response.data.errors)
            }
        }).catch(err => {
            toast.error(err.message);
        }), 'share-link-spinner');
    }
    copyToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = this.state.shareableLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success('Copied !')
    }
    generateProjectUrl = () => {
        let url = `${window.location.origin}/shareable_project/${this.state.updatedListToken}`
        this.setState({shareableLink: url})
    }
    render () {
        return (
            <div className="App">
                {/*<Button variant="primary" className="py-0 px-2" onClick={this.handleShow}>*/}
                {/*    Share*/}
                {/*</Button>*/}
                <Modal show={this.props.show} className="delete-modal" onHide={this.props.handleShareClose}>
                    <Modal.Header>
                        <Modal.Title>
                            Share List
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AppSpinner customClass="delete-loader" area="share-link-spinner"/>
                        <div className="msg-box">
                            <div className="close-box text-right">
                                <Button variant="close" onClick={this.props.handleShareClose}>
                                    <img src={close} alt="" />
                                </Button>
                            </div>
                            <div className="media margin-30">
                                <img src={confirm} alt=""/>
                            </div>
                            <p className="project-message">
                                {this.props.listName && this.props.listName}
                                <br/>
                                link has been generated for sharing
                            </p>
                            <a href={this.state.shareableLink} className="shareable-link format-link" target="_blank">
                                {this.state.shareableLink}
                            </a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="copy" className="mx-auto" onClick={this.copyToClipboard}>
                            <img className="mr-1" src={copy} alt=""/>
                            Copy URL
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
});
