/* eslint-disable */
import React, {Component} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import add from '../../../assets/images/icons/plus-circle.svg';
import delDark from '../../../assets/images/icons/trash-icon-dark.svg';
import filter from '../../../assets/images/icons/filter-icon-gray.svg';
import menuDot from '../../../assets/images/icons/menu.svg';
import AppSpinner from "../AppSpinner";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import {trackPromise} from "react-promise-tracker";
import rpa from "../../../apis/rpa";
import ProjectCard from "../ProjectCard";
import {toast, ToastContainer} from "react-toastify";
import SavedSearchesSideTop from "./SavedSearchesSideTop";
import DeleteModal from "./DeleteModal";
import {sessionService} from "redux-react-session";
import SaveListFilter from "./SaveListFilter";

export default withOktaAuth(class SavedSearchesSide extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFilterOpen: false,
            payLoad: null,
            updatedFilters:{},
            projectByStates: [],
            projectBySectors: [],
            projectByInvolvement:[],
            searchLists: [],
            currentList: {},
            CurrentListProjects: [],
            pageCount: null,
            currentPage: 1,
            listName: '',
            isOpen: false,
            selectedProjects:[],
            projectsCount: '',
            load: undefined,
            deleteListId: null,
            userDetails: null,
            response: undefined,
            loadProject: undefined,
            err: undefined,
            isSaveButtonDisabled: true
        }
        this.searchListSide = React.createRef();
    }
    componentDidMount() {
        this.setUserDetails()
    }
    setUserDetails = () => {
        if(_.isEmpty(this.userDetails)){
            sessionService.loadSession().then(response => {
                this.setState({userDetails: response}, () => this.getSearchLists())
            }).catch(err => {
            })
        }
    }
    getSearchLists = () => {
        trackPromise(rpa.get(`users/${this.state.userDetails.sub}/search_lists`, {
            params: {
            }
        })
            .then(res => {
                let searchLists = _.orderBy(res.data.search_lists, ['name'], ['asc']);
                if(!_.isEmpty(searchLists)){
                    this.setState({searchLists: searchLists, currentList: searchLists[0], load: true}, () => this.getCurrentListProjects())
                }
                else{
                    this.setState({searchLists: [], currentList: {}, CurrentListProjects: [], load: true, updatedFilters: {}})
                }
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'search-list-loader');
    }
    handlePageClick = (event) => {
        this.setState({currentPage: event.selected + 1}, () => this.getCurrentListProjects())
        this.setState({isSaveButtonDisabled: true})
    }
    getCurrentListProjects = () => {
        if (this.state.currentList && Number.isInteger(this.state.currentList.id)) {
            let currentPage = this.state.currentPage
            trackPromise(rpa.get(`/users/${this.state.userDetails.sub}/search_lists/${this.state.currentList.id}`, {
                params: {
                    page: currentPage
                }
            }).then(res => {
                    this.setState({CurrentListProjects: res.data.projects, currentList: res.data.list[0],
                        load: true, currentPage: res.data.page, pageCount: res.data.page_count, loadProject: true,
                        projectByStates: res.data.project_by_state, projectBySectors: res.data.project_by_sector, projectByInvolvement: res.data.project_by_involvement})
                })
                .catch(err => {
                     // this.setState({err: err, load: false})
                }), 'search-list-loader');
        }
    }
    updateSelectedList = (obj) => {
        let searchLists = Object.assign([], this.state.searchLists);
        searchLists = searchLists.filter(list => list.id !== obj.id)
        searchLists = _.orderBy(searchLists, ['name'], ['asc']);
        if (!_.isEmpty(searchLists)){
            this.setState({searchLists: searchLists, currentList: searchLists[0]}, () => this.getCurrentListProjects())
        }
        else {
            this.setState({searchLists: [], currentList: {}, CurrentListProjects: []})
        }
    }
    updateByOrder = (projects) => {
        this.setState({CurrentListProjects: projects})
    }
    onProjectCheckBoxChange = (e) => {
        let selectedProjects = Object.assign([], this.state.selectedProjects)
        if (e.target.checked){
            selectedProjects.push(e.target.id)
        }
        else
        {
            selectedProjects = selectedProjects.filter(id => id !== e.target.id)
        }
        this.setState({selectedProjects: selectedProjects})
        this.disbaleSaveProjectButton();
    }
    onDeleteHide = () => {
        this.setState({isOpen: !this.state.isOpen})
    }
    addSearchLists = () => {
        trackPromise(rpa.post(`users/${this.state.userDetails.sub}/search_lists`, {
            search_list: {
                name: this.state.listName
            }
        }).then(response => {
            if(response.data.success){
                let searchLists = Object.assign([], this.state.searchLists);
                searchLists.push(response.data.search_list)
                searchLists = _.orderBy(searchLists, ['name'], ['asc']);
                toast.success('Search list Created')
                this.setState({searchLists: searchLists, currentList: searchLists[0]}, () => this.getCurrentListProjects())
            }
            else
            {
                toast.error(response.data.errors)
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Search List Error', err)
        }), 'save-search-side');
    }
    onchangeListName = (e) => {
        this.setState({listName: e.target.value})
    }
    onFormSearchListSubmit = (e) => {
        this.addSearchLists()
        this.searchListSide.current.value = ''
    }
    onClickDelete = (e) => {
        this.setState({isOpen: !this.state.isOpen, deleteListId: e.currentTarget.id, listName: e.currentTarget.name})
    }
    UpdateSelectedListButton = (e) => {
        this.inActiveList();
        let searchLists = Object.assign([], this.state.searchLists);
        let currentList = searchLists.filter((item) => {return item.id === parseInt(e.currentTarget.id)})
        this.setState({currentList: currentList[0], updatedFilters: currentList[0], loadProject: false}, () => this.getCurrentListProjects())
    }
    onShowHideFilter = () => {
        this.setState({isFilterOpen: !this.state.isFilterOpen})
    }
    handlePageClickFilter = (event) => {
        this.setState({currentPage: event.selected + 1}, () => this.getProjects(this.state.updatedFilters))
    }
    getProjects = (filters) => {
        let currentPage = this.state.currentPage
        trackPromise(rpa.get(`/projects?year=${filters.year}&page=${currentPage}`, {
            params: {
                states: filters.states,
                sector: filters.sectors,
                involvement: filters.involvement,
                minSquare: filters.minSquare,
                maxSquare: filters.maxSquare,
                keywords: filters.keywords,
            }
        })
            .then(res => {
                this.setState({CurrentListProjects: res.data.projects, projectByStates: res.data.project_by_state, projectBySectors: res.data.project_by_sector, projectByInvolvement: res.data.project_by_involvement, load: true, pageCount: res.data.page_count, currentPage: res.data.page, projectsCount: res.data.projects_count, updatedFilters: filters})
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'project-list-loader');
    }
    onShowFilter = (e) => {
        let searchLists = Object.assign([], this.state.searchLists);
        let currentList = searchLists.filter((item) => {return item.id === parseInt(e.currentTarget.id)})
        this.setState({currentList: currentList[0], updatedFilters: currentList[0], loadProject: false, isFilterOpen: !this.state.isFilterOpen}, () => this.getCurrentListProjects())
    }
    inActiveList = (e) => {
        let listMobile = document.querySelector('.saved-list-box');
        if(listMobile.classList.contains('active')) {
            listMobile.classList.remove('active');
        }
        else {
            listMobile.classList.add('active');
        }
    }
    disbaleSaveProjectButton = () => {
        let checked = document.querySelectorAll(".projectCheckbox input[type='checkbox']:checked").length
        if(checked > 0){
            this.setState({isSaveButtonDisabled: false})
        }else{
            this.setState({isSaveButtonDisabled: true})
        }
    }
    render() {
        let load = this.state.load
        let loadProject = this.state.loadProject
        return (
            <main className="project-list-page">
                <AppSpinner area="project-list-loader" customClass="projects-loader" />
                <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {/*{this.state.load===false && <Message color='red' header={this.state.err}/>}*/}
                {load &&
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" >
                            {loadProject && <SavedSearchesSideTop inActiveList={this.inActiveList} isFilterOpen={this.state.isFilterOpen} onShowHideFilter={this.onShowHideFilter} projects={this.state.CurrentListProjects} currentList={this.state.currentList} userDetails={this.state.userDetails} updateByOrder={this.updateByOrder} selectedProjects={this.state.selectedProjects} isSaveButtonDisabled={this.state.isSaveButtonDisabled}/>}
                        </div>
                        <div className="col-12 col-md-4 col-lg-3">
                            <div className={`saved-list-box active ${this.state.isFilterOpen? "col-hide" : ""}`}>
                                <AppSpinner customClass="add-search-list-loader" area="save-search-side"/>
                                <h4 className="list-heading mb-1">
                                    My Saved Searches
                                </h4>
                                <Form className="save-form">
                                    <Form.Group controlId="">
                                        <Form.Control type="text" placeholder="Set new search name"
                                                      onChange={this.onchangeListName}
                                                      value={this.listName} ref={this.searchListSide}/>
                                        <Button variant="btn-add" type="button" onClick={this.onFormSearchListSubmit}>
                                            <img src={add} alt=""/>
                                        </Button>
                                    </Form.Group>
                                </Form>
                                <ul className="saved-list list-unstyled">
                                    {this.state.searchLists ? this.state.searchLists.map(list => {
                                        return (
                                            <li className={`${list.name === this.state.currentList.name ? "active" : ""}`} key={list.id}>
                                                <a className="search-link">
                                                    <div className="list-title" onClick={this.UpdateSelectedListButton} id={list.id}>
                                                        {list.name}
                                                    </div>
                                                    {/*<p className="info">*/}
                                                    {/*    <span className="status">Created</span>*/}
                                                    {/*    &nbsp;*/}
                                                    {/*    <span className="date">{list.list_created_at}</span>*/}
                                                    {/*</p>*/}
                                                    {/* <div className="counter">23</div> */}
                                                    <ul className="options list-unstyled">
                                                        <li>
                                                            <Button variant="btn-option" onClick={this.onClickDelete} id={list.id} name={list.name}>
                                                                <img className="del-icon-dark" src={delDark} alt="del"/>
                                                            </Button>
                                                        </li>
                                                        <li>
                                                            <Button variant="btn-option" onClick={this.onShowFilter} id={list.id}>
                                                                <img className="filter-icon-dark" src={filter} alt="filter"/>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                    <Dropdown className="options-mble d-inline-block d-md-none">
                                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                                            <img src={menuDot} alt="menu"/>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu align="right">
                                                            <Dropdown.Item  onClick={this.onClickDelete} id={list.id}>
                                                                <img src={delDark} className="mr-2" alt="del"/> Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </a>
                                            </li>
                                        )
                                    } ) : ''}
                                </ul>
                            </div>
                            <SaveListFilter projects={this.state.currentList.projects} filters={this.state.currentList}
                                            extractedData={this.state.extractedData}
                                            projectByStates={this.state.projectByStates}
                                            projectBySectors={this.state.projectBySectors}
                                            projectByInvolvement={this.state.projectByInvolvement}
                                            userDetails={this.state.userDetails}
                                            getProjects={this.getProjects} isFilterOpen={this.state.isFilterOpen}
                            />
                        </div>
                        <div className="col-12 col-md-8 col-lg-9">
                            <div className="project-list-pane project-list-pane-mobile">
                                <AppSpinner customClass="delete-loader" area="search-list-loader"/>
                                {
                                <Form>
                                    <Form.Row className="align-items-center">
                                        {(loadProject && this.state.CurrentListProjects.length > 0) ? this.state.CurrentListProjects.map(project => {
                                                return (
                                                    <div className="col-12 col-md-6 col-lg-4" key={project.id}>
                                                        <ProjectCard
                                                            id={project.id}
                                                            onProjectCheckBoxChange={this.onProjectCheckBoxChange}
                                                            projectName={_.isEmpty(project.marketing_project_name) ? project.project_name : project.marketing_project_name}
                                                            substantialCompletionDate={project.substantial_completion_date}
                                                            sector={project.project_type_name}
                                                            projectTypeName={project.project_type_name}
                                                            projectCity={project.project_city}
                                                            projectState={project.project_state}
                                                            images={project.images}
                                                            coverUrl={project.cover_url}
                                                            multipleStates = {project.states}
                                                        />
                                                    </div>
                                                )
                                            })
                                            : <div className="col-12 empty-msg">
                                                <p>No Project Found !</p>
                                            </div>}
                                    </Form.Row>
                                </Form>
                                }
                            </div>
                            <div className={`pagination-box ${this.state.isFilterOpen? "" : ""}`}>
                                <ReactPaginate className="pagination" id="react-paginate"
                                    // labels
                                               previousLabel={''}
                                               nextLabel={''}
                                               breakLabel={'…'}
                                    // classNames
                                               containerClassName={'react-paginate'}
                                               previousClassName={'previous'}
                                               breakClassName={'break-me'}
                                               nextClassName={'next'}
                                               pageClassName={'page'}
                                               activeClassName={'active'}
                                               pageLinkClassName={'page-link'}
                                               disabledClassName={'disabled'}
                                    // functionality
                                               pageCount={this.state.pageCount}
                                               marginPagesDisplayed={1}
                                               pageRangeDisplayed={2}
                                               onPageChange={this.handlePageClickFilter}
                                               disableInitialCallback={true}
                                               forcePage={this.state.currentPage - 1}
                                />
                                <span className="current-page">
                                    Page {this.state.currentPage} of {this.state.pageCount}
                                </span>
                            </div>
                        </div>
                    </div>
                    <DeleteModal isOpen={this.state.isOpen} onDeleteHide={this.onDeleteHide} onClickDelete={this.onClickDelete} id={this.state.deleteListId} userDetails={this.state.userDetails} updateSelectedList={this.updateSelectedList} listName={this.state.listName}/>
                </div>
                }
            </main>
        );
    }
});
