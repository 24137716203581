import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import close from '../../../assets/images/icons/close-circle.svg';
import add from '../../../assets/images/icons/plus-circle.svg';
import rpa from "../../../apis/rpa";
import _ from "lodash";
import {toast} from "react-toastify";
import confirm from "../../../assets/images/icons/confirm-icon.svg";
import {trackPromise} from "react-promise-tracker";
import AppSpinner from "../AppSpinner";
import {Link} from "react-router-dom";
export default withOktaAuth(class SearchEditBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchLists: [],
            userDetails: null,
            listName: '',
            isSaved: false,
            savedListName: '',
        }
        this.searchListInput = React.createRef();
    }
    componentDidMount() {
        if (!_.isEmpty(this.props.userDetails)) {
            this.setState({userDetails: this.props.userDetails}, () => this.getSearchLists())
        }
    }
    getSearchLists = () => {
        trackPromise(rpa.get(`users/${this.state.userDetails.sub}/search_lists`, {
            params: {
            }
        })
            .then(res => {
                let searchLists = _.orderBy(res.data.search_lists, ['name'], ['asc']);
                this.setState({searchLists: searchLists})
            })
            .catch(err => {
                this.setState({err: err, load: false})
            }), 'search-list-loader');
    }
    onchangeListName = (e) => {
        this.setState({listName: e.target.value})
    }
    addSearchLists = () => {
        trackPromise(rpa.post(`users/${this.state.userDetails.sub}/search_lists`, {
            search_list: {
                name: this.state.listName
            }
        }).then(response => {
            if(response.data.success){
                let searchLists = Object.assign([], this.state.searchLists);
                searchLists.push(response.data.search_list)
                searchLists = _.orderBy(searchLists, ['name'], ['asc']);
                toast.success('Search list Created')
                this.setState({searchLists: searchLists})
            }
            else
            {
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Search List Error', err)
        }), 'search-list-loader');
    }
    addProjectsSearchLists = (searchListId, savedListName) => {
        trackPromise(rpa.patch(`users/${this.state.userDetails.sub}/search_lists/${searchListId}`, {
            search_list: {
                states: this.props.filters["states"],
                sectors: this.props.filters["sectors"],
                involvement: this.props.filters["involvement"],
                minSquare: this.props.filters["minSquare"],
                maxSquare: this.props.filters["maxSquare"],
                keywords: this.props.filters["keywords"],
                year: this.props.filters["year"]
            }
        }).then(response => {
            if(response.data.success){
                let saveLists = Object.assign([], this.state.saveLists);
                saveLists = _.orderBy(saveLists, ['name'], ['asc']);
                // toast.success('Projects Added Successfully')
                this.setState({saveLists: saveLists, isSaved: true, savedListName: savedListName})
            }
            else
            {
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Something wrong In List Update', err)
        }), 'search-list-loader');
    }
    onFormUpdateSearchList = (e) => {
        this.addProjectsSearchLists(e.target.id, e.currentTarget.name)
    }
    onClickCloseButton = () => {
        this.setState({isSaved: false})
    }
    onClickCloseDropdown = () => {
        this.setState({isSaved: false})

        this.props.closeDropdown();
    }
    onFormSearchListSubmit = (e) => {
        this.addSearchLists()
        this.searchListInput.current.value = ''
    }
    render() {
        console.log('Filters inside searchlist', this.props.filters)
        return (
            <div className="save-search-content">
                <AppSpinner customClass="hello" area="search-list-loader"/>
                <div className={`${this.state.isSaved? "my-custom-hide" : ""}`}>
                    <div className="save-search-header">
                        <strong>
                            Select existing or create
                            <a href="#" className="d-inline-block ml-1">new</a>
                        </strong>
                        <Button variant="close-btn">
                            <img src={close} alt="" onClick={this.onClickCloseDropdown}/>
                        </Button>
                    </div>
                    <div className="form-pane">
                        <Form>
                            <Form.Group controlId="">
                                <Form.Control type="text" placeholder="Set new search name" onChange={this.onchangeListName}
                                              value={this.listName} ref={this.searchListInput}/>
                                <Button variant="btn-add" type="button" onClick={this.onFormSearchListSubmit}>
                                    <img src={add} alt=""/>
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="saved-pane">
                        <ul className="saved-list list-unstyled">
                            {this.state.searchLists && this.state.searchLists.map(searchList => {
                                return (
                                    <li key={searchList.id}>
                                        <h6 className="search-name">
                                            {searchList.name}
                                        </h6>
                                        <Button className="btn-small" id={searchList.id} name={searchList.name} onClick={this.onFormUpdateSearchList}>
                                            Save
                                            <span id={searchList.id}/>
                                        </Button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className={`success-pane ${!this.state.isSaved? "my-custom-hide" : ""}`}>
                    <div className="btn-box">
                        <Button variant="close-msg p-0" onClick={this.onClickCloseButton}>
                            <img src={close} alt="" />
                        </Button>
                    </div>
                    <div className="msg-pane">
                        <div className="media">
                            <img src={confirm} alt="" />
                        </div>
                        <p>
                            Search filters successfully
                            <br/>
                            saved as {this.state.savedListName}
                        </p>
                        <p className="msg-small">
                            Go to <Link to={{pathname: "/projects/save_search", data: {userDetails: this.state.userDetails, searchLists: this.state.searchLists}}}>My Saved Searches</Link> for edits and more
                        </p>
                    </div>
                </div>
            </div>
        );
    }
});
