/* eslint-disable */
import React from 'react'
import {withRouter} from 'react-router-dom';
import proImage from '../../assets/images/project-image.png'
import rpa from "../../apis/rpa";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Slider from "react-slick";
import Button from 'react-bootstrap/Button';
import _ from "lodash";
import iconBack from "../../assets/images/icons/back-btn.svg";
import ShowProjectMobile from "./ShowProjectMobile";
import iconLarge from "../../assets/images/icons/expand-icon.svg";
import {trackPromise} from "react-promise-tracker";
import ImageUpload from "./ImageUpload";
import editDesciption from '../../assets/images/icons/Icon-edit.svg'
import editImages from '../../assets/images/icons/edit-icon-white.svg';
import saveIcon from '../../assets/images/icons/save-icon.svg';
import {Form, Dropdown} from "react-bootstrap";
import AppSpinner from "./AppSpinner";
import SaveListSection from "./SaveListModule/SaveListSection";
import {sessionService} from "redux-react-session";
import iconDown from "../../assets/images/icons/small-down.svg";
import {withOktaAuth} from "@okta/okta-react";
import {RyanServicesMapping, ArchitectureServices} from "../shared/FilterConstant"
import share from "../../assets/images/icons/share-icon.svg";
import ShareLink from "./shareLink";
import AwardList from "../shared/AwardList";
import {getProjectServices} from "../Actions/ProjectActions"
export default withOktaAuth(withRouter(class ShowProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: undefined,
            editable: 0,
            isUploadModalOpen: false,
            superUserDetails: null,
            projectDescription: '',
            coverImage: undefined,
            coverName: '',
            coverValue: '',
            file: undefined,
            width:null,
            response: undefined,
            err: undefined,
            photoIndex: 0,
            isOpen: false,
            userDetails: null,
            updateTime: '',
            selectedProjects: [],
            readMore: false,
            currentPage: null,
            listId: null,
            ryanServices: RyanServicesMapping,
            architectServices: ArchitectureServices
        }
        this.fileInput = React.createRef()
    }

    async componentDidMount() {
        let projectId = this.props.match.params.projectId
        let currentPage = this.state.currentPage
        let listId = null
        if(!_.isEmpty(this.props.location.state)){
            currentPage =  this.props.location.state.currentPage > 0 ? this.props.location.state.currentPage : 1
            listId = this.props.location.state.listId > 0 ? this.props.location.state.listId : null
        }
        this.setUserDetails()
        this.setState({selectedProjects: [projectId], currentPage: currentPage, listId: listId})
        this.getProject(projectId)
    }
    updateStateDate = (project) => {
        this.getProject(project.id)
    }
    UpdateEditable = () => {
        if (this.state.editable === 0)
        {
            this.setState({editable: 1})
        }
        else {
            this.setState({editable: 0})
        }
    }
    getProject = (projectId) => {
        trackPromise(rpa.get(`/projects/${projectId}`, {
            params: {},
        })
            .then(res => {
                let innerWidth = window.innerWidth
                this.setState({project: res.data, updateTime: res.data.time ,width: innerWidth, projectDescription: res.data.description})
                if (!_.isEmpty(res.data)) {
                    if (_.isEmpty(res.data.images)) {
                        this.setState({coverImage: proImage})
                    } else {
                        if (!_.isEmpty(res.data.cover_url)){
                            this.setState({coverImage: res.data.cover_url, coverName: res.data.cover_name, coverValue: res.data.cover_value})
                        }
                        else{
                            this.setState({coverImage: proImage})
                        }
                    }
                }
            })
            .catch(err => {
                toast.error(err.response.data.error);
            }), 'file-upload-loader');
    }
    checkUserRights = () => {
        if (this.props.authState.isAuthenticated) {
            this.props.authService.getUser().then(
                response => {
                    console.log('Info userInfo Using Endpoints', response);
                    this.setState({userRights: response})
                }
            ).catch(err => console.log('Info userInfo Using Endpoints Err', err))
        }
    }

    updateCurrentImage = (e) => {
        if (this.state.project) {
            this.setState({coverImage: e.target.src, photoIndex: e.target.id})
        }
    }
    projectStats = (value, stats) => {
        if (value !== 'NULL' && parseInt(value) > 0) {
            return (
                <div>
                    <h3>{value}</h3>
                    <span>{stats}</span>
                </div>
            )
        }
    }
    projectUnits = (project) => {
        if (project.project_type_name === 'Multifamily' || project.project_type_name === 'Senior Living') {
            return this.projectStats(project.no_of_keys_beds_units, "Units")
        }
        else if (project.project_type_name === 'Alternative Energy' || project.project_type_name === 'Entertainment' || project.project_type_name === 'Office'  || project.project_type_name === 'Parking' || project.project_type_name === 'Public' || project.project_type_name === 'Sector' || project.project_type_name === '"Public Sector"'){
            return this.projectStats(project.parking_stalls, "Parking Stalls")
        }
        else if (project.project_type_name === 'Hospitality'){
            return this.projectStats(project.no_of_keys_beds_units, "Keys/Units")
        }
        else if (project.project_type_name === 'Mission Critical'){
            return this.projectStats(project.no_of_keys_beds_units, "Const.Duration")
        }
        else if (project.project_type_name === 'R&D' || project.project_type_name === 'Education' || project.project_type_name === 'Religious' || project.project_type_name === 'Industrial' || project.project_type_name === 'Site Development'){
            return this.projectStats(project.site_acres, "Acres")
        } else {
            return ''
        }
    }
    ShowStories = (project) => {
        if (project.no_of_stories !== 'NULL' && !_.isEmpty(project.no_of_stories)) {
            return (
                <div>
                    <h3>
                        {this.state.project.no_of_stories}
                    </h3>
                    <span>Stories</span>
                </div>
            )
        }
    }
    goBackButton = () => {
        if(this.state.currentPage > 0){
            let pathname = this.state.listId > 0 ? `/projects/save_list/${this.state.listId}` : '/projects/search'
            this.props.history.push({
                pathname: pathname, state: {currentPage: this.state.currentPage}
            })
        } else{
            this.props.history.goBack();
        }
    }
    textChanged = (e) => {
        this.setState({projectDescription: e.target.value});
    }
    verifyUserRights = () => {
        return ((!_.isEmpty(this.state.userDetails.department) && this.state.userDetails.department.includes('Marketing')) || (!_.isEmpty(this.state.userDetails.division) && this.state.userDetails.division.includes('Marketing')) || (this.state.superUserDetails && (this.state.superUserDetails.admin || this.state.superUserDetails.super_admin)))
    }
    renderDescription = (des) => {
        if (!_.isEmpty(des)){
            if(this.state.readMore){
                return (des.split('\n').map(str => <p>{str}</p>))
            } else{
                return <p>{des.substring(0,250)}</p>
            }
        }
        else
            return !this.state.editable && <div className="empty-msg-description"><strong></strong></div>
    }
    onUploadModalHide = () => {
        this.setState({isUploadModalOpen: !this.state.isUploadModalOpen})
    }
    onDescriptionFormSubmit = e => {
        e.preventDefault();
        this.descriptionUpdate(this.state.project.id)
    };
    descriptionUpdate = (project_id) => {
        rpa.patch(`/projects/${project_id}/update_description`, {
            description: this.state.projectDescription
        }).then(res => {
            this.setState({projectDescription: res.data.description, editable: 0}, () => toast.success("Description Updated"))
        }).catch(err => {
            toast.error("Unable to Update");
        })
    }
    closeDropMenu = () => {
        document.getElementById('close-save-list').click();
    }
    show_states = (states) => {
        return(
            states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
        )
    }
    setUserDetails = () => {
        if(_.isEmpty(this.state.userDetails)){
            sessionService.loadSession().then(response => {
                this.setState({userDetails: response})
            }).catch(err => {
                console.log('Load Session Not Found Project Detail!')
            })
        }
        if(_.isEmpty(this.state.superUserDetails)) {
            sessionService.loadUser().then(response => {
                this.setState({superUserDetails: response.user})
            }).catch(err => {
                console.log('Not Fount At Show')
            })
        }
    }
    onReadMore = () => {
        this.setState({readMore: !this.state.readMore })
    }
    truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };
    getProjectAwards = (awards) => {
        return( <ul className="list-sub-awards">{awards.map(award => <li>{award.name}</li>)} </ul>)
    }

    handleShareLink = (e) => {
        this.setState({openShare: !this.state.openShare})
    }
    handleShareClose = () => {
        this.setState({openShare: !this.state.openShare})
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        let images = [];
        if (this.state.project) { images = this.state.project.images.map(obj => {return obj.url}); }
        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        };
        const arrow = this.state.readMore? 'upward' : 'downward'
        const linkName = this.state.readMore? 'Read Less' : 'Read More'
        return (
            <>{this.state.project && this.state.width <= 575 ? <ShowProjectMobile data={this.state} updateCurrentImage={this.updateCurrentImage} projectUnits={this.projectUnits} ShowStories={this.ShowStories} goBackButton={this.goBackButton} renderDescription={this.renderDescription} textChanged={this.textChanged} descriptionFormSubmit={this.onDescriptionFormSubmit} updateEditable={this.UpdateEditable} updateStateDate={this.updateStateDate} onUploadModalHide={this.onUploadModalHide} readMore={this.state.readMore} onReadMore={this.onReadMore} closeDropMenu={this.closeDropMenu} project={this.state.project} userDetails={this.state.userDetails} coverImage={this.state.coverImage} width={this.state.width} updateTime={this.state.updateTime} verifyUserRights={this.verifyUserRights} getProjectServices={getProjectServices} getProjectAwards={this.getProjectAwards} handleShareLink = {this.handleShareLink} handleShareClose = {this.handleShareClose} openShare={this.state.openShare}/> :
            <main className="description-page-content">
                <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                <AppSpinner customClass="delete-loader" area="file-upload-loader"/>
                {this.state.project &&
                <div>
                <section className="page-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="top-bar">
                                    <a className="back-link" onClick={this.goBackButton}>
                                        <img src={iconBack} alt="icon" />
                                    </a>
                                    <Button variant="btn-option save-btn share-ind-project">
                                        <img src={share} alt="share" onClick={(e)=> this.handleShareLink(e)}/>
                                    </Button>
                                        {this.state.openShare &&
                                        <ShareLink show={this.state.openShare} handleShareClose={() => this.handleShareClose()}
                                                   projectShare='projectShareLink'
                                                   userDetails={this.state.userDetails} listId={this.state.listId}
                                                   listToken={this.state.listToken} listName={this.state.listName} project={this.state.project}/>
                                    }
                                    <Dropdown className="search-dropdown">
                                        <Dropdown.Toggle  variant="save save-btn" id="close-save-list">
                                            Save Project to List
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="p-0">
                                            <SaveListSection closeDropMenu={this.closeDropMenu} userDetails={this.state.userDetails} selectedProjects={this.state.selectedProjects}/>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="project-description">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-8">
                                <div className="project-gallery">
                                    <div className="image-view">
                                        <img src={this.state.coverImage} alt="photo"/>
                                        <Button variant="large-link" onClick={() => this.setState({ isOpen: true })}>
                                            <img src={iconLarge} alt="icon" />
                                        </Button>
                                        {isOpen && (
                                            <Lightbox
                                                mainSrc={images[photoIndex]}
                                                nextSrc={images[(photoIndex + 1) % images.length]}
                                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                                onMovePrevRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                                })
                                                }
                                                onMoveNextRequest={() =>
                                                this.setState({
                                                    photoIndex: (photoIndex + 1) % images.length,
                                                })
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="gallery-slider">
                                        <div>
                                            <Slider {...settings}>
                                                {this.state.project.images.length > 0 ? this.state.project.images.map((image, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className="img-box mx-auto">
                                                                    <a className="img-link">
                                                                        <img src={image.url}
                                                                             alt="photo"
                                                                             id={index}
                                                                             onClick={(e) => {
                                                                            this.updateCurrentImage(e)
                                                                        }}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    : ''
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-details">
                                    <div className="heading-box">
                                        <div className="heading">
                                            <h5>
                                                Description
                                            </h5>
                                            {this.state.userDetails && this.verifyUserRights() ?
                                                (this.state.editable === 0) &&
                                                <> <Button variant="edit" onClick={this.UpdateEditable}>
                                                        <img src={editDesciption} alt="edit"/>
                                                    </Button>
                                                    <Button variant="upload" onClick={this.onUploadModalHide}>
                                                        <img src={editImages} alt="edit"/>
                                                        Edit Gallery Images
                                                    </Button>
                                                </> : ''}
                                        </div>
                                    </div>
                                    {(this.state.editable === 1) &&
                                    <Form className="form-description-edit" onSubmit={this.onDescriptionFormSubmit}>
                                        <Button variant="edit edit-save" type="submit">
                                            <img src={saveIcon} alt="save"/>
                                        </Button>
                                        <Form.Group>
                                            <Form.Control as="textarea" aria-label="With textarea" value={this.state.projectDescription} onChange={this.textChanged} />
                                        </Form.Group>
                                    </Form>}
                                    {/* <h5>
                                        Description
                                    </h5> */}
                                    {(this.state.editable === 0) && this.renderDescription(this.state.projectDescription)}
                                    {!_.isEmpty(this.state.projectDescription) && this.state.projectDescription.length > 250 ?
                                    <div className="read-more">
                                        <a onClick={()=> this.onReadMore()}>
                                            {linkName}
                                            <span className={`icon ml-2 ${arrow}`}>
                                            <img src={iconDown} alt="" />
                                        </span>
                                        </a>
                                    </div>
                                    : '' }
                                    <ImageUpload isUploadModalOpen={this.state.isUploadModalOpen} onUploadModalHide={this.onUploadModalHide} updateStateDate={this.updateStateDate} images={this.state.project.images} project={this.state.project} updateTime={this.state.updateTime}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6  col-lg-4">
                                <div className="project-info">
                                    <div className="project-title-pane">
                                        <h4>
                                            {_.isEmpty(this.state.project.marketing_project_name) ? this.state.project.project_name : this.state.project.marketing_project_name}
                                        </h4>
                                        { this.state.project.project_city && this.state.project.project_city.includes('Multiple') ?
                                            <span className="location">{this.show_states(this.state.project.states)}</span>
                                            :
                                            <span className="location">
                                                {this.state.project.project_city}, {this.state.project.project_state}
                                            </span>
                                        }
                                    </div>
                                    <div className="project-attributes">
                                        <div className="cards-pane">
                                            {this.ShowStories(this.state.project)}
                                            {this.projectUnits(this.state.project)}
                                            {this.state.project.square_footage_number > 0 &&
                                            <div>
                                                <h3>
                                                    {this.state.project.square_footage}
                                                </h3>
                                                <span>
                                                    Square Feet
                                                </span>
                                            </div>
                                            }
                                        </div>
                                        <div className="info-card">
                                            <h5>
                                                Type of Project
                                            </h5>
                                            <ul>
                                                <li>{this.state.project.project_type_name}</li>
                                            </ul>
                                            <ul>
                                                <li className="list-sub-type">{this.state.project.project_sub_type_name}</li>
                                            </ul>
                                        </div>
                                        <div className="info-card">
                                            <h5>
                                                Ryan Responsibility
                                            </h5>
                                            <ul>
                                                {getProjectServices(this.state.project, RyanServicesMapping, ArchitectureServices)}
                                            </ul>
                                        </div>
                                        { !_.isEmpty(this.state.project.awards) &&
                                            <div className="info-card">
                                                <h5>
                                                    Certifications & Recognition
                                                </h5>
                                                <ul>
                                                    {Object.entries(_.groupBy(this.state.project.awards, awd => awd.issuer))
                                                        .map((awd, index) => {
                                                            return (<AwardList index={index} triggerOn={['hover', 'focus', 'click']} name={awd[0]} issuer={awd[1]} getProjectAwards={this.getProjectAwards}/>)
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                </div>}
            </main>}
        </>
        );
    }
}));
