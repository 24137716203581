import React, { Component } from 'react';
import _ from "lodash";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import iconBack from "../../../assets/images/icons/back-btn.svg";
import rpa from "../../../apis/rpa";
import {sessionService} from "redux-react-session";
import {Redirect} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import AppSpinner from "../../Projects/AppSpinner";
import {trackPromise} from "react-promise-tracker";
import {UserLoginRange} from "../../shared/FilterConstant";
export default (class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            pageCount: null,
            currentPage: 1,
            superUserDetails: null,
            load: false,
            err: undefined,
            filterBy: 'today',
            userListColumns: [{
                dataField: 'id',
                text: 'ID',
                sort: true,
                editable: false,
                headerStyle: {width: '5%'}
            }, {
                dataField: 'email',
                text: 'Username',
                filter: textFilter(),
                sort: true,
                editable: false,
                headerClasses: 'custom-email-label',
                headerStyle: {width: '25%', padding: '3px 0 3px 12px'}
            }, {
                dataField: 'save_lists',
                text: 'SaveLists',
                sort: true,
                editable: false,
                headerStyle: {width: '10%'}

            }, {
                dataField: 'super_admin',
                text: 'SuperAdmin',
                sort: true,
                headerStyle: {width: '10%'},
                editor: {
                    type: Type.SELECT,
                    options: [{
                        value: true,
                        label: 'Yes'
                    }, {
                        value: false,
                        label: 'No'
                    }],
                },
                classes: 'admin-selector'
            }, {
                dataField: 'admin',
                text: 'Admin',
                sort: true,
                headerStyle: {width: '10%'},
                editor: {
                    type: Type.SELECT,
                    options: [{
                        value: true,
                        label: 'Yes'
                    }, {
                        value: false,
                        label: 'No'
                    }],
                },
                classes: 'admin-selector'
            }, {
                dataField: 'last_login',
                text: 'Last Login',
                sort: true,
                editable: false,
                headerStyle: {width: '15%'},
            }, {
                dataField: 'logins',
                text: 'Logins',
                filter: selectFilter({
                    placeholder: 'Filter Logins',
                    withoutEmptyOption: true,
                    options: UserLoginRange,
                    onFilter: this.fetchLoginStats
                }),
                sort: true,
                editable: false,
                headerClasses: 'custom-logins-label',
                headerStyle: {width: '20%', padding: '0 0 3px 8px'},
            }]
        }
    }
    componentDidMount() {
        debugger
        this.getUserList()
        if(_.isEmpty(this.state.superUserDetails)) {
            sessionService.loadUser().then(response => {
                this.setState({superUserDetails: response.user, load: true})
            }).catch(err => {
                console.log('Not Fount At Show')
            })
        }
    }
    fetchLoginStats =  (filterVal, data) => {
        debugger
        if (this.state.filterBy !== filterVal) {
            debugger
            this.setState({filterBy: filterVal}, () => this.fetchUsers(this.state.filterBy, 'selected-filter'))
        }
        return this.state.users
    }
    getUserList = () => {
        if (this.state.users.length === 0) {
            this.fetchUsers(null, 'file-upload-loader')
        }
    }
    fetchUsers = (filteredValue=null, loaderArea) => {
        debugger
        console.log('filterValue', filteredValue)
        console.log('repo', !_.isEmpty(filteredValue) ? filteredValue : 'today')
        console.log('LoadArea', loaderArea)
        trackPromise(rpa.get('/users', {
            params: {
                filteredValue:(!_.isEmpty(filteredValue) ? filteredValue : 'today'),
            },
        }).then(res => {
            let users = res.data.users.map(user => {
                user.email = user.email.split('@')[0]
                user.last_login = new Date(user.last_login).toDateString()
                user.logins = user.logins > 0 ? user.logins : 0
                return user
            })
            users = _.orderBy(users, ['email'], ['asc']);
            debugger
            this.setState({users: users});
        }).catch(err => {}), loaderArea);
    }
    updateUserAdmin = (e, user) => {
        this.UpdateAdmin(e.currentTarget.id, user['admin'])
    }
    UpdateAdmin = (id, dataField, value) => {
        rpa.patch(`/users/${id}`, {
            user: {
                [dataField] : value
            }
        }).then(rr => {
            let users = this.state.users.map(user => {
                if (user.id === rr.data.user.id) {
                    user.admin = rr.data.user.admin;
                    user.super_admin = rr.data.user.super_admin;
                    return user
                } else {
                    return user
                }
            })
            this.setState({users: users}, () => toast.success("Updated"))
        }).catch(err => console.log('Update Admin Response Err', err))
    }
    afterSaveCell = (oldValue, newValue, row, column)=> {
        this.UpdateAdmin(row.uid, column.dataField, !oldValue)
    }
    goBackButton = () => {
        this.props.history.goBack();
    }
    render() {
        return(
            <>
                <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {this.state.load ?
                    this.state.superUserDetails.super_admin ?
                        <div className="saved-list-page user-list-page">
                            <div className="container">
                                <div className="row">
                                    <AppSpinner customClass="delete-loader" area="file-upload-loader"/>
                                    <AppSpinner customClass="delete-loader" area="selected-filter"/>
                                    <div className="col-12">
                                        <div className={"list-top saved-list-detail-page d-flex justify-content-between align-items-center"}>
                                            <div className="msg-pane">
                                                <a className="btn btn-back" onClick={this.goBackButton}>
                                                    <img src={iconBack} alt="back" />
                                                </a>
                                                <h3>
                                                    User Lists
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row projects-pane">
                                    <div className="col-12 col-md-12 col-lg-12" key=''>
                                        <BootstrapTable keyField='id' data={ this.state.users } columns={ this.state.userListColumns } bootstrap4={true} filter={ filterFactory() } cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: this.afterSaveCell}) } wrapperClasses="table-responsive"/>
                                    </div>
                                </div>
                                <div className="pagination-box">
                                {/*    <ReactPaginate className="pagination" id="react-paginate"*/}
                                {/*        // labels*/}
                                {/*                   previousLabel={''}*/}
                                {/*                   nextLabel={''}*/}
                                {/*                   breakLabel={'…'}*/}
                                {/*        // classNames*/}
                                {/*                   containerClassName={'react-paginate'}*/}
                                {/*                   previousClassName={'previous'}*/}
                                {/*                   breakClassName={'break-me'}*/}
                                {/*                   nextClassName={'next'}*/}
                                {/*                   pageClassName={'page'}*/}
                                {/*                   activeClassName={'active'}*/}
                                {/*                   pageLinkClassName={'page-link'}*/}
                                {/*                   disabledClassName={'disabled'}*/}
                                {/*        // functionality*/}
                                {/*                   pageCount={this.state.pageCount}*/}
                                {/*                   marginPagesDisplayed={2}*/}
                                {/*                   pageRangeDisplayed={5}*/}
                                {/*                   onPageChange={this.handlePageClick}*/}
                                {/*                   disableInitialCallback={true}*/}
                                {/*                   forcePage={this.state.currentPage - 1}*/}
                                {/*    />*/}
                                {/*    <span className="current-page">*/}
                                {/*    Page {this.state.currentPage} of {this.state.pageCount? this.state.pageCount : this.state.currentPage }*/}
                                {/*</span>*/}
                                </div>
                            </div>
                        </div>
                : <Redirect to="/" /> : ''
                }
            </>
        );
    }
});
