import React,{Component} from 'react';
import _ from "lodash";
import ReactPaginate from "react-paginate";
import ShareableProjectCard from "./ShareableProjectCard";

export default (class ShareAbleProjectsList extends Component {
    render () {
        return (

            <div className="shared-projects-section">
                <div className="row shared-projects-list">
                        {(!_.isEmpty(this.props.list) && this.props.projects.length > 0) ? this.props.projects.map(project => {
                            return (
                                <div className="col-12 col-md-12 col-lg-6" key={project.id}>
                                    <ShareableProjectCard
                                        id={project.id}
                                        projectName={_.isEmpty(project.marketing_project_name) ? project.project_name : project.marketing_project_name}
                                        projectCity={project.project_city}
                                        projectState={project.project_state}
                                        sector={project.project_type_name}
                                        images={project.images}
                                        list={this.props.list}
                                        currentPage={this.props.currentPage}
                                        multipleStates = {project.states}
                                />
                                </div>);
                        }) : <h6 className="no-result">No Project</h6>}
                </div>
                <div className="row">
                    <div className="col-12">
                            <div className="pagination-box">
                                <ReactPaginate className="pagination" id="react-paginate"
                                    // labels
                                            previousLabel={''}
                                            nextLabel={''}
                                            breakLabel={'…'}
                                    // classNames
                                            containerClassName={'react-paginate'}
                                            previousClassName={'previous'}
                                            breakClassName={'break-me'}
                                            nextClassName={'next'}
                                            pageClassName={'page'}
                                            activeClassName={'active'}
                                            pageLinkClassName={'page-link'}
                                            disabledClassName={'disabled'}
                                    // functionality
                                            pageCount={this.props.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.props.handlePageClick}
                                            disableInitialCallback={true}
                                            forcePage={this.props.currentPage - 1}
                                />
                                <span className="current-page">
                                                Page {this.props.currentPage} of {this.props.pageCount? this.props.pageCount : this.props.currentPage }
                                            </span>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
});
