import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';

export default withOktaAuth(class Hero extends Component {
    render() {
        return(
            <section className="section-hero">
                <h1 className="web-title">
                    Creating spaces that bring your story to life.
                </h1>
                <p className="slogon">
                    EVERY PROJECT HAS A STORY BEYOND THE STRUCTURE
                </p>
            </section>
        ); 
        }
});
