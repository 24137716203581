import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import LoginForm from "./LoginForm";
import {withOktaAuth} from '@okta/okta-react';
import {Spinner} from "react-bootstrap";

export default withOktaAuth(class SignIn extends Component {
    render() {
        if (this.props.authState.isPending) {
            return (
                <div className="loader-general">
                    <div className="content content-spinner"><Spinner animation="grow" variant="success"/></div>
                </div>
            )
        }
        else{
            return (this.props.authState.isAuthenticated && !this.props.authState.isPending) ?
                <Redirect to={{pathname: '/'}}/> :
                <LoginForm issuer={this.props.issuer}/>;
        }
    }
});
