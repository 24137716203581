import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ImageUploading from 'react-images-uploading';
import _ from "lodash";
import rpa from "../../apis/rpa";
import {toast} from "react-toastify";
import uploadImg from '../../assets/images/icons/add-photo-icon.svg';
import closeIcon from '../../assets/images/icons/close-circle.svg';
import deleteIcon from '../../assets/images/icons/delete-icon.svg';
import infoIcon from '../../assets/images/icons/info-green.svg';
import {trackPromise} from "react-promise-tracker";

export default (class ImageUpload extends Component{
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            project: undefined,
            coverImage: '',
            coverUrl: '',
            coverValue: '',
            isSubmit: false,
            show: '',
            updateTime: '',
            coverSelected: true
        }
        this.imageRadioRef = React.createRef();
    }
    MaxNumber = 69;
    componentDidMount() {
        this.setState({project: this.props.project, coverUrl: this.props.project.cover_url, coverImage: this.props.project.cover_name, coverValue: this.props.project.cover_value, images: this.props.images, updateTime: this.props.updateTime})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateTime !== prevState.updateTime) {
            this.setState({project: this.props.project, coverUrl: this.props.project.cover_url, coverImage: this.props.project.cover_name, coverValue: this.props.project.cover_value, images: this.props.images, updateTime: this.props.updateTime})
        }
    }

    onChange = (imageList, addUpdateIndex) => {
        if(!_.isEmpty(addUpdateIndex)){
            for(let i = 0; i < addUpdateIndex.length; i++){
                imageList[addUpdateIndex[i]]["position"] = 99
            }
        }
        this.setState({images: imageList});
    };
    onFormSubmitUpload = (e) => {
        let selectedCover = document.querySelector(".cover-check input[type='radio']:checked")
        if(_.isEmpty(selectedCover) && this.state.images.length > 0){
            this.setState({coverSelected: false})
        }else{
            this.filesUploadSubmit()
            this.setState({coverSelected: true})
        }
    }
    filesUploadSubmit = () => {
        let formData = new FormData();
        let { images } = this.state;
        formData.append(`cover`, this.state.coverImage)
        let images_position = []
        for (let i = 0; i < images.length; i++) {
            let file = images[i];
            if (file.id) {
                formData.append(`project[images][${i}]`, file.id);
                images_position.push({id: file.id, position: file.position})
            } else {
                formData.append(`files[]`, file.file, file.file.name)
                images_position.push({name: file.file.name, position: file.position})
            }
        }
        formData.append(`project[images][position]`, JSON.stringify(images_position))
        this.filesUpload(formData, this.props.project.id)
        this.handleClose();
    }
    filesUpload =  (formData, project_id) => {
        this.props.onUploadModalHide()
        trackPromise(rpa.put(`/projects/${project_id}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        }).then(res => {
                if (!_.isEmpty(res.data)) {
                    this.setState({project: res.data, coverImage: res.data.cover_url, coverName: res.data.cover_name, coverValue: res.data.cover_value, isSubmit: true, images: res.data.images}, () => this.props.updateStateDate(res.data, res.data.cover_url, res.data.cover_name, res.data.cover_value ))
                }
                toast.success("Gallery Updated");
            }).catch(err => {
                    // this.setState({err: err, file: undefined})
                    toast.error("Problem with the upload !");
                }
            ), 'file-upload-loader')
    };
    updateCoverImage = (e) => {
        let currentIndex = parseInt(e.currentTarget.ariaLabel)
        let current_cover_image = this.state.images[currentIndex]
        if (_.isEmpty(current_cover_image.name)){
            this.setState({coverImage: current_cover_image.file.name, coverValue: true})
        } else{
            this.setState({coverImage: current_cover_image.name, coverValue: true})
        }
        this.setState({coverSelected: true})
        this.setCoverPosition(currentIndex)
    }

    handleClose = () => this.setState({
        show: false
    });
    handleShow = () => this.setState({
        show: true
    });
    hideAndResetState = () => {
        this.props.onUploadModalHide()
        if((this.state.images.length !== this.props.images.length) && !this.state.isSubmit) {
            this.setState({images: this.props.images})
        }
    }
    updatePosition = (e) => {
        let positions = document.querySelectorAll(".img-position");
        for (let i = 0; i < positions.length; i++) {
            let selectValue = positions[i].value
            if(selectValue == e.target.value && positions[i].id !== e.target.id){
                positions[i].value = '99';
                positions[i].dispatchEvent(new Event('select', {bubbles: true}));
                this.setState(prevState => {
                    let images = Object.assign([], prevState.images);
                    images[positions[i].id]["position"] = 99
                    return {images};
                })
            }
        }
        this.setState(prevState => {
            let images = Object.assign([], prevState.images);
            images[e.target.id]["position"] = e.target.value
            return {images};
        })
    }
    setCoverPosition = (currentIndex) => {
        let positions = document.querySelectorAll(".img-position");
        // setting Slot 1 as position and placeholder for cover img
        if(positions.length > 0){
            positions[currentIndex].value = '1';
            positions[currentIndex].disabled = true
            positions[currentIndex].dispatchEvent(new Event('select', {bubbles: true}));
            this.setState(prevState => {
                let images = Object.assign([], prevState.images);
                images[currentIndex]["position"] = "1"
                return {images};
            })
            // this.setState({coverValue: true})
            // disabling slot 1 for other images if cover is selected
            for (let i = 0; i < positions.length; i++) {
                if(positions[i].id != currentIndex){
                    let options = positions[i].options
                    for (let i = 0; i < options.length; i++) {
                        (options[i].value == "1")
                            ? options[i].disabled = true
                            : options[i].disabled = false ;
                    }
                    if(positions[i].value == "1"){
                        positions[i].value = '99';
                        positions[i].disabled = false
                        positions[i].dispatchEvent(new Event('select', {bubbles: true}));
                    }
                    this.setState(prevState => {
                        let images = Object.assign([], prevState.images);
                        images[currentIndex]["position"] = "1"
                        images[i]["position"] = positions[i].value
                        return {images};
                    })
                }
            }
        }
    }
    onImageRemove = (index) => {
        let images = Object.assign([], this.state.images);
        let coverImageName = this.state.coverImage
        if (Array.isArray(index)) {
            index.forEach((i) => {
                images.splice(i, 1);
            });
        } else {
            if(images[index].name == coverImageName){
                this.setState({coverUrl: '', coverValue:false, coverImage:''})
            }
            images.splice(index, 1);
        }
        this.setState({images: images})
    }
    render () {
        return (
            <div className="App">
                <Modal className="upload-img-modal" show={this.props.isUploadModalOpen} size="lg" onHide={this.hideAndResetState}>
                    <Modal.Header>
                    <Modal.Title>
                        Edit Gallery
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="head-box d-flex justify-content-end">
                            <Button variant="close" onClick={this.props.onUploadModalHide}>
                                <img src={closeIcon} alt="close"/>
                            </Button>
                        </div>
                        <h5 className="heading">Modify Project Photos</h5>

                        <ImageUploading
                                multiple
                                value={this.state.images}
                                onChange={this.onChange}
                                maxNumber={this.MaxNumber}
                                dataURLKey="url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemove,
                                }) => (

                                    <div className="upload__image-wrapper">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-9">
                                                <div className="gallery-box">
                                                    {imageList.map((image, index) => {
                                                        return(<div key={index} className="image-item">
                                                            <div className="head-box">
                                                                { _.isEmpty(image.name)
                                                                    ? <Form.Check type="radio" label="Cover" className="cover-check" aria-label={index} checked={this.state.coverValue && this.state.coverImage === image.file.name} onClick={this.updateCoverImage} name={image.name} id={image.name}/>
                                                                    : <Form.Check type="radio" label="Cover" className="cover-check" aria-label={index} checked={this.state.coverValue && this.state.coverImage === image.name} onClick={this.updateCoverImage} name={image.name} id={image.name}/>
                                                                }
                                                                <div className="image-item__btn-wrapper">
                                                                    <Button variant="delete" onClick={() => {
                                                                        this.onImageRemove(index);
                                                                    }}>
                                                                        <img src={deleteIcon} alt="x"/>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <img src={image['url']} alt="" width="100"/>
                                                            {/*{this.state.coverValue && this.state.coverImage === image.name?  '' :*/}
                                                                <Form.Control as="select" className="img-position" disabled={this.state.coverValue && ((_.isEmpty(image.name) && this.state.coverImage === image.file.name) || (!_.isEmpty(image.name) && this.state.coverImage === image.name))} onChange={this.updatePosition} id={`${index}`}>
                                                                <option value='99'>Select</option>
                                                                <option value='1' selected = {`${image.position == 1 ? "selected" : ""}`} disabled={this.state.coverValue}>{this.props.screenWidth <= 575 ? '1st Image' : 'Set as 1st Position'}</option>
                                                                <option value='2' selected = {`${image.position == 2 ? "selected" : ""}`}>{this.props.screenWidth <= 575 ? '2nd Image' : 'Set as 2nd Position'}</option>
                                                                <option value='3' selected = {`${image.position == 3 ? "selected" : ""}`}>{this.props.screenWidth <= 575 ? '3rd Image' : 'Set as 3rd Position'}</option>
                                                            </Form.Control>
                                                            {/*}*/}
                                                        </div>)
                                                    })}
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-3 upload-col">
                                                <div className="btn-box">
                                                    <Button variant="upload" onClick={onImageUpload}>
                                                        <img src={uploadImg} alt="upload" />
                                                    </Button>
                                                    <span className="btn-lable d-inline-block d-md-none">
                                                        Upload Photo
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>
                    </Modal.Body>
                    <Modal.Footer>
                        <p>
                            <span className="pb-1">
                                <img className="mr-1" src={infoIcon} alt="into"/>
                            </span>
                            You can select any of 3 images as preferred slots</p>
                        {!this.state.coverSelected && <span className="mr-1 text-danger">Please select a cover image.</span>}
                        <Button type={"button"} variant="primary" onClick={this.onFormSubmitUpload}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
});

