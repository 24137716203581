import React, {Component} from 'react';
import {Route, withRouter, Switch} from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import Login from "../components/Login/Login";
import Home from "../components/Home";
import AppContainer from "../components/layouts/AppContainer";
import config from './Config';
import ProjectList from "../components/Projects/ProjectList";
import ShowProject from "../components/Projects/ShowProject";
import ProjectSearch from "../components/Projects/ProjectSearch";
import SavedSearchesSide from "../components/Projects/SearchModule/SavedSearchesSide";
import ShareDetailPage from "../components/shareModule/ShareAblePage";
import ShareProject from "../components/ShareProjectModule/ShareProject";
import SavedListPage from "../components/NewSavedListModule/SavedListPage";
import SavedListProjects from "../components/NewSavedListModule/SavedListProjects";
import ShowShareableProject from "../components/shareModule/ShowShareableProject";
import UserList from "../components/Admin/Users/UserList";
import UserActivity from "../components/Admin/Users/UserActivity";
import {clearSessionAndStorage} from "../components/Actions/AppActions";

export default withRouter(class Routes extends Component {
    constructor(props) {
        super(props);
        this.onAuthRequired = this.onAuthRequired.bind(this);
    }

    onAuthRequired() {
        clearSessionAndStorage()
        this.props.history.push('/login');
    }

    render() {
        return (
            <Security
                {...config}
                onAuthRequired={this.onAuthRequired}
            >
                <Switch>
                    <Route path='/shareable' exact={true}  component={props => <ShareDetailPage {...props}/>} />
                    <Route path='/shareable/:token/project/:projectId' exact={true} component={props => <ShowShareableProject {...props}/>}/>
                    <Route path='/shareable_project/:token' exact={true} component={props => <ShareProject {...props}/>}/>
                    <Route>
                        <AppContainer>
                            <SecureRoute path='/' exact={true} component={Home}/>
                            <SecureRoute path='/search' exact={true} component={ProjectSearch}/>
                            <SecureRoute path='/projects' exact={true} component={ProjectList}/>
                            <SecureRoute path='/users' exact={true} component={UserList}/>
                            <SecureRoute path='/activity' exact={true} component={UserActivity}/>
                            <SecureRoute path='/project/:projectId' exact={true} component={ShowProject}/>
                            <SecureRoute path='/projects/search' exact={true} component={ProjectList}/>
                            <SecureRoute path='/projects/save_search' exact={true} component={SavedSearchesSide}/>
                            <SecureRoute path='/projects/save_list' exact={true} component={SavedListPage}/>
                            <SecureRoute path='/projects/save_list/:listId' exact={true} component={SavedListProjects}/>
                            <Route path='/login' exact={true} render={() => <Login issuer={config.issuer}/>}/>
                            <Route path='/login/callback' exact={true} component={LoginCallback}/>
                        </AppContainer>
                    </Route>
                </Switch>
            </Security>
        );
    }
});
