import React,{Component} from 'react';
import _ from "lodash";
import {trackPromise} from "react-promise-tracker";
import rpa from "../../apis/rpa";
import {toast} from "react-toastify";
import ShareAbleProjectsList from "./ShareAbleProjectsList";
import AppSpinner from "../Projects/AppSpinner";
import SharedHeader from "./SharedHeader";
import SharedAblePageTop from "./ShareAblePageTop";
import SharedFooter from "./SharedFooter";

export default (class ShareAblePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: null,
            pageCount: null,
            currentPage: 1,
            token: '',
            projects: [],
            sortByData: [false, false, false, false, false, false, false, false]
        }
    }
    componentDidMount() {
        var url = new URL(window.location.href);
        var token = url.searchParams.get("token")
        let currentPage = this.state.currentPage
        if(!_.isEmpty(this.props.location.state)){
            currentPage =  this.props.location.state.currentPage > 0 ? this.props.location.state.currentPage : 1
        }
        if(!_.isEmpty(token)){
            this.setState({token:token, currentPage: currentPage}, ()=> this.getProjects(token));
        }
    }
    getProjects = (token) => {
        trackPromise(rpa.get(`show_shareable_public?token=${token}&page=${this.state.currentPage}`, {
        }).then(response => {
            if(!_.isEmpty(response.data.save_list)){
                this.setState({list: response.data.save_list, projects: response.data.save_list.projects, pageCount: response.data.save_list.page_count})
            } else{
                toast.error(response.data.errors)
            }
        }).catch(err => {
            toast.error(err.message);
        }), 'share-list-spinner');
    }
    handlePageClick = (event) => {
        console.log("page clicked",event)
        this.setState({currentPage: event.selected + 1}, () => this.getProjects(this.state.token))
        // this.setState({isSaveButtonDisabled: true})
    }
    updateProjectsByOrder = (projects, sortByData) => {
        this.setState({projects: projects, sortByData: sortByData})
    }
    render () {
        return (
            <div className="share-page">
                <SharedHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <AppSpinner area="share-list-spinner"/>
                            {this.state.list && <SharedAblePageTop projects={this.state.projects} list={this.state.list}/> }
                            {/* <div className="shared-top">
                                {this.state.list &&
                                <ShareAblePageTop list={this.state.list} projects={this.state.projects} updateProjectsByOrder={this.updateProjectsByOrder} sortPaginationData={this.state.sortByData}/>}
                            </div> */}
                        </div>
                        {/* <div className="col-12 col-md-12 col-lg-11 mx-auto">
                            <ShareAbleProjectsList list={this.state.list} projects={this.state.projects} currentPage={this.state.currentPage} pageCount={this.state.pageCount} handlePageClick={this.handlePageClick}/>
                        </div> */}
                    </div>
                    <ShareAbleProjectsList list={this.state.list} projects={this.state.projects} currentPage={this.state.currentPage} pageCount={this.state.pageCount} handlePageClick={this.handlePageClick}/>
                </div>
                <SharedFooter/>
            </div>
        );
    }
});
