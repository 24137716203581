import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
const AwardList = props => {
    const renderTooltip = props => (
        <Tooltip {...props}>{props}</Tooltip>
    )
    return (
        <li key={props.index} className='l-lower-roman-d'>
            <OverlayTrigger trigger={props.triggerOn} placement="top" overlay={renderTooltip(props.name)}>
                <span className='awards-list-issuer'>{props.name}</span>
            </OverlayTrigger>
            {props.getProjectAwards(props.issuer)}
        </li>)
}
export default AwardList;
