import axios from 'axios';
import secret from "../Config/Secret";

export default axios.create({
  baseURL: 'https://rpa-aoftv.ondigitalocean.app/api/v1',
  headers: {
    'Key': secret.KEY
  }
});

//local changes
// export default axios.create({
//   baseURL: 'http://10.28.84.192:5000/api/v1',
//   headers: {
//     'Key': secret.KEY
//   }
// });

//staging changes
// export default axios.create({
//   baseURL: 'https://rpa-aoftv.ondigitalocean.app/api/v1',
//   headers: {
//     'Key': secret.KEY
//   }
// });

// For NgRock
// export default axios.create({
//   baseURL: 'https://6b075ad38190.ngrok.io/api/v1',
//   headers: {
//     'Key': secret.KEY
//   }
// });
