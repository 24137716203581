import React from 'react'
import {withOktaAuth} from "@okta/okta-react";
import Header from "../shared/Header";
import {Footer} from "./Footer";

export default withOktaAuth(class DesktopContainer extends React.Component {
    render() {
        const {children} = this.props
        return (
            <div>
                <Header/>
                <section className="content">
                    <div className="cust-wrapper">
                        <div style={{flex: 1}}>
                            {children}
                        </div>
                    </div>
                </section>
                <Footer/>

            </div>
        )
    }
})
