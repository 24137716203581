import React from 'react';
import {Spinner} from "react-bootstrap";
import { usePromiseTracker } from "react-promise-tracker";

const MenuAppSpinner = props => {
    const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});
    return (
        promiseInProgress && <div className={props.customClass}>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="success"
            />
        </div>
    );
}
export default MenuAppSpinner;
