import React from 'react';
import {Button,Modal} from "react-bootstrap";
import close from '../../../assets/images/icons/close-circle.svg';
import warning from '../../../assets/images/icons/alert-triangle.svg';
import {trackPromise} from "react-promise-tracker";
import rpa from "../../../apis/rpa";
import {toast} from "react-toastify";
import AppSpinner from "../AppSpinner";
const DeleteModal = (props) => {

    const deleteSavedList = () => {
        props.onDeleteHide()
        trackPromise(rpa.delete(`/users/${props.userDetails.sub}/save_lists/${props.id}`, {
            params: {
            }
        }).then(response => {
            if(response.data.success){
                // props.onDeleteHide()
                props.getSaveLists()
                // props.updateSelectedList(response.data.save_list)
            }
            else {
                toast.error(response.data.errors.name[0])
            }
        }).catch(err => {
            toast.error(err.message);
            console.log('Delete List Error', err)
        }), 'delete-project-save-list');
    }
    const show = props.isOpen
        return (
          <>
            <AppSpinner customClass="delete-saved-list-loader" area="delete-project-save-list"/>
            <Modal show={show} onHide={props.onDeleteHide} size="sm" className="delete-modal" centered>
              <Modal.Header>
                <Modal.Title>Delete List Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="msg-box">
                      <div className="close-box text-right">
                        <Button variant="close" onClick={props.onDeleteHide}>
                          <img src={close} alt="" />
                        </Button>
                      </div>
                      <div className="media">
                          <img src={warning} alt="" />
                      </div>
                      <p>
                        Are you sure you want to delete <br/>
                          {`"${props.listName}"`}
                      </p>
                      <Button variant="primary delete" onClick={deleteSavedList}>
                        Yes, Delete
                      </Button>
                  </div>
              </Modal.Body>
            </Modal>
          </>
        );
};
export default DeleteModal;
