export const ryanInvolvement = [
    {label: 'A + E Architecture', value: 'architecture_name', checked: false},
    {label: 'A + E Civil', value: 'civil_engineering', checked: false},
    {label: 'A + E Landscape', value: 'landscape_engineering', checked: false},
    {label: 'A + E Interior', value: 'interior_engineering', checked: false},
    {label: 'Capital Markets', value: 'capital_markets', checked: false},
    {label: 'Construction', value: 'construction', checked: false},
    {label: 'Development', value: 'development', checked: false},
    // {label: 'Preconstruction', value: 'pre_construction', checked: false},
    {label: 'Ryan Building Services', value: 'rbs', checked: false},
    {label: 'Real Estate Management', value: 'real_estate_management', checked: false},
    // {label: 'Virtual Design & Const.', value: 'vdc', checked: false},
    {label: 'Yellow Truck Creative', value: 'yellow_truck_creative_name', checked: false}
]
export const rangCompletionYear = [
    {label: 'Last 2 years', value: '2', checked: false},
    {label: 'Last 5 years', value: '5', checked: false},
    {label: 'Last 10 years', value: '10', checked: false},
    {label: 'Active', value: 'all', checked: false},
]
export const ActivityRange = [
    {label: 'Today', value: 'today', checked: false},
    {label: 'This Week', value: 'this_week', checked: false},
    {label: 'Last 60 days', value: 'sixty_days', checked: false},
    {label: 'This Year', value: 'this_year', checked: false},
    {label: 'All', value: 'all_time', checked: false},
]
export const ArchitectureServices = {
    landscape_engineering: ['A+E Landscape'],
    interior_engineering: ['A+E Interiors'],
    civil_engineering: ['A+E Civil'],
    architecture_name: ['A+E Architecture'],
}
export const RyanServicesMapping = {
    construction: ['Construction'],
    rbs: ['Ryan Building Services'],
    development: ['Development'],
    capital_markets: ['Capital Markets'],
    // pre_construction: ['Preconstruction'],
    real_estate_management: ['Real Estate Management'],
    // vdc: ['Virtual Design & Const.'],
    yellow_truck_creative_name: ['Yellow Truck Creative']
}
export const layoutViews = {
    1: 'gridView',
    2: 'listView',
}
export const UserLoginRange = {
    today: 'Today',
    this_week: 'This Week',
    sixty_days: 'Sixty Days',
    this_year: 'This Year',
    all_time: 'All Time',
}
