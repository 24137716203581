import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Badge from '../../assets/images/logo-main.svg';

export default (class SharedHeader extends Component {

    componentDidMount(){
        document.title = 'Ryan Companies'
    }
    render() {
        return (
            <header className="shared-header">
                <div className="container">
                    <Navbar>
                        <Navbar.Brand to="/">
                            <img className="img-fluid" src={Badge} alt="RPA"/>
                        </Navbar.Brand>
                    </Navbar>
                </div>
            </header>
        );
    }
});
