import React from 'react'
import {Badge, Form, ListGroup} from "react-bootstrap";
import proImage from '../../assets/images/project-image.png';
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from "lodash";
function image_path (coverUrl) {
    if(!_.isEmpty(coverUrl)) {
        return coverUrl
    }else{
        return proImage
    }
}
const show_states = (states) => {
    return(
        states.length > 5 ? 'Multiple' : states.map(s => {return s.name}).join(", ")
    )
}
const ListView = ({ id, projectName, projectCity, projectState, onProjectCheckBoxChange, coverUrl, currentPage, multipleStates, sector}) => {
    return (
        <Link to={{pathname: `/project/${id}`, state: {currentPage: currentPage} }} className="project-card-link">
            {/*<div className="card project-card">*/}
            {/*    <div className="img-pane media">*/}
            {/*        <LazyLoadImage*/}
            {/*            className="card-img-top"*/}
            {/*            alt="Project Image"*/}
            {/*            effect="blur"*/}
            {/*            src={image_path(coverUrl)} />*/}
            {/*    </div>*/}
            {/*    <div className="card-body">*/}
            {/*        <h4 className="card-text project-sector">*/}
            {/*            {projectName}*/}
            {/*        </h4>*/}
            {/*        <small>{sector}</small>*/}
            {/*        <div className="check-label-pane d-flex justify-content-between">*/}
            {/*            { projectCity && projectCity.includes('Multiple') ?*/}
            {/*                <small>{show_states(multipleStates)}</small>*/}
            {/*                :*/}
            {/*                <small>{projectCity}, {projectState}</small>*/}
            {/*            }*/}
            {/*            <Form.Group className="mb-0" controlId={`gridCheck_${id}`}>*/}
            {/*                <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>*/}
            {/*            </Form.Group>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<ListGroup className="my-2">*/}
            {/*    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">*/}
            {/*        <div className="ms-2 me-auto">*/}
            {/*            <Form.Group className="mb-0" controlId={`gridCheck_${id}`}>*/}
            {/*                <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>*/}
            {/*            </Form.Group>*/}
            {/*            <div className="fw-bold">Subheading</div>*/}
            {/*            Cras justo odio*/}
            {/*        </div>*/}
            {/*        <Badge variant="primary" pill>14</Badge>*/}
            {/*    </ListGroup.Item>*/}
            {/*</ListGroup>*/}

            <div className="list-group list-view-link">
                <a href="#" className="list-group-item list-group-item-action">
                    {/*<div className="check-label-pane d-flex justify-content-between">*/}
                    {/*    <h5 className="mb-1">List group item heading</h5>*/}
                    {/*    <small>Amazon</small>*/}
                    {/*    <Form.Group className="mb-0" controlId={`gridCheck_${id}`}>*/}
                    {/*        <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>*/}
                    {/*    </Form.Group>*/}
                    {/*</div>*/}
                    {/*<div className="justify-content-between">*/}
                    {/*    <Form.Group className="d-flex" controlId={`gridCheck_${id}`}>*/}
                    {/*        <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>*/}
                    {/*    </Form.Group>*/}
                    {/*    <div className="d-flex">*/}
                    {/*        <h5 className="mb-1">List group item heading</h5>*/}
                    {/*        <span className="state">Arizona</span>*/}
                    {/*        <span className="state">Hospitality</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="project-list-view">
                        <Form.Group className="" controlId={`gridCheck_${id}`}>
                            <Form.Check type="checkbox" label="" className="projectCheckbox" id={id} onClick={(e) => {e.stopPropagation();}} onChange={onProjectCheckBoxChange}/>
                        </Form.Group>
                        <div className="d-flex list-name-state">
                            <h4>{projectName}</h4>
                            { projectCity && projectCity.includes('Multiple') ?
                                <small className="state">{show_states(multipleStates)}</small>
                                :
                                <small className="state">{projectCity}, {projectState}</small>
                            }
                            <small className="sector">{sector}</small>
                        </div>
                    </div>
                </a>
            </div>
        </Link>
    );
}
export default ListView;
