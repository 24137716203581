import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import _ from "lodash";
import {ToastContainer} from "react-toastify";
import {sessionService} from "redux-react-session";
import ReactPaginate from "react-paginate";
import AppSpinner from "../Projects/AppSpinner";
import ProjectCardDelete from "../Projects/ProjectCardDelete";
import SavedListTop from "./SavedListTop";
import {trackPromise} from "react-promise-tracker";
import rpa from "../../apis/rpa";
import SearchParams from "../CustomHooks/SearchParams";
export default withOktaAuth(class SavedSearchesSide extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageCount: null,
            currentPage: 1,
            load: true,
            userDetails: null,
            response: undefined,
            err: undefined,
            list: null,
            projects: [],
            sortByData: {completionYearOldest: false, completionYearNewest: false, squareFeetSmallest: false, squareFeetLargest: false, AToZ: false, ALZ: false, unitsSmallest: false, unitsLargest: false},
            sortKey: undefined
        }
    }
    componentDidMount() {
        let currentPage = this.state.currentPage
        if(!_.isEmpty(this.props.location.state)){
            currentPage =  this.props.location.state.currentPage > 0 ? this.props.location.state.currentPage : 1
        }
        this.setState({currentPage: currentPage})
        if(_.isEmpty(this.state.sortKey)){
            this.getSortData();
        }
        this.setUserDetails()
    }
    getProjects = () => {
        let listId = this.props.match.params.listId
        let currentPage = this.state.currentPage
        trackPromise(rpa.get(`/users/${this.state.userDetails.sub}/save_lists/${listId}?page=${currentPage}`, {
            params: {
                sortBy: this.state.sortKey
            }
        }).then(res => {
                this.setState({list: res.data, projects: res.data.projects, load: true, pageCount: res.data.page_count, currentPage: res.data.page, projectsCount: res.data.projects_count})
            }).catch(err => {
                this.setState({err: err, load: false})
            }), 'list-projects-loader');
    }
    setUserDetails = () => {
        if(_.isEmpty(this.state.userDetails)){
            sessionService.loadSession().then(response => {
                this.setState({userDetails: response}, ()=> this.getProjects())
            }).catch(err => {
            })
        }
    }
    UpdateCurrentListOnProjectDelete = (currentList) => {
        let projects = this.state.projects
        if (this.state.list.id === currentList.id){
            projects = currentList.projects
        }
        this.setState({projects: projects, load: true})
    }
    handlePageClick = (event) => {
        this.setState({currentPage: event.selected + 1}, () => this.getProjects())
    }
    updateProjectsBySavedListOrder = () => {
        SearchParams.setValue('sortKey', JSON.stringify(this.state.sortKey))
        SearchParams.setValue('sortByData', JSON.stringify(this.state.sortByData))
        this.getProjects();
    }
    onChangeSortBy = (e) => {
        let sortName = e.target.ariaLabel
        let sortValue = e.target.checked
        let sortData = this.state.sortByData
        let sortKey = {}
        Object.keys(sortData).forEach(function(key) {
            sortData[key] = false
        });
        if(sortValue){
            sortData[sortName] = true
            sortKey = this.setSortKey(sortName)
        }
        this.setState({sortByData: sortData, sortKey: sortKey})
    }
    setSortKey = (sortName) => {
        switch (sortName){
            case 'completionYearOldest':
                return {'substantial_completion_date': 'desc'}
            case 'completionYearNewest':
                return {'substantial_completion_date': 'asc'}
            case 'squareFeetSmallest':
                return {'square_footage_number': 'asc'}
            case 'squareFeetLargest':
                return {'square_footage_number': 'desc'}
            case 'AToZ':
                return {'marketing_project_name': 'asc', 'project_name': 'asc'}
            case 'ALZ':
                return {'project_state': 'asc'}
            case 'unitsSmallest':
                return {'no_of_keys_beds_units': 'asc'}
            case 'unitsLargest':
                return {'no_of_keys_beds_units': 'desc'}
            default:
                return {}
        }
    }
    getSortData = () => {
        let sortBy = SearchParams.getValue('sortKey')
        let sortByData = SearchParams.getValue('sortByData')
        if (!_.isEmpty(sortBy) && !_.isEmpty(sortByData)) {
            this.setState({sortKey: sortBy, sortByData: sortByData})
        }
    }
    render() {
        let load = this.state.load
        return (
            <main className="saved-list-page">

                <AppSpinner customClass="delete-loader" area="list-projects-loader"/>
                <ToastContainer position="top-center" autoClose={1200} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {/*{this.state.load===false && <Message color='red' header={this.state.err}/>}*/}
                {load &&
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.list &&
                            <SavedListTop listPage={false} list={this.state.list} projects={this.state.projects} updateProjectsBySavedListOrder={this.updateProjectsBySavedListOrder} sortPaginationData={this.state.sortByData} onChangeSortBy={this.onChangeSortBy}/>
                            }
                        </div>
                        <div className="col-12">
                            <div className="project-list-pane project-list-pane-mobile">

                                    <div className="row">
                                        {(!_.isEmpty(this.state.projects) && this.state.projects.length > 0) ? this.state.projects.map(project => {
                                                return (
                                                    <div className="col-12 col-md-6 col-lg-4" key={project.id}>
                                                        <ProjectCardDelete
                                                            id={project.id}
                                                            onProjectCheckBoxChange={this.onProjectCheckBoxChange}
                                                            projectName={_.isEmpty(project.marketing_project_name) ? project.project_name : project.marketing_project_name}
                                                            substantialCompletionDate={project.substantial_completion_date}
                                                            projectTypeName={project.project_type_name}
                                                            sector={project.project_type_name}
                                                            projectCity={project.project_city}
                                                            projectState={project.project_state}
                                                            userDetails={this.state.userDetails}
                                                            listId={this.state.list.id}
                                                            UpdateCurrentListOnProjectDelete={this.UpdateCurrentListOnProjectDelete}
                                                            coverUrl={project.cover_url}
                                                            currentPage={this.state.currentPage}
                                                            sortBy={this.state.sortKey}
                                                            multipleStates = {project.states}
                                                        />
                                                    </div>
                                                )
                                            })
                                            : <div className="col-12 empty-msg">
                                                <p>No Project Found !</p>
                                            </div>}
                                    </div>

                            </div>
                            <div className="pagination-box">
                                <ReactPaginate className="pagination" id="react-paginate"
                                    // labels
                                               previousLabel={''}
                                               nextLabel={''}
                                               breakLabel={'…'}
                                    // classNames
                                               containerClassName={'react-paginate'}
                                               previousClassName={'previous'}
                                               breakClassName={'break-me'}
                                               nextClassName={'next'}
                                               pageClassName={'page'}
                                               activeClassName={'active'}
                                               pageLinkClassName={'page-link'}
                                               disabledClassName={'disabled'}
                                    // functionality
                                               pageCount={this.state.pageCount}
                                               marginPagesDisplayed={2}
                                               pageRangeDisplayed={5}
                                               onPageChange={this.handlePageClick}
                                               disableInitialCallback={true}
                                               forcePage={this.state.currentPage - 1}
                                />
                                <span className="current-page">
                                    Page {this.state.currentPage} of {this.state.pageCount? this.state.pageCount : this.state.currentPage }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </main>
        );
    }
});
