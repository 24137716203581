import React, { Component } from 'react';
import _ from "lodash";
import iconBack from "../../../assets/images/icons/back-btn.svg";
import {Badge} from "react-bootstrap";
import rpa from "../../../apis/rpa";
import {sessionService} from "redux-react-session";
import {Redirect} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import AppSpinner from "../../Projects/AppSpinner";
import {trackPromise} from "react-promise-tracker";
import {ActivityRange} from "../../shared/FilterConstant";
import Select from "react-select";
export default (class UserActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            uniqueCount: 0,
            uniqueSelection: null,
            overAllCount: 0,
            overAllSelection: null,
            currentPage: 1,
            superUserDetails: null,
            load: false,
            err: undefined,
        }
    }
    componentDidMount() {
        if(_.isEmpty(this.state.superUserDetails)) {
            sessionService.loadUser().then(response => {
                this.setState({superUserDetails: response.user, load: true})
            }).catch(err => {
                console.log('Not Fount At Show')
            })
        }
    }
    getUniqueLogin = () => {
        trackPromise(rpa.get('/users/user_logins', {
            params: {
                type: 'unique_login',
                search_by: this.state.uniqueSelection['value']
            },
        }).then(res => {
            this.setState({uniqueCount: res.data.count});
        }).catch(err => {}), 'file-upload-loader');
    }
    UpdateAllUserLogin = () => {
        trackPromise(rpa.get('/users/user_logins', {
            params: {
                type: 'all_login',
                search_by: this.state.overAllSelection['value']
            },
        }).then(res => {
            this.setState({overAllCount: res.data.count});
        }).catch(err => {}), 'file-upload-loader');
    }
    updateUniqueUser = (e) => {
        e.checked = true
        this.setState({uniqueSelection: e}, () => this.getUniqueLogin())
    }

    updateAllUserLoginChange = (e) => {
        e.checked = true
        this.setState({overAllSelection: e}, () => this.UpdateAllUserLogin())
    }

    goBackButton = () => {
        this.props.history.goBack();
    }
    render() {
        return(
            <>
                <ToastContainer position="top-center" autoClose={1500} pauseOnHover={false} style={{textAlign: 'center'}}/>
                {this.state.load ?
                    this.state.superUserDetails.super_admin ?
                        <div className="saved-list-page user-list-page">
                            <div className="container">
                                <div className="row">
                                    <AppSpinner customClass="delete-loader" area="file-upload-loader"/>
                                    <div className="col-12">
                                        <div className={"list-top saved-list-detail-page d-flex justify-content-between align-items-center"}>
                                            <div className="msg-pane">
                                                <a className="btn btn-back" onClick={this.goBackButton}>
                                                    <img src={iconBack} alt="back" />
                                                </a>
                                                <h3>
                                                    User Activity
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-3 col-lg-3" key=''>
                                        <h3><Badge variant="light">Unique Logins</Badge></h3>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3" key=''>
                                        <h1><Badge variant="success">{this.state.uniqueCount}</Badge></h1>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6" key='a'>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            options={ActivityRange}
                                            value={this.state.uniqueSelection}
                                            onChange={this.updateUniqueUser}
                                            // menuIsOpen={true}
                                            placeholder={"Select"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-3 col-lg-3" key=''>
                                        <h3><Badge variant="light">Total Logins</Badge></h3>
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-3" key=''>
                                        <h1><Badge variant="success">{this.state.overAllCount}</Badge></h1>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6" key='a'>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            options={ActivityRange}
                                            value={this.state.overAllSelection}
                                            onChange={this.updateAllUserLoginChange}
                                            // menuIsOpen={true}
                                            placeholder={"Select"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                : <Redirect to="/" /> : ''
                }
            </>
        );
    }
});
