/* eslint-disable */
import React from "react";
import '../../assets/stylesheets/Footer.css'
import logo from '../../assets/images/logo-main.svg'
export const Footer = () => (
    <footer className="inner-footer">
    <div className="footer-primary internal-footer">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-5 d-flex align-items-center">
            <a className="footer-logo mr-3">
                <img className="img-fluid" src={logo}></img>
            </a>
            <p className="m-0">
              {`©2014-${new Date().getFullYear()} All Rights Reserved. ® Ryan Companies and/or its subsidiaries or affiliates are copyrights`}
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <ul className="footer-list list-unstyled ">
              <li>
                <a href="#">
                  Privacy Policies
                </a>
              </li>
              <li>
                <a href="#">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
