import React from 'react';
import {Spinner} from "react-bootstrap";
import { usePromiseTracker } from "react-promise-tracker";

const AppSpinner = props => {
    const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});
    return (
        promiseInProgress && <div className={props.customClass}>
            <main className="content content-spinner"><Spinner animation="grow" variant="success"/></main>
        </div>
    );
}
export default AppSpinner;
